import Rails from '@rails/ujs';

document.addEventListener("turbolinks:load", function (event) {
  const auditLogsTable = document.getElementById("audit-logs-table");

  if (!auditLogsTable) return;

  const tableRows = document.getElementsByTagName("tr");
  for (let i = 0; i < tableRows.length; i++) {
    let location = tableRows[i].getAttribute("data-href");

    if (location) {
      tableRows[i].onclick = function () {
        Rails.ajax({
          type: 'GET',
          url: location,
          dataType: 'script'
        })
      };
    }
  }
});
