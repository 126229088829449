document.addEventListener('turbolinks:load', () => {
  setupFilterListener();
})

const setupFilterListener = () => {
  const listFilterInput = document.querySelector('#list-filter-input');
  if (!listFilterInput) return;

  listFilterInput.addEventListener('keyup', (e) => {
    const value = e.target.value = e.target.value.toLowerCase();
    const elementsNodeList = document.querySelectorAll('#list-filter .list-filter-item');
    [...elementsNodeList].filter((element) => {
      if (element.textContent.toLowerCase().indexOf(value) > -1) {
        showElement(element);
      } else {
        hideElement(element);
      }
    });
  });
};

const showElement = (element) => {
  element.style.display = '';
}

const hideElement = (element) => {
  element.style.display = 'none';
}
