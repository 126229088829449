import { toggleUrlParam } from '../utils/location';
import { getSelectedValueByEvent, getSelectedOptionData } from "../utils/selects";
import { updateManufacturerOptions } from "../organization_vaccines";

document.addEventListener("turbolinks:load", () => {
  const absenceTotalsYearSelect = document.getElementById('absence-totals-year-select');
  if (absenceTotalsYearSelect) {
    absenceTotalsYearSelect.addEventListener('change', (e) => {
      const year = getSelectedValueByEvent(e);
      toggleUrlParam('year', year)
    });
  }

  const absenceTotalsWorkRelatedSelect = document.getElementById('absence-totals-work-related-select');
  if (absenceTotalsWorkRelatedSelect) {
    absenceTotalsWorkRelatedSelect.addEventListener('change', (e) => {
      const workRelated = getSelectedValueByEvent(e);
      toggleUrlParam('work_related', workRelated)
    });
  }

  const sortSelect = document.getElementById('sort-by-select');
  if (sortSelect) {
    sortSelect.addEventListener('change', (e) => {
      const sortBy = getSelectedValueByEvent(e);
      toggleUrlParam('sort_by', sortBy)
    });
  }

  $("#location-select").on("change", (e) => {
    const locationId = getSelectedValueByEvent(e);
    toggleUrlParam('physical_location_id', locationId)
  });

  $("#person-group-select").on("change", (e) => {
    const groupId = getSelectedValueByEvent(e);
    toggleUrlParam("group_id", groupId);
  });

  $("#screened-status-select").on("change", (e) => {
    const status = getSelectedValueByEvent(e);
    toggleUrlParam("screened_status", status);
  });

  $("#person-checkin-location-select").on("change", (e) => {
    const { locationId } = getSelectedOptionData(e.target);
    toggleUrlParam("checkin_location_id", locationId);
  });

  $("#employees-role-select").on("change", (e) => {
    const { roleId } = getSelectedOptionData(e.target);
    toggleUrlParam("role_id", roleId);
  });

  $("#shift-select").on("change", (e) => {
    const shift = getSelectedValueByEvent(e);
    toggleUrlParam('shift', shift);
  });

  $("#screening-result-select").on("change", (e) => {
    const screeningResult = getSelectedValueByEvent(e);
    toggleUrlParam('screening_result', screeningResult);
  });

  $("#vaccine-select").on("change", (e) => {
    const VaccineId = getSelectedValueByEvent(e);
    toggleUrlParam("vaccine_id", VaccineId);
  });

  $("#vaccine-type-select").on("change", (e) => {
    const VaccineTypeId = getSelectedValueByEvent(e);
    toggleUrlParam("vaccine_type_id", VaccineTypeId);
  });

  $("#kiosk-physical-location-filter-select").on("change", (e) => {
    const locationId = getSelectedValueByEvent(e)
    const currentURL = window.location.href;
    const newURL = currentURL.replace(/physical_locations\/\d\/kiosks/, `physical_locations/${locationId}/kiosks`);
    window.location = newURL;
  });

  $("#dashboard-select").on("change", (e) => {
    window.location = getSelectedValueByEvent(e);
  });

  $('#vaccination_vaccine_id').on("change", (e) => {
    updateManufacturerOptions();
  })

  $("#person-type-select").on("change", (e) => {
    const personType = getSelectedValueByEvent(e);
    toggleUrlParam("person_type", personType);
  });

  $("#case-type-select").on("change", (e) => {
    const caseTypeId = getSelectedValueByEvent(e);
    toggleUrlParam("case_type_id", caseTypeId);
  });

  $("#case-status-select").on("change", (e) => {
    const caseStatus = getSelectedValueByEvent(e);
    toggleUrlParam('case_status', caseStatus);
  });

  $("#case-progress-status-select").on("change", (e) => {
    const caseProgressStatus = getSelectedValueByEvent(e);
    toggleUrlParam('progress_status', caseProgressStatus);
  });

  $("#case-work-related-select").on("change", (e) => {
    const caseWorkRelated = getSelectedValueByEvent(e);
    toggleUrlParam('work_related', caseWorkRelated);
  });

  $("#visitor-type-select").on("change", (e) => {
    const { visitorType } = getSelectedOptionData(e.target);
    toggleUrlParam("visitor_type", visitorType);
  });

  $('#vaccination-status-select').on('change', (e) => {
    const select = e.target;
    const vaccinationStatus = select.options[select.selectedIndex].value;
    toggleUrlParam("vaccination_status", vaccinationStatus);
  });

  $("#status-select").on("change", (e) => {
    const status = getSelectedValueByEvent(e);
    toggleUrlParam("status", status);
  });

  // TODO status-select, test-result-status-select and reservation-status-select are very similar, we can merge it in one
  $("#test-result-status-select").on("change", (e) => {
    const status = getSelectedValueByEvent(e);
    toggleUrlParam("status", status);
  });

  $("#certification-select").on("change", (e) => {
    const status = getSelectedValueByEvent(e);
    toggleUrlParam("certification", status);
  });

  $("#text-search").on("click", (e) => {
    const searchText = $("[data-text-search]").val();
    toggleUrlParam("q", searchText);
  });

  $("#disruption-category-select").on("change", (e) => {
    const category = getSelectedValueByEvent(e);
    toggleUrlParam("disruption_category", category);
  });

  $(".absence-work-capacity-select").on("change", (e) => {
    const workCapacity = getSelectedValueByEvent(e);
    const localityFormGroup = $(".absence-locality-group-form");

    if (workCapacity == 'not_working') {
      localityFormGroup.addClass('hidden');
    } else {
      localityFormGroup.removeClass('hidden');
    }
  });

  $("#audit-log-action-select").on("change", (e) => {
    const action = getSelectedValueByEvent(e);
    toggleUrlParam("audit_log_action", action);
  });

  $("#case-totals-select").on("change", (e) => {
    const action = getSelectedValueByEvent(e);
    toggleUrlParam("case_type", action);
  });

  $("#case-assigned-to-select").on("change", (e) => {
    const assigned_to = getSelectedValueByEvent(e);
    toggleUrlParam("assigned_to", assigned_to);
  });

  $("#incident-menu-select").on("change", (e) => {
    const url = getSelectedValueByEvent(e)
    window.location = url;
  });

  const jobTitlesSelect = document.getElementById('job-title-select');
  if(jobTitlesSelect) {
    jobTitlesSelect.addEventListener('change', (e) => {
      const jobTitleId = getSelectedValueByEvent(e)
      toggleUrlParam('job_title_id', jobTitleId);
    });
  }

  const weekStartSelect = document.getElementById('week-start-select');
  if (weekStartSelect) {
    weekStartSelect.addEventListener('change', (e) => {
      const weekStart = getSelectedValueByEvent(e);
      toggleUrlParam('week_start', weekStart)
    });
  }
});
