document.addEventListener('turbolinks:load', () => {
  const partyForm = document.querySelector('#party-form');
  if (!partyForm) return;

  const partyCountTotal = partyForm.querySelector('.party-count-total');
  let partyCount = parseInt(partyCountTotal.innerHTML);
  partyForm.querySelector('.decrement').addEventListener('click', () => {
    partyCount -= 1;
    if (partyCount <= 1) {
      partyCount = 1;
      document.querySelector('#party-form-instructions').classList.add('hidden');
    }
    partyCountTotal.innerHTML = partyCount;
    const lastMember = [...document.querySelector('#party-member-list').children].at(-1);
    if (!lastMember) return;

    const newInput = '<input type="hidden" name="visitor[party_members_attributes][][_destroy]" class="delete-flag" value="1">'
    lastMember.insertAdjacentHTML('beforeend', newInput);
    lastMember.remove();
    const partyMemberRemovals = document.querySelector('#party-member-removals');
    partyMemberRemovals.insertAdjacentHTML('beforeend', lastMember);
  });

  partyForm.querySelector('.increment').addEventListener('click', () => {
    partyCount += 1;
    partyCountTotal.innerHTML = partyCount;
    document.querySelector('#party-form-instructions').classList.remove('hidden');
    const lastRemovedElement = [...document.querySelector('#party-member-removals').children].at(-1);
    const partyMemberList = document.querySelector('#party-member-list');
    if (lastRemovedElement) {
      lastRemovedElement.querySelector('.delete-flag').remove();
      lastRemovedElement.remove();
      partyMemberList.innerHTML = lastRemovedElement;
    } else {
      let newMember = document.querySelector('#party-member-template').innerHTML;
      newMember = newMember.replaceAll('index', partyCount)
      partyMemberList.insertAdjacentHTML('beforeend', newMember);
    }
  });

  if (partyCount > 1) {
    document.querySelector('#party-form-instructions').classList.remove('hidden');
  } else {
    document.querySelector('#party-form-instructions').classList.add('hidden');
  }
});
