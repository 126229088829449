import { NUMBERS_TEXT } from '../scripts/utils/numbers_to_text.js';
const FLOW_STEPS = ['vaccine_selection', 'vaccine_manufacture_selection', 'doses_received_selection', 'evidence_uploads'];
const STEP_REQUIRING_ACTION = ['vaccine_manufacture_selection', 'doses_received_selection'];
let CURRENT_STEP = 'vaccine_selection';

document.addEventListener('turbolinks:load', () => {
  const vaccineRadios = Array.from(document.querySelectorAll('input[name="vaccination[vaccine_id]"]'));
  const nextBtn = document.querySelector('[data-paginator-action="next-btn"]');
  const dosesContainer = document.querySelector('#vaccine-submission-doses-container');
  const vaccinationOptOutInput = document.getElementById('vaccination_vaccine_id_opt_out');
  if(!dosesContainer) return;

  if (vaccinationOptOutInput) {
    vaccinationOptOutInput.addEventListener('click', enableNextBtn);
  }

  const setupVaccineRadio = (vaccineRadio) => {
    vaccineRadio.addEventListener('change', (event) => {
      const organizationId = document.querySelector('input[name="vaccination[organization_id]"]').value;
      const vaccineUid = event.target.value;
      // opt-out doesn't have vaccine type
      if(vaccineUid !== 'xyz123abc') {
        $.get(`/organizations/${organizationId}/vaccines/${vaccineUid}/vaccine_manufacturers.js`, () => {
          const vaccineTypeInputs = document.querySelectorAll('input[type="radio"][name="vaccination[vaccine_type_id]"]');
          vaccineTypeInputs.forEach((vaccineTypeInput) => setupVaccineType(vaccineTypeInput));
        });
      }
      enableNextBtn();
    });
  };

  const buildBoosterInput = () => {
    dosesContainer.insertAdjacentHTML('beforeend', `
      <div class='flex justify-start items-center mt-2 custom-radio'>
        <input
          class="form-radio text-indigo-600 mx-1"
          type="radio" value="true"
          name="vaccination[booster]"
          id='vaccination_booster'
        >
        <label for='vaccination_booster' class='text-left'>Booster</label>
      </div>
    `);
    document.getElementById('vaccination_booster').addEventListener('change', (event) => {
      const boosterInput = event.target;
      const dosesInputs = document.querySelectorAll('input[name="vaccination[received_doses]"]');
      Array.from(dosesInputs).forEach((dosesInput) => {
        dosesInput.checked = false;
        dosesInput.addEventListener('change', () => {
          boosterInput.checked = false;
        });
      });
    });
  };

  const setupDosesReceived = () => {
    // not only doses received inputs but also booster one
    const dosesInputs = Array.from(document.querySelectorAll('#vaccine-submission-doses-container input'));
    dosesInputs.forEach((dosesInput) => {
      dosesInput.addEventListener('click', enableNextBtn);
    });
  };;

  const setupVaccineType = (vaccineTypeInput) => {
    vaccineTypeInput.addEventListener('change', (event) => {
      const vaccineType = vaccineTypeInput.getAttribute('data-vaccine-type-name');
      updateNextSection(vaccineType);
      const numberOfDoses = event.target.getAttribute('data-number-of-doses');
      const isBooster = event.target.getAttribute('data-booster') === 'true';
      buildDosesReceived(numberOfDoses);
      if (isBooster) buildBoosterInput();
      enableNextBtn();
      setupDosesReceived();

      // even except type expects a # of doses
      if (vaccineType !== 'Exempt') return;

      document.querySelector('input[name="vaccination[received_doses]"]').checked = true;
    });
  };

  const updateNextSection = (vaccineType) => {
    // Let's make paginator junt to section 5 instead of the next one
    const nextSection = vaccineType === 'Exempt' ? 5 : ''
    nextBtn.setAttribute('data-next-section', nextSection);
  };

  const buildDosesReceived = (numberOfDoses) => {
    dosesContainer.innerHTML =''
    for (let dose = 1; dose <= parseInt(numberOfDoses); dose++) {
      dosesContainer.insertAdjacentHTML('beforeend', `
      <div class='flex justify-start items-center mt-2 custom-radio'>
        <input
          class="form-radio text-indigo-600 mx-1"
          type="radio" value="${dose}"
          name="vaccination[received_doses]"
          id='vaccination_number_of_doses_id_${dose}'
        >
        <label for='vaccination_number_of_doses_id_${dose}' class='text-left'>${NUMBERS_TEXT[dose]}</label>
      </div>
      `);
    };
  };

  const enableNextBtn = () => {
    nextBtn.classList.remove('is-disabled');
  };

  const disableNextBtn = () => {
    nextBtn.classList.add('is-disabled');
  };

  const updateNextStep = () => {
    const currentIndex = FLOW_STEPS.findIndex((step) => step === CURRENT_STEP);
    CURRENT_STEP = FLOW_STEPS[currentIndex + 1];
  };

  // if now new action requires user interacion(i.e selecting a radio)
  // we disable next btn
  nextBtn.addEventListener('click', () => {
    updateNextStep();
    if(STEP_REQUIRING_ACTION.includes(CURRENT_STEP)) {
      disableNextBtn();
    };
  });

  vaccineRadios.forEach((vaccineRadio) => setupVaccineRadio(vaccineRadio));

  const paginatorNextBtn = document.getElementById('paginatorNextBtn');
  if (!paginatorNextBtn) return;

  // when clicked, instead of paginating, we hidde current form and show
  // the opt-out one
  paginatorNextBtn.addEventListener('click', (event) => {
    const selectedVaccineUid = document.querySelector('input[name="vaccination[vaccine_id]"]:checked').value;
    if(selectedVaccineUid !== 'xyz123abc') return;

    event.stopPropagation();
    event.preventDefault();
    const optOutContainer = document.getElementById('opt-out-form-container');
    const vaccinationFormContainer = document.getElementById('vaccination-form');
    optOutContainer.classList.remove('hidden');
    vaccinationFormContainer.classList.add('hidden');
  });
});
