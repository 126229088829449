// Used to increate/decrease a timer with format HH:MM:SS
// How to use it:
// 1. You need a single elemnt that will work as the timer
//    This element needs to have data-component="timer" and data-seconds=timeInSeconds
//    By default, timer works fordward, incresing the number given
//    Optionally:
//    1 - Include data-timer-direction="backward" to decrease the number given

import { secondsToHMS } from '../utils/time';

const setupComponent = (component) => {
  let timeInSeconds = parseInt(component.getAttribute('data-seconds'));
  const direction = component.getAttribute('data-timer-direction');
  setInterval(() => {
    timeInSeconds = newTime(timeInSeconds, direction);
    component.innerText = secondsToHMS(timeInSeconds);
  }, 1000);
};

const newTime = (seconds, direction) => {
  if (direction === 'backward') {
    if (seconds == 0) return 0;

    return seconds - 1;
  } else {
    return seconds + 1;
  }
}

export default function setupTimerComponents() {
  const components = Array.from(document.querySelectorAll('[data-component="timer"]'));
  components.forEach((component) => setupComponent(component));
}
