import Highcharts from "../highcharts_config";

export default function renderChart (data) {
  // Charts
  const special_labels = {
    'Opt Out': 'Opt-Out'
  }
  const colors = [
    "#55B686",
    "#FCC515",
    "#F97316",
    "#FC4647",
    "#EB3334",
    "#DC2626",
  ]

  const chartContainer = document.getElementById("vaccination-coverage-chart");

  if(!chartContainer) return;

  chartContainer.setAttribute("style", null)

  Highcharts.chart(chartContainer.id, {
    credits: {
      enabled: false,
    },
    chart: {
      marginTop: 100,
      type: "column",
    },
    title: {
      text: data.name,
      align: "left",
      margin: 30,
      style: {
        fontWeight: "bold"
      }
    },
    xAxis: {
      categories: data.categories,
      title: {
        text: null,
      },
      style: {
        marginTop: 20
      },
      labels: {
        enabled: false
      }
    },
    yAxis: {
      gridLineDashStyle: 'shortdash',
      title: {
        text: "Personnel",
      },
      labels: {
        format: '{value} %'
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: ( // theme
            Highcharts.defaultOptions.title.style &&
            Highcharts.defaultOptions.title.style.color
          ) || "gray"
        }
      },
    },
    legend: {
      align: "center",
      x: 0,
      verticalAlign: "top",
      y: 30,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false
    },
    tooltip: {
      backgroundColor: "#000",
      borderColor: "#000",
      borderRadius: 15,
      style: {
        color: "#fff",
      },
      headerFormat: "<b>{point.x}</b><br/><br/>",
      pointFormat: "{series.name}: {point.y:,.0f}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        borderWidth: 1,
        stacking: "percent",
        dataLabels: {
          enabled: false
        }
      }
    },
    series: data.series.map((series,i) => {
      series.color = colors[i]
      series.borderColor = colors[i]
      if(series.name in special_labels){
        series.name = special_labels[series.name]
      }
      return series
    })
  })
}
