import Highcharts from 'highcharts';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsMarkerClusters from 'highcharts/modules/marker-clusters';

highchartsExporting(Highcharts);
highchartsMarkerClusters(Highcharts);

Highcharts.setOptions({
  credits: {
    enabled: false
  },
  navigation: {
    buttonOptions: {
      enabled: false
    }
  },
  lang: {
    thousandsSep: ','
  },
  chart: {
    style: {
      // copied from tailwind
      fontFamily: 'ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
    }
  }
});

export default Highcharts;
