import Ably from 'ably';

const ABLY_CHANNEL_NAME = 'disruptions';
const ABLY_NEW_MAP_EVENT_NAME = 'new_map_event';

// Our Radar map shows all disruptive events
// Any event registered in our system
const subscribeToNewMapEvent = (callback, map) => {
  const channel = getAblyChannel();
  channel.subscribe(ABLY_NEW_MAP_EVENT_NAME, (message) => callback(map, message));
}

// Our Radar list shows disruptions
// Events that affects a particular organization
const subscribeToNewListEvent = (callback, organizationUid) => {
  const ablyNewListEventName = `new_list_event_${organizationUid}`;
  const channel = getAblyChannel();
  channel.subscribe(ablyNewListEventName, (message) => callback(message));
}

const getAblyChannel = () => {
  const client = new Ably.Realtime({ authUrl: "/auth/realtime/token" });
  return client.channels.get(ABLY_CHANNEL_NAME);
}

export { subscribeToNewMapEvent, subscribeToNewListEvent };
