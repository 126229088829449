// The mark_for_destruction rails approach doesn't
// offers the greatest user experience.
// This component allow us to hidde a html block and mark the
// _destroy checkbox for a nested resource.
// Given the user the feeling that he/she is removing an element.

// It requires:
// 1. A div container with an id(wrapping resources attributes)
// 2. A hiddden `_destroy` input with an id
// 3. A button with component="destroyable"(the one who triggers the action after click)
// 4. Button needs to include `data-target` with the id of the `_destroy` hidden input
// 5. Button needs to include `data-container` with the id of the div container

// You can find an example in app/views/visitor_types/edit.html.haml
function setupDestroyableButton() {
  const destroyBtns = document.querySelectorAll('[component="destroyable"]')

  destroyBtns.forEach((btn) => {
    btn.addEventListener('click', () => {
      const destroyInputId = btn.getAttribute('data-target');
      const destroyInput = document.getElementById(destroyInputId);
      const containerId = btn.getAttribute('data-container');
      const container = document.getElementById(containerId);

      container.classList.add('hidden');
      destroyInput.value = true;
    });
  });
}

export default setupDestroyableButton;
