const setupMergeIntegratorsComponent = () => {
  const mergeIntegrators = document.querySelectorAll('[data-component="merge-integrator"]');
  mergeIntegrators.forEach((mergeIntegrator) => { setupComponent(mergeIntegrator) });
}

const setupComponent = (mergeIntegrator) => {
  const category = mergeIntegrator.dataset.category;
  mergeIntegrator.addEventListener('click', () => { getMergeLinkToken(category) });
}

const getMergeLinkToken = (integration='all') => {
  const url = `/merge/link?integration=${integration}`;

  fetch(url)
  .then(response => response.json())
  .then(data => getPublicToken(data.link_token, data.category, data.error));
}

const getPublicToken = (linkToken, category, error) => {
  if (error) return alert(Object.values(JSON.parse(error)).join());

  MergeLink.initialize({
    linkToken: linkToken,
    onSuccess: (public_token) => {
      window.location = `/merge/token?token=${public_token}&category=${category}`;
    },
    onReady: () => MergeLink.openLink()
  });
}

export default setupMergeIntegratorsComponent;
