import setupMediumEditor from '../utils/medium_editor';
import { setupModalListeners, activateModal } from '../utils/modals';
import setupAllDatePickers from '../components/datepicker';

document.addEventListener('turbolinks:load', () => {
  $('#case-instruction-submit-button').on('click', (event) => {
    const isIconSelected = $('input[name="case_instruction[icon]"]:checked').val();
    const iconErrorMessage = $('#case-instruction-icon-message');
    iconErrorMessage.toggleClass('hidden', Boolean(isIconSelected));

    const isSummaryValid = $('#case-instruction-summary-input').val().trim();
    const summaryErrorMessage = $('#case-instruction-summary-message');
    summaryErrorMessage.toggleClass('hidden', Boolean(isSummaryValid));

    if (!isSummaryValid || !isIconSelected) {
      event.preventDefault();
      event.stopPropagation();
    };
  });


  $('button.instruction-by-template').each((i, node) => {
    // We ask for a new form each time an option is clicked
    $(node).on('click', () => {
      const templateId = $(node).attr('data-instruction-template-id');
      const sanitizedTemplateId = templateId ? templateId : '';
      const organizationId = $(node).attr('case-id');
      const options =  { headers: { 'Content-Type': 'application/json' } };
      const url = `/cases/${organizationId}/instructions/new?template_id=${sanitizedTemplateId}`;

      fetch(url, options)
        .then(response => response.json())
        .then(data => setupCaseInstructionModal(data));
    });
  })
});

const setupCaseInstructionModal = (data) => {
  $('#new-case-instruction-modal').html(data.html);
  activateModal('#new-case-instruction');
  // We need to look for new mediumeditor elements, modals and datepickers
  setupMediumEditor();
  setupModalListeners();
  setupAllDatePickers();
};
