import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pin-scrolling-to-bottom"
export default class extends Controller {
  static targets = ['content'];
  observer = null

  connect() {
    this.observer = new MutationObserver(() => this.scrollContentToBottom());
    this.observer.observe(this.contentTarget, { childList: true });
  }

  disconnect() {
    this.observer.disconnect();
  }

  scrollContentToBottom() {
    const content = this.contentTarget;

    if (this.isScrollable(content)) {
      content.scrollTop = content.scrollHeight;
    }
  }

  isScrollable(content) {
    return content.scrollHeight > content.clientHeight;
  }
}
