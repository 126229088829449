const RED_COLOR = '#E11D48';
const ORANGE_COLOR = '#F97316';

// Mapbox has identifiers for sources and layers
// We can not add a layer or source if id is already registered
// and because disruptive events are constantly changing
// it is posible to receive new data for an already rendered disruption
// that's why we have to delete data if present
const renderData = (options) => {
  const { map, data, index, animated = false } = options;
  const systemEventBoundaryLayerId = `system-events-boundary-${index}`;
  const systemEventBorderLayerId = `system-events-border-${index}`;
  const systemEventSourceId = `system-events-${index}`;
  deleteMapLayerIfPresent(map, systemEventBoundaryLayerId);
  deleteMapLayerIfPresent(map, systemEventBorderLayerId);
  deleteMapSourceIfPresent(map, systemEventSourceId);
  addSource(map, systemEventSourceId, data);
  addInternalLayer(map, systemEventBoundaryLayerId, systemEventSourceId, animated);
  addExternalLayer(map, systemEventBorderLayerId, systemEventSourceId);
};

const addSource = (map, sourceId, data) => {
  map.addSource(sourceId, {
    'type': 'geojson',
    'data': {
      'type': 'FeatureCollection',
      'features': data,
    }
  });
};

const addExternalLayer = (map, layerId, sourceId) => {
  map.addLayer({
    'id': layerId,
    'type': 'line',
    'source': sourceId,
    'paint': {
      'line-color': ORANGE_COLOR,
      'line-width': 1,
    },
  });
  map.setLayoutProperty(layerId, 'visibility', setInitialLayerVisibility());
};

const setInitialLayerVisibility = () => {
  const toggleButton = document.querySelector('[data-mapbox-layer-toggle]');
  if (!toggleButton) return;

  const showingAllEvents = toggleButton.dataset.activeLayerPrefix == 'all-events';
  return showingAllEvents ? 'visible' : 'none';
}

const coordsInSimilarPosition = (coords1, coords2) => {
  const currentLatitude = Number((coords1.lat).toFixed(8));
  const currentLongitude = Number((coords1.lng).toFixed(8));
  const itemLatitude = Number((coords2.lat).toFixed(8));
  const itemLongitude = Number((coords2.lng).toFixed(8));
  return (currentLatitude == itemLatitude && currentLongitude == itemLongitude)
}

const addInternalLayer = (map, layerId, sourceId, animated) => {
  const fillColor = animated ? RED_COLOR : ORANGE_COLOR;
  map.addLayer({
    'id': layerId,
    'type': 'fill',
    'source': sourceId,
    'paint': {
      'fill-color': fillColor,
      'fill-opacity': 0.4
    },
  });
  if (!animated) return;

  map.setLayoutProperty(layerId, 'visibility', setInitialLayerVisibility());
  // If disruption is animated, then layer was initialize rendered in RED_COLOR, then change it to ORANGE_COLOR
  setTimeout(() => {
    map.setPaintProperty(layerId, 'fill-color', ORANGE_COLOR);
  }, 3000);
};

const deleteMapLayerIfPresent = (map, layerId) => {
  const mapLayers = Object.keys(map.style._layers)
  if (mapLayers.includes(layerId)) {
    map.removeLayer(layerId);
  }
};

const deleteMapSourceIfPresent = (map, sourceId) => {
  const mapSources = Object.keys(map.getStyle().sources);
  if (mapSources.includes(sourceId)) {
    map.removeSource(sourceId);
  }
};

const buildFeatureTitle = (feature) => {
  return `
  <li class='flex justify-between items-center rounded cursor-pointer px-1 py-2
    border-l-4 hover:bg-gray-100 tooltip-list-item bg-white
    ${leftBorderColor(feature)} ${affectedEvent(feature)}'
    data-disruptive-event-id='${feature.properties.id}'>
    <div class='flex items-center'>
      ${feature.properties.event_type_svg}
      <p class='ml-2 ${feature.properties.finalized ? 'line-through' : ''}'>${feature.properties.title}</p>
    </div>
    ${crossPlusIcon()}
  </li>`
}

const affectedEvent = (feature) => {
  if (feature.source.includes('affected-events')) return 'affected-event';

  return '';
}

const leftBorderColor = (feature) => {
  if (feature.source.includes('affected-events')) return 'border-l-rose-500';

  return 'border-l-white hover:border-l-gray-100';
}

const debounce = (callback, wait) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => { callback(...args) }, wait);
  };
}

// Icons section
const crossPlusIcon = () => {
  return `
  <svg class='cross-plus-icon block w-4 h-4 rounded-full text-gray-400' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='currentColor'>
    <path fill-rule='evenodd' d='M10 18a8 8 0 100-16 8 8 0 000 16zm.75-11.25a.75.75 0 00-1.5 0v2.5h-2.5a.75.75 0 000 1.5h2.5v2.5a.75.75 0 001.5 0v-2.5h2.5a.75.75 0 000-1.5h-2.5v-2.5z' clip-rule='evenodd' />
  </svg>`
}

export { renderData, buildFeatureTitle, debounce, coordsInSimilarPosition };
