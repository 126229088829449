import Highcharts from "../../charts/highcharts_config";

const drawTrendChart = (chartContainer, dates, today, totalSeries, actualSeries, detrimentalsSeries) => {
  Highcharts.chart(chartContainer, {
    accessibility: {
      enabled: false
    },
    chart: {
      backgroundColor: null,
      borderWidth: 0,
      type: 'line',
      margin: [0, 0, 0, 0],
      width: 200,
      height: 25,
      style: {
        overflow: 'visible'
      },
      skipClone: true
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    xAxis: {
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      tickWidth: 0,
      lineWidth: 0,
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
      categories: dates,
      plotLines: [{
        color: '#C0C0C0',
        width: 1,
        value: 30,
        dashStyle: 'ShortDash'
      },
      ...detrimentalsSeries.map((detrimental, i) => {
        if(!detrimental) return null;

        return  {
          color: 'rgba(244,63,94,0.5)',
          width: 5,
          value: i,
          dashStyle: 'Solid',
          fillOpacity: 0.5
        }
      }).filter(Boolean)
    ]
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false
      },
      title: {
        text: null
      },
      tickPositions: []
    },
    legend: {
      enabled: false
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
      formatter: function(tooltip){
        if (this.x > today){
          this.points[1].series['name'] = 'Predicted'
        }
        if (this.x <= today){
          // Reseting tooltip naming from Predicted to Available
          this.points[1].series['name'] = 'Available'
        }
        return tooltip.defaultFormatter.call(this, tooltip);
      }
    },
    plotOptions: {
      series: {
        animation: false,
        lineWidth: 1,
        shadow: false,
        states: {
          hover: {
            lineWidth: 1
          }
        },
        marker: {
          radius: 0,
          states: {
            hover: {
              radius: 2
            }
          }
        },
        fillOpacity: 0.0
      }
    },
    series: [
      { name: 'On Staff',
        data: totalSeries,
        color: '#000000' },
      { name: 'Available',
        data: actualSeries,
        color: '#FF0000',
        zoneAxis: 'x',
        zones: [
          { value: 30},
          { dashStyle: 'ShortDash'}
        ]
      }
    ]
  });
};


export default drawTrendChart;
