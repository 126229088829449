import Rails from '@rails/ujs';

// TODO: deprecate this and use app/javascript/controllers/person_search_controller.js instead
// There are at least two problems with this implementation:
// It requires to be wrapped by a form(see Rails.fire(searchForm, 'submit') line).
// Meaning it can not be wrapped in your forms
// It is based on ids, meaning it is not posible to have multiple people search in the same page
// Bonus: It only populates selected person on searchField. So you need another script to attach
// a click listenners to searchResults to get the selected person and populate your form inputs
document.addEventListener('turbolinks:load', function (_event) {
  const searchForm = document.querySelector('#personSearch');

  if (searchForm) {
    let debounce = null;
    const debounceMs = 300;
    const minimumSearchLength = 3;
    const searchField = searchForm.querySelector('#search');
    const searchResults = searchForm.querySelector('#searchResults');
    const searchResultsErrorMessage = searchForm.querySelector('#searchResultsErrorMessage');

    searchField.addEventListener('input', function (e) {
      let inputLength = e.target.value.length;

      if (inputLength >= minimumSearchLength) {
        clearTimeout(debounce);
        debounce = setTimeout(function () {
          searchResultsErrorMessage.innerHTML = '';
          Rails.fire(searchForm, 'submit');
        }, debounceMs);
      } else {
        searchResults.innerHTML = '';
        searchResultsErrorMessage.innerHTML = `Minimum characters needed: ${minimumSearchLength - inputLength}`;
      }
    });

    // When clicking outside the search results:
    // - Hide search results
    document.body.addEventListener('click', function (e) {
      if (!searchForm.contains(e.target)) { searchResults.innerHTML = ''; }
    });

    searchResults.addEventListener('click', function (e) {
      // When selecting a search result:
      // - Populate search field with selected person's info
      // - Hide search results
      if (e.target.tagName === 'LI') {
        searchField.value = `${e.target.dataset.firstName} ${e.target.dataset.lastName} (${e.target.dataset.email})`;
        searchResults.innerHTML = '';
      }
    });

    // When clicking the search field and field has a person info:
    // - Clear the search field
    searchField.addEventListener('click', function (e) {
      if (e.target.value.length > 0) { e.target.value = ''; }
    });
  }
});
