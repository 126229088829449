import filterParams from './filter_params';

document.addEventListener("turbolinks:load", () => {
  const clearTextSearch = document.querySelector('#clear-text-search')
  if (clearTextSearch) {
    clearTextSearch.addEventListener('click', () => filterParams('q'));
  }

  // Employees backup search form
  const searchForm = document.querySelector('#personSearch');
  const employeeId = document.querySelector('#employee-id');

  if (searchForm && employeeId) {
    searchForm.querySelector('#searchResults').addEventListener('click', (e) => {
      if (e.target.tagName === 'LI') {
        employeeId.value = e.target.dataset.personId;
      }
    });

    // Search field will be cleared when clicking on it
    // so we need to clear the employeeId field as well
    searchForm.querySelector('#search').addEventListener('click', (e) => {
      if (e.target.value.length) { employeeId.value = null; }
    });
  }

  const newPassword = document.querySelector('input#employee_new_password');
  const confirmPassword = document.querySelector('input#employee_password_confirmation');
  if (newPassword && confirmPassword) {
    const passwordNoMatchError = document.querySelector('#password-no-match-error');
    confirmPassword.addEventListener('keyup', (e) => {
      if (e.target.value !== newPassword.value) {
        passwordNoMatchError.classList.remove('hidden');
      } else {
        passwordNoMatchError.classList.add('hidden');
      }
    });
  }
});
