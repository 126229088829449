import Ably from 'ably';

document.addEventListener('turbolinks:load', () => {
  const checkInTable = document.getElementById('incident-check-in-table');
  if(!checkInTable) return;

  const channelName = checkInTable.dataset.realtimeChannel;
  const client = new Ably.Realtime({ authUrl: '/auth/realtime/token' });
  const channel = client.channels.get(channelName);
  channel.subscribe('refresh', () => location.reload());
});