import { hideElement, showElement } from 'scripts/utils/visibility';

const setupTabComponent = (component) => {
  const tabs = component.querySelectorAll('[data-target="tab"]')
  tabs.forEach((tab) => {
    tab.addEventListener('click', () => {
      resetTabs(tabs);
      showSelectedTab(tab);
    });
  });
}

const resetTabs = (tabs) => {
  tabs.forEach((tab) => {
    tab.classList.replace('text-indigo-600', 'text-gray-500');
    tab.classList.replace('border-indigo-500', 'border-transparent');
    const targetContentWrapper = document.getElementById(tab.dataset.contentWrapperId);
    hideElement(targetContentWrapper);
  });
}

const showSelectedTab = (tab) => {
  tab.classList.replace('text-gray-500', 'text-indigo-600');
  tab.classList.replace('border-transparent', 'border-indigo-500');
  const targetContentWrapper = document.getElementById(tab.dataset.contentWrapperId);
  showElement(targetContentWrapper);
}

const setupTabComponents = () => {
  const tabComponents = document.querySelectorAll('[data-component="tab"]');
  tabComponents.forEach((component) => setupTabComponent(component) );
}

export { setupTabComponents, setupTabComponent };
