document.addEventListener('turbolinks:load', () => {
  const absenceCreationModal = document.querySelector('#absence-creation-modal')
  const absenceEndDateInput = document.querySelector('#absence_end_date')

  if (absenceCreationModal && absenceEndDateInput) {
    const options = { attributes: true }
    const observer = new MutationObserver(callback)
    observer.observe(absenceCreationModal, options)

    function callback(mutationList, observer) {
      mutationList.forEach(function(mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          absenceEndDateInput.value = ''
        }
      })
    }
  }
})
