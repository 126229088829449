import { hideElement, showElement } from 'scripts/utils/visibility';
import { selectCard, unselectCard } from 'scripts/utils/alerts.js';

// TODO: Let's DRY this up. See app/javascript/scripts/components/alert_form.js
document.addEventListener('turbolinks:load', () => {
  const createEventAlertTemplateSelect = document.getElementById('create-event-alert-template-select');
  if (createEventAlertTemplateSelect) {
    createEventAlertTemplateSelect.addEventListener('change', (event) => {
      const alertTemplateName = document.getElementById('alert_template_name');
      alertTemplateName.value = event.target.options[event.target.selectedIndex].text;

      const templateId = event.target.value;
      const eventAttributes = {
        eventTitle: event.target.dataset.eventTitle,
        eventDescription: event.target.dataset.eventDescription,
        eventCategory: event.target.dataset.eventCategory,
        eventType: event.target.dataset.eventType
      };
      fetchTemplateContent(templateId, eventAttributes);
    });

    initializeCreateEventAlertTemplateSelect();
  }


  const alertModal = document.getElementById('alert-modal');
  if(!alertModal) return;

  // Form Checkboxes (Cards)
  const standardAlertPriorityContainer = document.getElementById('standard-alert-priority-container');
  const standardAlertPriorityContainerIndicator = document.getElementById('standard-alert-priority-indicator');
  const emergencyAlertPriorityContainer = document.getElementById('emergency-alert-priority-container');
  const emergencyAlertPriorityContainerIndicator = document.getElementById('emergency-alert-priority-indicator');
  // Modal Icons
  const standardAlertIconModal = document.getElementById('standard-alert-icon-modal');
  const emergencyAlertIconModal = document.getElementById('emergency-alert-icon-modal');
  // Alert Priority Descriptions
  const standardAlertPriorityDescription = document.getElementById('standard-alert-priority-description');
  const emergencyAlertPriorityDescription = document.getElementById('emergency-alert-priority-description');
  // Locations checkboxes
  const locationCheckboxes = document.getElementsByClassName('alert-location-checkbox');
  // Select / unselect all locations buttons
  const selectAllLocationsButton = document.getElementById('select-all-locations-button');
  const unselectAllLocationsButton = document.getElementById('unselect-all-locations-button');
  // Side Button Help Texts
  const standardAlertButtonSideText = document.getElementById('standard-alert-button-side-text');
  const emergencyAlertButtonSideText = document.getElementById('emergency-alert-button-side-text');
  // Submission Buttons
  const standardAlertSubmitButton = document.getElementById('standard-alert-submit-button');
  const emergencyAlertSubmitButton = document.getElementById('emergency-alert-submit-button');

  selectAllLocationsButton.addEventListener('click', () => {
    locationCheckboxes.forEach((checkbox) => {
      checkbox.checked = true;
    });
  });

  unselectAllLocationsButton.addEventListener('click', () => {
    locationCheckboxes.forEach((checkbox) => {
      checkbox.checked = false;
    });
  });

  standardAlertPriorityContainer.addEventListener('click', () => {
    hideElement(emergencyAlertSubmitButton);
    hideElement(emergencyAlertButtonSideText);
    hideElement(emergencyAlertIconModal);
    hideElement(emergencyAlertPriorityDescription);
    showElement(standardAlertSubmitButton);
    showElement(standardAlertButtonSideText);
    showElement(standardAlertIconModal);
    showElement(standardAlertPriorityDescription);
    showElement(standardAlertPriorityContainerIndicator);
    unselectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator);
    selectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator, false);
    // Update selected location checkboxes
    locationCheckboxes.forEach((checkbox) => {
      changeElementPrimaryColor(checkbox, 'rose', 'indigo')
    });
    changeElementPrimaryColor(selectAllLocationsButton, 'rose', 'indigo');
    changeElementPrimaryColor(unselectAllLocationsButton, 'rose', 'indigo');
  });

  emergencyAlertPriorityContainer.addEventListener('click', () => {
    hideElement(standardAlertSubmitButton);
    hideElement(standardAlertButtonSideText);
    hideElement(standardAlertIconModal);
    hideElement(standardAlertPriorityDescription);
    showElement(emergencyAlertSubmitButton);
    showElement(emergencyAlertButtonSideText);
    showElement(emergencyAlertIconModal);
    showElement(emergencyAlertPriorityDescription);
    showElement(emergencyAlertPriorityContainerIndicator);
    unselectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator);
    selectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator, true);
    // Update selected location checkboxes
    locationCheckboxes.forEach((checkbox) => {
      changeElementPrimaryColor(checkbox, 'indigo', 'rose')
    });
    changeElementPrimaryColor(selectAllLocationsButton, 'indigo', 'rose');
    changeElementPrimaryColor(unselectAllLocationsButton, 'indigo', 'rose');
  });
});

const changeElementPrimaryColor = (element, oldColorKey, newColorKey) => {
  element.className = element.className.replaceAll(oldColorKey, newColorKey);
}

const initializeCreateEventAlertTemplateSelect = () => {
  const eventContainer = document.getElementById('disruptive-event-container');
  const titleInput = document.getElementById('disruption_disruptive_event_title');
  const descriptionInput = eventContainer.querySelector('div.markdown');
  const categorySelect = document.getElementById('disruption_disruptive_event_category');
  const eventTypeSelect = document.getElementById('disruption_disruptive_event_event_type');
  const createEventAlertTemplateSelect = document.getElementById('create-event-alert-template-select');

  if (titleInput && descriptionInput && categorySelect && eventTypeSelect && createEventAlertTemplateSelect) {
    const updateDataset = (element, datasetKey, value) => {
      element.dataset[datasetKey] = value;
    };

    // Set current category and event type to the alert template select
    createEventAlertTemplateSelect.dataset.eventCategory = categorySelect.value;

    // NOTE: For some reason eventTypeSelect.value is not returning the first option value
    // for the selected initial category "Disasters". I'm using the querySelector to get the value instead.
    const eventType = eventTypeSelect.querySelector('option[data-category="disasters"]').value
    createEventAlertTemplateSelect.dataset.eventType = eventType;

    titleInput.addEventListener('input', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventTitle', titleInput.value);
    });

    descriptionInput.addEventListener('input', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventDescription', descriptionInput.innerHTML);
    });

    categorySelect.addEventListener('change', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventCategory', categorySelect.value);
    });

    eventTypeSelect.addEventListener('change', function() {
      updateDataset(createEventAlertTemplateSelect, 'eventType', eventTypeSelect.value);
    });
  } else {
    console.error('One or more elements not found:', {
      titleInput,
      descriptionInput,
      categorySelect,
      eventTypeSelect,
      createEventAlertTemplateSelect
    });
  }
};

const fetchTemplateContent = async (templateId, eventAttributes = {}) => {
  if (templateId) {
    const params = new URLSearchParams({
      id: templateId,
      event_title: eventAttributes.eventTitle || '',
      event_description: eventAttributes.eventDescription || '',
      event_category: eventAttributes.eventCategory || '',
      event_type: eventAttributes.eventType || ''
    });
    const url = `/alerts/alert_template_content?${params.toString()}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      // Set alert title
      document.getElementById('event_alert_name').value = data.title;

      // Not sure why but the content markdown has two elements, a div and a textarea.
      // I'm updating both to be safe.
      const eventAlertContainer = document.getElementById('event-alert-container');
      const markdowns = eventAlertContainer.querySelectorAll('.markdown');
      markdowns.forEach((markdown) => {
        markdown.innerHTML = data.content;
        if (markdown.hasAttribute('data-placeholder')) {
          markdown.removeAttribute('data-placeholder');
        }
      });

      // Set alert emergency and all personnel radio buttons
      setRadioButtonState(data.emergency, 'event_emergency_true', 'event_emergency_false');
      setRadioButtonState(data.all_personnel, 'all_personnel', 'affected_personnel');
      // Update alert priority and recipient rules
      updatePriorityRules(data.emergency);
      updateRecipientRules(data.all_personnel, data.emergency);
    }
    catch (error) {
      console.error('Error fetching template content:', error);
    }
  } else {
    console.error('Template ID is required');
  }
};

const setRadioButtonState = (condition, trueElementId, falseElementId) => {
  document.getElementById(trueElementId).checked = condition;
  document.getElementById(falseElementId).checked = !condition;
};

const updatePriorityRules = (isEmergencyAlert) => {
  const standardAlertPriorityContainer = document.getElementById('alert-standard-priority-container');
  const standardAlertPriorityContainerIndicator = document.getElementById('alert-standard-priority-indicator');
  const emergencyAlertPriorityContainer = document.getElementById('alert-emergency-priority-container');
  const emergencyAlertPriorityContainerIndicator = document.getElementById('alert-emergency-priority-indicator');

  if (isEmergencyAlert) {
    selectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator, true);
    unselectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator);
  } else {
    selectCard(standardAlertPriorityContainer, standardAlertPriorityContainerIndicator, false);
    unselectCard(emergencyAlertPriorityContainer, emergencyAlertPriorityContainerIndicator);
  }
};

const updateRecipientRules = (allPersonnel, isEmergencyAlert) => {
  const likelyPersonnelContainer = document.getElementById('alert-likely-personnel-container');
  const likelyPersonnelContainerIndicator = document.getElementById('alert-likely-personnel-indicator');
  const allPersonnelContainer = document.getElementById('alert-all-personnel-container');
  const allPersonnelContainerIndicator = document.getElementById('alert-all-personnel-indicator');

  if (allPersonnel) {
    selectCard(allPersonnelContainer, allPersonnelContainerIndicator, isEmergencyAlert);
    unselectCard(likelyPersonnelContainer, likelyPersonnelContainerIndicator);
  } else {
    selectCard(likelyPersonnelContainer, likelyPersonnelContainerIndicator, isEmergencyAlert);
    unselectCard(allPersonnelContainer, allPersonnelContainerIndicator);
  }
};
