//  TODO: Add tests
const selectDropDownOptionByText = (element, optionText) => {
  const options = element.find("option")
  const option = options.filter((_i, o) => $(o).text() === optionText);
  option.prop("selected", true);
}

const getSelectedValueByEvent = (e) => {
  return e.target.options[e.target.selectedIndex].value;
}

const getSelectedOptionData = (select) => select.options[select.selectedIndex].dataset;

export { selectDropDownOptionByText, getSelectedValueByEvent, getSelectedOptionData };
