import Ably from "ably";

document.addEventListener("turbolinks:load", () => {
    const badge = document.getElementById("badge_monitor");
    if(badge) {
        // setup PubSub
        let channel_name = badge.dataset.realtimeChannel;
        let client = new Ably.Realtime({
            authUrl: "/auth/realtime/token"
        });
        client.connection.on( function(event) {
            // TODO: This needs to be turned into a Sentry error. After initial deployment, we can remove these lines entirely.
            if(event.reason) {
                console.log("PubSub State: " + event.current);
                console.log("PubSub Failure: " + event.reason.message);
                console.log("Reason: " + event.reason.cause);
                console.log(event);
            }
        });
        let channel = client.channels.get(channel_name);
        channel.subscribe("refresh",function(message) {
            location.reload();
        });
    }
});