export default function filterParams(param_name, param_value) {
  const url = new URL(window.location);
  const params = new URLSearchParams(url.search.slice(1));

  // Return to the first page whenever a filter query is triggered
  params.delete("page");

  if (param_value && param_value.length) {
    params.set(param_name, param_value);
  } else {
    params.delete(param_name);
  }

  let filterData = {}
  for (const [key, value] of params) {
    filterData[key] = value
  }

  // expires in 100 days
  Cookies.set("filters", JSON.stringify(filterData), {expires: 100, secure: true})

  url.search = params.toString();
  window.location.href = url.href;
}
