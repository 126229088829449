document.addEventListener('turbolinks:load', function (_event) {
  const searchForm = document.querySelector('#personSearch');
  const recipientId = document.querySelector('#recipient_id');

  // When selecting a search result:
  // - Recipient id hidden field is populated with the selected person's info
  if (searchForm && recipientId) {
    searchForm.querySelector('#searchResults').addEventListener('click', function (e) {
      if (e.target.tagName === 'LI') {
        recipientId.value = e.target.dataset.personId;
      }
    });

    // When clicking the search field and field has a person info:
    // - Clear recipient id
    searchForm.querySelector('#search').addEventListener('click', function (_e) {
      recipientId.value = '';
    });
  }
});
