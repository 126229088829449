import filterParams from "./filter_params";

document.addEventListener("turbolinks:load", () => {
  const caseTable = document.getElementById('case-management-table');
  if (!caseTable) return;

  document.querySelectorAll('tr[data-href]').forEach((row) => {
    row.addEventListener('click', () => {
      window.document.location = row.dataset.href;
    });
  });

  document.querySelector('#cases-text-search')
    .addEventListener('click', () => {
      const searchText = document.querySelector('[data-text-search]').value;
      filterParams('q', searchText);
    });
});
