// This component is strognly related with rails nested_attributes forms
// It requests the fields needed to be included at the end of the form
// You'll need:
// 1. An empty div with an id(used as a container to append new fields)
// 2. A button with 'data-component': 'dynamic-form'(it'll trigger the process)
// 3. Trigger button needs to contain the following data attributes:
// data-target-url (url to hit and build html)
// data-form-name (needs to match container id)

// Optional:
// If a delete button is included:
// 1. Fields need to be wrapped into a div with and id(matching data-form-name + and index)
// 2. Delete button needs to have a `data-delete-target`(matching the div's id wrapping fields)

// You can take a look at visitor_types/new and visitor_type_documents/_form as an example
function setupDynamicForms() {
  const dynamicForms = document.querySelectorAll('[data-component="dynamic-form"]');

  dynamicForms.forEach((triggerInput) => {
    triggerInput.addEventListener('click', () => setupListener(triggerInput) );
  });
}

// index not necesarily needs to be incremented on each call
function setupListener(triggerInput, incrementalIndex = true, callback) {
  const url = triggerInput.getAttribute('data-target-url');
  const currentIndex = parseInt(triggerInput.getAttribute('data-index') || 0);

  $.ajax({
    url,
    data: { index: currentIndex },
    type: 'get',
    dataType: "json",
    complete: (response, textStatus) => {
      const data = response.responseJSON
      if (textStatus !== 'success') return;

      updateForm(triggerInput, currentIndex, data.html, incrementalIndex);
      if (callback) callback();
    },
  });
}

function updateForm(triggerInput, currentIndex, html, incrementalIndex) {
  const formName = triggerInput.getAttribute('data-form-name');
  const container = document.getElementById(formName); // formName and container's id needs to match
  const newIndex = incrementalIndex ? (currentIndex + 1) : currentIndex;
  container.insertAdjacentHTML('beforeend', html);
  triggerInput.setAttribute('data-index', newIndex);
  const deleteButton = document.querySelector(`[data-delete-target='${formName}-${newIndex}']`);
  if(!deleteButton) return; // Delete button cand be optional

  deleteButton.addEventListener('click', () => {
    document.getElementById(`${formName}-${newIndex}`).remove();
  });
}

export { setupDynamicForms, setupListener };
