import { hideElement, showElement } from 'scripts/utils/visibility';

const selectCard = (card, cardIndicator, isEmergencyAlert) => {
  const primaryColor = isEmergencyAlert ? 'red-600' : 'indigo-700';
  card.classList.add('border-2');
  card.classList.add(`border-${primaryColor}`);
  cardIndicator.classList.add(`text-${primaryColor}`);
  showElement(cardIndicator);
};

const unselectCard = (card, cardIndicator) => {
  card.classList.remove('border-2');
  card.classList.remove('border-red-600');
  card.classList.remove('border-indigo-700');
  cardIndicator.classList.remove('text-red-600');
  cardIndicator.classList.remove('text-indigo-700');
};

export{ selectCard, unselectCard };
