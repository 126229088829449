// This component is being used on modals containing images.
// Clicking on an image hides modal's content and 'make' the image full size
// In order to use it, you'll need:
// 1. A div wrapping the modal content with component="full-size-image-modal"
// 2. A div wrapping an empty full-size img + close icon
// 3. The div wrapping the modal needs to have the following attrs:
// data-full-image-id with the id of full-size image
// data-image-container-id with the id of div wrapping full-size img + close icon
// data-close-icon-id with the id of close icon
// Also, each img should have an data-original-img-src attr
// since sometimes an img varian is used instead

// You can find an example in app/views/visitor_documents/_documents_modal.html.haml
function toogleContainers(container, imageContainer, status) {
  container.classList.toggle('hidden', status);
  imageContainer.classList.toggle('hidden', !status);
}

function setupFullSizeImageModal() {
  const imagesContainers = document.querySelectorAll('[component="full-size-image-modal"]');

  imagesContainers.forEach((container) => {
    const imageContainerId = container.getAttribute('data-image-container-id');
    const fullImageId = container.getAttribute('data-full-image-id');
    const closeIconId = container.getAttribute('data-close-icon-id');
    const imageContainer = document.getElementById(imageContainerId);
    const fullImage = document.getElementById(fullImageId);
    const closeIcon = document.getElementById(closeIconId);

    closeIcon.addEventListener('click', () => toogleContainers(container, imageContainer, false));
    container.querySelectorAll('img').forEach((image) => {
      image.addEventListener('click', () => {
        const imageSrc = image.getAttribute('data-original-img-src');
        fullImage.setAttribute('src', imageSrc);
        toogleContainers(container, imageContainer, true);
      });
    });
  });
}

export default setupFullSizeImageModal;
