// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Alpine from 'alpinejs';
import "controllers"

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
require('jquery');
require('ably');
require('select2');

require('../components/switch');

// Scripts
require('scripts/admin_banner');
require('scripts/attendance_table');
require('scripts/audit_logs_table');
require('scripts/availability_thresholds');
require('scripts/badge');
require('scripts/baseline_summary');
require('scripts/case_management_table');
require('scripts/case_managers');
require('scripts/case_types');
require('scripts/cases');
require('scripts/cocoon-jquery.js');
require('scripts/contacts');
require('scripts/data_link');
require('scripts/date-pick');
require('scripts/disruptions.js');
require('scripts/disruptions/events_list.js');
require('scripts/dropzone_config');
require('scripts/employees');
require('scripts/executive_charts');
require('scripts/geofencing_map');
require('scripts/header');
require('scripts/job_title_search');
require('scripts/list_filter');
require('scripts/locations_dropdown');
require('scripts/locations');
require('scripts/managers');
require('scripts/matchables');
require('scripts/occupancy_tracking');
require('scripts/organization_vaccines');
require('scripts/participation_rates');
require('scripts/party_members');
require('scripts/people_check_ins');
require('scripts/person_search');
require('scripts/phone_numbers');
require('scripts/reservations');
require('scripts/scanner');
require('scripts/screenings');
require('scripts/setup_components');
require('scripts/stripe_card_token_handler');
require('scripts/tabbed_nav');
require('scripts/timezone');
require('scripts/trial_admins');
require('scripts/vaccination_web_submissions');
require('scripts/vaccine_types');
require('scripts/visitor_documents');
require('scripts/visitor_registrations');
require('scripts/visitor_types');
require('scripts/visitors');
require('scripts/web_submissions_form.js');
require('scripts/work_climate_tutorial.js');

// Charts
require('scripts/charts/availability');
require('scripts/charts/journal_ratio');
require('scripts/charts/locality');
require('scripts/charts/mood_swings');
require('scripts/charts/onboarding_progress');
require('scripts/charts/response_distribution');
require('scripts/charts/unknown_response');
require('scripts/charts/vaccination_status');
require('scripts/charts/vaccine_coverage');
require('scripts/charts/work_climate_scores');

// Incidents
require('scripts/incidents/responsibles');

// Listeners
require('scripts/listeners/selects');
require('scripts/listeners/text_inputs');

// Pages
require('scripts/pages/case_absences');
require('scripts/pages/case_instructions');
require('scripts/pages/case_type_fields');
require('scripts/pages/case_types');
require('scripts/pages/disruptions/alerts');
require('scripts/pages/disruptions/new_disruptions');
require('scripts/pages/work_climate/facet_sortable');
require('scripts/pages/work_climate/managers');
require('scripts/pages/incident_check_in');
require('scripts/pages/incident_roster');
require('scripts/pages/incidents/response_chart');
require('scripts/pages/onboarding/data_imports');
require('scripts/pages/staffing_levels');
require('scripts/pages/work_climate');

// Utils
require('scripts/utils/tooltips');

require('local-time').start();
Alpine.start();

require('dropzone/dist/dropzone.css');
require('js-datepicker/dist/datepicker.min.css');
require('mapbox-gl/dist/mapbox-gl.css');
require('select2/dist/css/select2.css');
require('../stylesheets/application.scss');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
