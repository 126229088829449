import Highcharts from "../../charts/highcharts_config";

const drawAbsenceHistoryChart = (jobTitleId, chartSeries) => {
  Highcharts.chart(`highchart-container-${jobTitleId}`, {
    accessibility: {
      enabled: false
    },
    chart: { type: 'column', },
    title: { text: '' },
    credits: {
      enabled: false
    },
    xAxis: {
      title: {
        useHTML: true,
        text: 'Days'
      },
      type: 'datetime',
      labels: {
        formatter: () => Highcharts.dateFormat('%b %d')
      }
    },
    yAxis: {
      title: { text: '' },
      min: 0
    },
    tooltip: {
      shared: true,
      headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>'
    },
    plotOptions: {
      series: {
        pointPadding: 0.00,
        groupPadding: 0.00,
        borderRadiusTopLeft: '50%',
        borderRadiusTopRight: '50%',
        borderRadius: 1,
        fillOpacity: 0.75,
      },
      column: {
        stacking: 'normal',
        dataLabels: { enabled: false },
        borderWidth: 0.25,
      },
      area: {
        stacking: 'normal',
        lineColor: '#881337',
        lineWidth: 1,
        marker: {
            enabled: false,
            radius: 2,
            lineWidth: 1,
            lineColor: '#881337'
        }
      }
    },
    series: chartSeries,
  });
};

export default drawAbsenceHistoryChart;
