import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'inputFirstName',
    'inputLastName',
    'inputEmail',
    'inputCellphone',
    'inputPrimaryGroupName',
    'submitCreateButton',
    'submitCreateButtonDisabled',
    'submitUpdateButton',
    'deletionLink'
  ]

  connect() {
    this.element.addEventListener('ajax:success', () => {
      document.querySelector('#errors_container').classList.add('hidden')
      this.updateRecipientsInfo()
    })
    this.element.addEventListener('ajax:error', () => {
      document.querySelector('#errors_container').classList.remove('hidden')
    })
  }

  toggleUpdateSubmit(){
    this.submitUpdateButtonTarget.classList.remove('hidden')
    this.submitUpdateButtonTarget.disabled = false
    this.deletionLinkTarget.classList.add('hidden')
  }

  toggleCreateSubmit(){
    const firstName = this.inputFirstNameTarget.value.trim()
    const lastName = this.inputLastNameTarget.value.trim()
    const email = this.inputEmailTarget.value.trim()
    const cellphone = this.inputCellphoneTarget.value.trim()
    if(firstName === '' || lastName === '' || (email === '' && cellphone === '')){
      this.submitCreateButtonDisabledTarget.classList.remove('hidden')
      this.submitCreateButtonTarget.classList.add('hidden')
      this.submitCreateButtonTarget.disabled = true
      return
    }

    this.submitCreateButtonDisabledTarget.classList.add('hidden')
    this.submitCreateButtonTarget.classList.remove('hidden')
    this.submitCreateButtonTarget.disabled = false
  }

  updateRecipientsInfo(){
    const employeesQty = document.querySelectorAll('form').length - 1
    const recipientsCounter = document.querySelector('#recipientsCounter')
    const recipientsText = document.querySelector('#recipientsText')
    const nextStepButton = document.querySelector('#nextStepButton')

    if(employeesQty == 1){
      recipientsCounter.innerHTML = employeesQty
      recipientsText.innerHTML = 'Person will receive a request to fill out a baseline by SMS to their cell if present, otherwise to Email.'
      nextStepButton.classList.remove('hidden')
    } else if (employeesQty > 1){
      recipientsCounter.innerHTML = employeesQty
      recipientsText.innerHTML = 'People will receive a request to fill out a baseline by SMS to their cell if present, otherwise to Email.'
      nextStepButton.classList.remove('hidden')
    } else if (employeesQty == 0){
      recipientsCounter.innerHTML = ''
      recipientsText.innerHTML = 'People will receive a request to fill out a baseline by SMS to their cell if present, otherwise to Email.'
      nextStepButton.classList.add('hidden')
    }
  }
}
