window.addUpdateTime = addUpdateTime
window.removeUpdateTime = removeUpdateTime

export default function editDataLink() {
  new ClipboardJS('#report-datalink-url-copy')
  const modal = document.querySelector('#report-datalink-modal');

  modal.classList.add('opacity-100', 'active');
  modal.classList.remove('pointer-events-none', 'opacity-0');
  document.querySelectorAll('#report-datalink-modal .modal-close').forEach((button) => {
    button.addEventListener('click', () => {
      modal.classList.add('opacity-0', 'pointer-events-none');
      modal.classList.remove('opacity-100', 'active');
    })
  });
}

// Does this eventListener belong here?
document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.edit-report-datalink')
    .forEach((element) => {
      element.addEventListener('click', () => editDataLink());
    });
});

const addUpdateTime = (event) => {
  event.preventDefault();
  const currentGrid = [...document.querySelectorAll('.update-daily-at-container')].at(-1);
  const duplicatedGrid = currentGrid.clone();
  [...duplicatedGrid.children].at(-1)
    .children
    .classList
    .remove('invisible');
  duplicatedGrid.after(currentGrid);
}

const removeUpdateTime = (event, self) => {
  event.preventDefault();
  document.querySelector(self)
    .closest('.update-daily-at-container')
    .remove();
}
