document.addEventListener('turbolinks:load', function (_event) {
  const searchForm = document.querySelector('#personSearch');
  const adminId = document.querySelector('#admin_id');

  // Populate admin_id when selecting a search result:
  if (searchForm && adminId) {
    searchForm.querySelector('#searchResults').addEventListener('click', function (e) {
      if (e.target.tagName === 'LI') {
        adminId.value = e.target.dataset.personId;
      }
    });

    // Clear admin_id when clicking the search field:
    searchForm.querySelector('#search').addEventListener('click', function (_e) {
      adminId.value = '';
    });
  }
});
