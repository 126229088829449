// This is strongly related with switch.scss component. It toggles 'enabled' class in button

// How to use it:
// 1. Include data-component="switch" to the buttons you want to act as switches
// 2. Optionally button can have data-target-input(that means you need a hidden input)
// 3. Enjoy
document.addEventListener('turbolinks:load', () =>  {
  const switches = document.querySelectorAll('[data-component="switch"]');

  switches.forEach((switchButton) => {
    switchButton.addEventListener('click', () => {
      switchButton.classList.toggle('enabled');
      const targetId = switchButton.getAttribute('data-target-input');
      if(!targetId) return;

      const targetInput = document.getElementById(targetId);
      const newValue = targetInput.value == 'true' ? 'false' : 'true';
      targetInput.value = newValue;
      targetInput.dispatchEvent(new Event('change'));
    });
  });
});
