import Highcharts from "../highcharts_config";

export default function renderChart (data) {
  const colors = [
    "#E15062",
    "#A0ECB1",
    "#5EC269",
  ]

  const chartContainer = document.getElementById("workforce-availability-chart")
  if (!chartContainer) return;
  const form = document.getElementById("availability-thresholds-form")
  const thresholdPlotLines = buildThresholdPlotLines(JSON.parse(form.dataset.thresholds))

  chartContainer.setAttribute("style", null)

  window.workforceAvailabilityChart = Highcharts.chart(chartContainer.id, {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      zoomType: "y",
      height: 285,
      resetZoomButton: {
        position: {
          x: 0,
          y: -50,
        },
        theme: {
          fill: 'white',
          stroke: 'silver',
          r: 0,
        }
      }
    },
    title: {
      text: data.name,
      align: "left",
      margin: 30,
      style: {
        fontWeight: "bold"
      }
    },
    xAxis: {
      categories: data.categories,
      title: {
        text: null,
      },
      style: {
        marginTop: 20
      }
    },
    yAxis: {
      min: 0,
      max: 100,
      gridLineDashStyle: 'shortdash',
      title: {
        text: null,
      },
      labels: {
        format: '{value} %'
      },
      plotLines: thresholdPlotLines,
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
          ) || "gray"
        }
      },
      opposite: true,
    },
    legend: {
      align: "right",
      x: 0,
      verticalAlign: "top",
      y: 0,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
      borderColor: "#CCC",
      borderWidth: 1,
      shadow: false,
      enabled: false,
    },
    tooltip: {
      backgroundColor: "#000",
      borderColor: "#000",
      borderRadius: 15,
      outside: true,
      style: {
        color: "#fff",
        zIndex: 10,
      },
      headerFormat: "<b>{point.x}</b><br/><br/>",
      pointFormat: "{series.name}: {point.y:,.0f}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        borderWidth: 1,
        stacking: "percent",
        dataLabels: {
          enabled: false
        }
      }
    },
    series: data.series.map((series,i) => {
      series.color = colors[i]
      series.borderColor = colors[i]
      return series
    })
  })
}

function buildThresholdPlotLines (thresholdConfigs) {
  const plotLines = []

  thresholdConfigs.forEach(function (thresholdConfig) {
    const plotLine = {
      value: thresholdConfig.value,
      dashStyle: 'shortdash',
      width: 1,
      label: {
        text: thresholdConfig.name,
        align: 'right',
        style: {}
      },
      zIndex: 3
    }

    if (thresholdConfig.name === 'T1') {
      plotLine.color = '#ee6d1b'
      plotLine.label.x = 28
      plotLine.label.y = 5
      plotLine.label.style.color = '#ee6d1b'
    } else if (thresholdConfig.name === 'T2') {
      plotLine.color = '#db2626'
      plotLine.label.x = 30
      plotLine.label.y = 3
      plotLine.label.style.color = '#db2626'
    }

    if (thresholdConfig.enabled === 'true') {
      plotLines.push(plotLine)
    }
  })

  return plotLines
}

window.buildThresholdPlotLines = buildThresholdPlotLines
