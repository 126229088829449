import { setupMenus, removeMenus } from './components/menu';
import { setupQuerySearch } from './components/query_search';
import { setupModals } from './components/modals';
import { setupDynamicForms } from './components/dynamic_forms';
import setupAllDatePickers from './components/datepicker';
import setupDestroyableButton from './components/destroyable_button';
import setupFullSizeImageModal from './components/full_size_image_modal';
import setupPaginators from './components/paginator';
import { setupCopyToClipboardComponents } from './components/clipboard_copy';
import setupElementRemoverComponents from './components/element_remover';
import setupSortableComponents from './components/sortable';
import setupHideableComponents from './components/hideable';
import setupTimerComponents from './components/timer';
import setupMergeIntegratorsComponent from './components/merge_integrator';
import { setupTabComponents } from './components/tabs';
import { setupAlertMenu, setupAlertTemplateMenu } from './components/alert_form';

// Its only responsability is to call setup components once page ready
document.addEventListener('turbolinks:load', () => {
  // There are places where turbolinks:load event is trigered and all scripts
  // are executed again. Here we remove listeners first, preventing bugs
  setupQuerySearch();
  removeMenus();
  setupMenus();
  setupModals();
  setupAllDatePickers();
  setupDynamicForms();
  setupDestroyableButton();
  setupFullSizeImageModal();
  setupPaginators();
  setupCopyToClipboardComponents();
  setupElementRemoverComponents();
  setupSortableComponents();
  setupHideableComponents();
  setupTimerComponents();
  setupMergeIntegratorsComponent();
  setupAlertMenu();
  setupAlertTemplateMenu();
  setupTabComponents();
});
