import { toggleUrlParam } from '../utils/location';

const EnterKey = 'Enter';

document.addEventListener('turbolinks:load', () => {
  const personNameInput = document.getElementById('person-name-text-input');
  if (!personNameInput) return;

  personNameInput.addEventListener('keydown', (e) => {
    if (e.key === EnterKey) {
      const personName = e.target.value;
      toggleUrlParam('person_name', personName);
    }
  });
});
