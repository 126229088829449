document.addEventListener('turbolinks:load', () => {
  const nextBtnTriggers = document.querySelectorAll('*[data-role="next-btn-trigger"]');
  const requiredMultipleChoices = document.querySelectorAll('*[data-role="required-multiple-choice"]');
  const requiredTextInputs = document.querySelectorAll('*[data-role="required-text-input"]');
  const paginatorNextBtn = document.querySelector('button[data-paginator-action="next-btn"]');
  if(!paginatorNextBtn) return;

  if(nextBtnTriggers) {
    nextBtnTriggers.forEach((element) => element.addEventListener('click', () => {
      const section = element.closest('section');
      const submitBtn = section.querySelector('#checkInSubmitBtn');
      const answerHiddenInput = section.querySelector('input[type=hidden]');
      if (element.classList.contains('single-choice-answer')) {
        answerHiddenInput.value = element.textContent.trim();
        if (submitBtn) {
          submitBtn.click();
          return;
        }
      }
      paginatorNextBtn.click();
    }));
  }

  if(requiredMultipleChoices) {
    requiredMultipleChoices.forEach((element) => element.addEventListener('click', () => {
      const section = element.closest('section');
      const nextBtnTrigger = section.querySelector('*[data-role="next-btn-trigger"]');
      const submitBtn = section.querySelector('#checkInSubmitBtn');
      if (submitBtn) {
        submitBtn.parentElement.classList.remove('hidden');
      }
      else if (nextBtnTrigger) {
        nextBtnTrigger.parentElement.classList.remove('hidden');
      }
    }));
  }

  if(requiredTextInputs) {
    requiredTextInputs.forEach((element) => element.addEventListener('keyup', () => {
      const section = element.closest('section');
      const nextBtnTrigger = section.querySelector('*[data-role="next-btn-trigger"]');
      const submitBtn = section.querySelector('#checkInSubmitBtn');
      if (submitBtn) {
        submitBtn.parentElement.classList.remove('hidden');
      }
      else if (nextBtnTrigger) {
        nextBtnTrigger.parentElement.classList.remove('hidden');
      }
    }));
  }
});
