import drawAbsenceHistoryChart from './staffing_levels/absence_history_chart.js';
import drawTrendChart from './staffing_levels/trend_chart.js';
import setupDeltaTooltips from './staffing_levels/delta_tooltip.js';

const NOW = new Date();
let threeMonthsAgo = new Date();
threeMonthsAgo.setDate(NOW.getDate() - 90);

document.addEventListener('turbolinks:load', () => {
  const todayIso8601 = new Date().toLocaleDateString('sv').substring(0, 10);
  const trendChartContainers = document.querySelectorAll('.chart-trend');
  trendChartContainers.forEach((chartContainer) => {
    const trendData = chartContainer.dataset.trend
    if(!trendData) return;

    const chartData = JSON.parse(trendData);
    const upcomingWeekReturns = JSON.parse(chartContainer.dataset.upcomingWeekReturns);
    const upcomingWeekAbsences = JSON.parse(chartContainer.dataset.upcomingWeekAbsences);
    let totalSeries = [];
    let actualSeries = [];
    let detrimentalsSeries = [];
    let dates = [];
    let oneMonthAgo = new Date();
    let oneWeekFromNow = new Date();
    oneMonthAgo.setDate(NOW.getDate() - 30);
    oneWeekFromNow.setDate(NOW.getDate() + 7);

    let todayTotal;
    let predictedDayData;

    for (const date = oneMonthAgo; date <= oneWeekFromNow; date.setDate(date.getDate() + 1)) {
      const formattedDate = date.toISOString().substring(0, 10);
      
      dates.push(formattedDate);
      if(formattedDate === todayIso8601) todayTotal = chartData[formattedDate] || [];
      const dayData = chartData[formattedDate] || []
      // We are generating predicted data each time a future absence is created
      // if no absences, no data in the future
      if(formattedDate <= todayIso8601) {
        totalSeries.push(dayData['total'] || 0);
        detrimentalsSeries.push(dayData['detrimental'] || 0);
        actualSeries.push(dayData['current'] || 0);
      } else {
        // If no data in the future, then we rely on current data(TODAY stats)
        totalSeries.push(dayData['total'] || todayTotal['total'] || 0);
        detrimentalsSeries.push(dayData['detrimental'] || todayTotal['detrimental'] || 0);
        predictedDayData = (predictedDayData || todayTotal['current']) +
                           (upcomingWeekReturns[formattedDate] || 0) -
                           (upcomingWeekAbsences[formattedDate] || 0);
        actualSeries.push(predictedDayData || 0);
      }
    }
    drawTrendChart(chartContainer, dates, todayIso8601, totalSeries, actualSeries, detrimentalsSeries);
  });
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const chartContainers = document.querySelectorAll('[data-component="absence-history"]')
  chartContainers.forEach((chartContainer) => setupStaffingLevelsListeners(chartContainer, csrfToken));

  setupDeltaTooltips();
});

const setupStaffingLevelsListeners = (chartContainer, csrfToken) => {
  const showChartButton = chartContainer.querySelector('[data-action="showChart"]');
  showChartButton.addEventListener('click', () => {
    if(chartContainer.dataset.chartSettedUp) return;

    const jobTitleId = chartContainer.dataset.jobTitleId;
    const groupId = chartContainer.dataset.groupId;
    const physicalLocationId = chartContainer.dataset.physicalLocationId;
    const options =  { headers: { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json' } };
    let url = `/staffing_levels/${jobTitleId}/?`;
    if(groupId) url += `group_id=${groupId}&`;
    if(physicalLocationId) url += `physical_location_id=${physicalLocationId}`;
    fetch(url, options)
      .then(response => response.json())
      .then(data => processData(chartContainer, data, jobTitleId));
    chartContainer.dataset.chartSettedUp = true;
  });
};

const processData = (chartContainer, data, jobTitleId) => {
  const workingData = [];
  const sickData = [];
  const personalData = [];
  const vacationData = [];
  // looks like dates are passed as references. new Date is required here per setDate
  for (const date = new Date(threeMonthsAgo); date <= NOW; date.setDate(date.getDate() + 1)) {
    const formattedDate = date.toISOString().substring(0, 10);
    const absenceHistory = data['absence_history'][formattedDate] || {};
    workingData.push(absenceHistory['Working'] || 0);
    sickData.push(absenceHistory['Sick'] || 0);
    personalData.push(absenceHistory['Personal'] || 0);
    vacationData.push(absenceHistory['Vacation'] || 0);
  }
  const chartSeries = buildChartSeries(threeMonthsAgo, workingData, sickData, personalData, vacationData);
  updateStaffingLevelData(chartContainer, jobTitleId, data, chartSeries);
};

const updateStaffingLevelData = (chartContainer, jobTitleId, data, chartSeries) => {
  const upcomingTerminations = chartContainer.querySelector('[data-target="upcoming-terminations"]');
  const upcomingNewHires = chartContainer.querySelector('[data-target="upcoming-new-hires"]');
  const upcomingAbsences = chartContainer.querySelector('[data-target="upcoming-absences"]');
  const upcomingReturns = chartContainer.querySelector('[data-target="upcoming-returns"]');

  if (data['upcoming_terminations'] == 0 ) upcomingTerminations.classList.add('text-gray-500')
  upcomingTerminations.innerHTML = data['upcoming_terminations'];
  if (data['upcoming_new_hires'] == 0 ) upcomingNewHires.classList.add('text-gray-500')
  upcomingNewHires.innerHTML = data['upcoming_new_hires'];
  if (data['upcoming_absences'] == 0 ) upcomingAbsences.classList.add('text-gray-500')
  upcomingAbsences.innerHTML = data['upcoming_absences'];
  if (data['upcoming_returns'] == 0 ) upcomingReturns.classList.add('text-gray-500')
  upcomingReturns.innerHTML = data['upcoming_returns'];

  setBackgrondColor(upcomingTerminations, data['upcoming_terminations'], true);
  setBackgrondColor(upcomingAbsences, data['upcoming_absences'], true);
  setBackgrondColor(upcomingNewHires, data['upcoming_new_hires']);
  setBackgrondColor(upcomingReturns, data['upcoming_returns']);

  drawAbsenceHistoryChart(jobTitleId, chartSeries);
};

const setBackgrondColor = (element, value, negative = false) => {
  const card = element.parentElement.parentElement.querySelector('.card-left');
  card.classList.remove('cool-gray', 'rose', 'green');

  if (value == 0) {
    card.classList.add('cool-gray');
  } else if (negative) {
    card.classList.add('rose');
  }
  else {
    card.classList.add('green');
  };
};

const buildChartSeries = (startTime, workingData, sickData, personalData, vacationData) => {
  const startDateUTC = Date.UTC(startTime.getUTCFullYear(), startTime.getUTCMonth(), startTime.getUTCDate());

  return [
    {
      name: 'Working',
      color: '#A7F3D0',
      lineColor: '#BBF7D0',
      borderColor: '#D1FAE5',
      marker: {
        lineColor: '#86EFAC',
      },
      data: workingData,
      pointStart: startDateUTC,
      pointInterval: 86400 * 1000
    },{
      name: 'Sick',
      color: '#F43F5E',
      lineColor: '#E11D48',
      borderColor: '#9F1239',
      marker: {
        lineColor: '#9F1239',
      },
      data: sickData,
      pointStart: startDateUTC,
      pointInterval: 86400 * 1000
    }, {
      name: 'Personal',
      color: '#FB7185',
      lineColor: '#BE123C',
      borderColor: '#BE123C',
      marker: {
        lineColor: '#BE123C',
      },
      data: personalData,
      pointStart: startDateUTC,
      pointInterval: 86400 * 1000
    }, {
      name: 'Vacation',
      color: '#FDA4AF',
      borderColor: '#9F1239',
      data: vacationData,
      pointStart: startDateUTC,
      pointInterval: 86400 * 1000
    }
  ];
};
