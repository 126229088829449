document.addEventListener('turbolinks:load', function (_event) {
  const searchForm = document.querySelector('#personSearch');
  const caseManagerId = document.querySelector('#case_manager_id');

  // When selecting a search result:
  // - Case manager id hidden field is populated with the selected person's info
  if (searchForm && caseManagerId) {
    searchForm.querySelector('#searchResults').addEventListener('click', function (e) {
      if (e.target.tagName === 'LI') {
        caseManagerId.value = e.target.dataset.personId;
      }
    });

    // When clicking the search field and field has a person info:
    // - Clear case manager id
    searchForm.querySelector('#search').addEventListener('click', function (_e) {
      caseManagerId.value = '';
    });
  }
});
