import filterParams from "../filter_params";

const ENTER_KEYCODE = 13;

// keypress event has been deprecated! beforeinput or keydown are the new standard.
// https://developer.mozilla.org/en-US/docs/Web/API/Element/keypress_event
const setupQuerySearch = () => {
  const queryField = document.querySelector('input#q');
  if (!queryField) return;

  queryField.addEventListener('keydown', (e) => {
    const keycode = e.keyCode ?? e.which;
    if (keycode !== ENTER_KEYCODE) return;

    filterParams('q', e.target.value);
  });
}

export { setupQuerySearch };
