import { subscribeToNewListEvent } from './disruptions_subscriber';

document.addEventListener('turbolinks:load', () => {
  const disruptionsContainer = document.getElementById('current_events_indicator')
  if(!disruptionsContainer) return;

  const { organizationUid } = disruptionsContainer.dataset;
  subscribeToNewListEvent(processListEvent, organizationUid);
});

// We're expecting message.data to be json thart includes event public uid
const processListEvent = (message) => {
  const data = message.data || {};
  const disruptiveEventPublicUid = data['disruptive_event_public_uid'];
  if (!disruptiveEventPublicUid) return;

  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const options =  { headers: { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/json' } };
  fetch(`/disruptions/${disruptiveEventPublicUid}.json`, options)
  .then(response => response.text())
  .then(html => {
    cleaUpEmptyStates();
    insertNewItem(html)
    animateNewItem();
  });
}

const insertNewItem = (itemHTML) => {
  const disruptionsContainer = document.getElementById('current_events_indicator');
  disruptionsContainer.insertAdjacentHTML('afterEnd', itemHTML);
}

const cleaUpEmptyStates = () => {
  const noEventsContainer = document.getElementById('no_events_container');
  if(noEventsContainer) noEventsContainer.remove();
  const noCurrentEventsContainer = document.getElementById('no_current_events_container');
  if(noCurrentEventsContainer) noCurrentEventsContainer.remove();
}

const animateNewItem = () => {
  const justAddedElement = document.getElementById('disruptions_container').querySelector('a');
  setTimeout(()=> {
    justAddedElement.classList.add('scale-105');
  }, 500);
  setTimeout(()=> {
    justAddedElement.classList.remove('scale-105');
  }, 1000);
};
