import QrScanner from 'qr-scanner';
import { selectDropDownOptionByText } from './utils/selects.js'
import { hideActiveModal, setupModal } from './utils/modals.js'
QrScanner.WORKER_PATH = "/qr-scanner-worker.js";
let readingAllowed = true;
let videoSelect;
let videoElement;
let currentStreamName; // we have a timing error that is really hard to resolve cross browser...
const URL_REGEX = /\/([\w]+)\/([\w]+)$/;

// TODO: we could refactor a little bit, using a _utils.js and adding some tests
document.addEventListener("turbolinks:load", () => {
  stopStreaming(); // Let's stop streaming after page load
  videoElement = document.getElementById("qr-video");
  if (!videoElement) return;

  // let's setup dropdown
  videoSelect = $("select#video_source");
  $(videoSelect).on('change', getStream);
  $(videoElement).on("loadeddata", setupScannerVideo);
  $(window).on("resize", setupScannerVideo);

  window.qrScanner = createQrScannerInstance(videoElement);
  $("#start-scanning").click((event) => {
    getStream().then(getDevices).then(populateDevicesSelect);
    playSound();
    window.qrScanner.start();
    $(event.target).addClass("hidden");
  });
});

function buildOnSiteScreeningLink(barcodeURI) {
  const link = `<a
    href='${barcodeURI}'
    id='start-onsite-screening'
    target='_blank'
    class='bg-indigo-600 text-center rounded py-2 px-4 font-bold text-white block m-auto mt-12'
    >
      Proceed with Screening
    </a>`;
  return link;
}

function getDevices() {
  // AFAICT in Safari this only gets default devices until gUM is called :/
  return navigator.mediaDevices.enumerateDevices();
}

function populateDevicesSelect(deviceInfos) {
  window.deviceInfos = deviceInfos; // make available to console
  videoSelect.find('option').remove();// Let's remove first dropdown option
  deviceInfos.forEach((deviceInfo, index) => {
    if(deviceInfo.kind !== "videoinput") return;

    const selectOption = buildDeviceInfoOption(deviceInfo, index);
    videoSelect.append(selectOption);
  });
  selectDropDownOptionByText(videoSelect, currentStreamName);
}

function buildDeviceInfoOption (deviceInfo, index) {
  const value = deviceInfo.deviceId;
  const text = deviceInfo.label || `Camera ${index + 1}`;

  return $('<option>', { value, text });
}

function getStream() {
  // stop existing stream if it is running...
  if (window.stream)  stopStreaming()
  // search with getUserMedia for the exact stream selected in the drop down and
  // that will be returned and then passed to setupStream.
  const videoSource = $(videoSelect).val();
  const deviceId = videoSource ? { exact: videoSource } : undefined
  const constraints = { video: { deviceId } };
  return navigator.mediaDevices.getUserMedia(constraints).then(setupStream).catch(handleError);
}

// This sync's the select on the first load to the appropriate camera
function setupStream(stream) {
  window.stream = stream; // make stream available to console
  // we have to keep track of current stream because the drop down list is not always populated before
  // we have a stream. :(
  currentStreamName = stream.getVideoTracks()[0].label;
  selectDropDownOptionByText(videoSelect, currentStreamName);
  videoElement.srcObject = stream;
}

function handleError(error) {
  console.error('Error: ', error);
}

// Artificial limit imposed because the event for recognizing code is continuous
function waitForReadindToBeAllow() {
  readingAllowed = false;
  setTimeout(() => {
    readingAllowed = true;
  }, 3000)
}

function playSound(sound = "scanned") {
  // currently, we are swapping sounds around with one audio element having permission to play.
  // we should explore getting permission for all audio elements and then playing individually.
  // latency might be lower.
  const url = $(`audio#${sound}`).attr("src");
  const audio = $('audio#player')[0];
  audio.src = url;
  audio.load();
  audio.pause();
  audio.currentTime = 0;
  audio.play();
  // only muted for first play
  const isMuted = $("#player").prop("muted");
  if (isMuted) {
    setTimeout(() => {
      $("#player").prop("muted", false);
    }, 1000);
  }
}

function setupScannerVideo() {
  $("#video-container").removeClass("hidden");
  resizeScannerVideo();
}

function resizeScannerVideo() {
  const $video = $("#qr-video");
  let width = $video.width();
  if ($video.height() < $video.width()) {
    width = $video.height();
  }
  width = width * 0.66;
  $("#focus-ring").css("width", width).css("height", width).css("border-color", "#cccccc");
  $("#focus-ring").css("left", ($video.width() / 2) - (width / 2));
  $("#focus-ring").css("top", ($video.height() / 2) - (width / 2));

  $("#frame").css("left", $("#focus-ring").css("left"));
  $("#frame").css("right", $("#focus-ring").css("right"));
  $("#frame").css("top", $("#focus-ring").css("top"));
  $("#frame").css("bottom", $("#focus-ring").css("bottom"));
}

function parseCode(result) {
  // for https://app.cleartogo.co/v/OyXDKQ2Bx0
  // returns ["/v/OyXDKQ2Bx0", "v", "OyXDKQ2Bx0"]
  const matches = URL_REGEX.exec(result);
  const isMatchValid = (matches && matches.length === 3)
  if (!isMatchValid) return { failed: true };

  const [uri, action, screeningId] = matches;
  return { uri, action, screeningId };
}

function stopStreaming() {
  //track.stop is useless if qrScanner stills working
  if (window.qrScanner) window.qrScanner.stop();

  if (window.stream) {
    const tracks = window.stream.getTracks()
    tracks.forEach((track) => track.stop());
  }
}

// TODO: Let's refactor this
function createQrScannerInstance(videoElement) {
  const qrScanner = new QrScanner(videoElement, (result) => {
    // we are seeing some weird cases where this is being called with no data.
    if (!result) return;

    if (!readingAllowed) return;

    waitForReadindToBeAllow();
    const locationId = $("#location-select option:selected").val();
    if (!locationId) {
      alert("Please select a location before scanning");
      return;
    }

    const barcode = parseCode(result);

    if (barcode.failed) {
      $("#focus-ring").css("border-color", "#FF0000").css("border-width", "2px");
      setTimeout(() => {
        $("#focus-ring").css("border-color", "#cccccc").css("border-width", "1px");
      }, 800);
      playSound("denied");
      alert(`Unknown format: ${result}`);
    } else {
      $("#focus-ring").css("border-color", "#00FF00").css("border-width", "2px");
      setTimeout(() => {
        $("#focus-ring").css("border-color", "#cccccc").css("border-width", "1px");
      }, 800);
      playSound();
    }

    if (barcode.action.startsWith('v')) {
      $.post(`/scanner/${barcode.screeningId}/toggle?location_id=${locationId}`, {}, (data) => {
        const modalContent = `${data.action}: ${data.name}`;
        setupModal("#scan-modal", "Good!", modalContent);
        setTimeout(hideActiveModal, 1500);
        playSound("checked_in");
      }).fail((xhr) => {
        let message = "Could not check in.";
        if (xhr.responseJSON) {
          message = xhr.responseJSON["message"]
        } else if (xhr.status === 404) {
          message = "Could not find screening.";
        }

        setupModal("#scan-modal", "Problem", message);
        setTimeout(hideActiveModal, 2000);
        playSound("denied");
      });

    } else if (barcode.action === 'o') {
      const modalContent = buildOnSiteScreeningLink(barcode.uri);
      setupModal("#scan-modal", "On-site Screening Required", modalContent);
      // Close existing modal after starting to screen...
      $("#start-onsite-screening").click(hideActiveModal);
    } else { // If not a verification code, then onsite-screening...
      alert(`Unknown action for: ${response}`);
    }
  });

  return qrScanner;
}
