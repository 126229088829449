import { getSelectedValueByEvent } from './utils/selects';

const updateManufacturerOptions = () => {
  const vaccines = document.getElementById('vaccination_vaccine_id');

  if (!vaccines) return;

  const vaccineId = vaccines.value;
  const organizationId = document.getElementById('organization_id').value;
  const vaccinationId = document.getElementById('vaccination_id').value;
  const url = `/organizations/${organizationId}/vaccines/${vaccineId}/vaccine_manufacturers/new`;

  $.ajax({
    url: url,
    type: "get",
    data: {
      vaccine_id: vaccineId,
      vaccination_id: vaccinationId,
    },
    complete: (response, textStatus) => {
      if(textStatus === "success") {
        updateDosesOptions();

        document.getElementById('vaccination_vaccine_type_id').addEventListener("change", (e) => {
          updateDosesOptions();
        })
      }
    }
  });
};

const updateDosesOptions = () => {
  const vaccines = document.getElementById('vaccination_vaccine_id');
  const vaccineId = vaccines.value;
  const manufacturers = document.getElementById('vaccination_vaccine_type_id');
  const manufacturerId = manufacturers.value;
  const organizationId = document.getElementById('organization_id').value;
  const vaccinationId = document.getElementById('vaccination_id').value;
  const url = `/organizations/${organizationId}/vaccines/${vaccineId}/vaccine_manufacturers/${manufacturerId}`;

  $.ajax({
    url: url,
    type: "get",
    data: {
      vaccination_id: vaccinationId,
    },
    complete: () => {
      setupDosesListener();
    }
  });
};

const setupDosesListener = () => {
  const receivedDosesSelect = document.getElementById("received-doses-select");
  if (!receivedDosesSelect) return;

  receivedDosesSelect.addEventListener("change", (e) => {
    const receivedDosesInput = document.getElementById("received_doses_input");
    const selectedValue = getSelectedValueByEvent(e);
    const isBooster = selectedValue === "Booster";
    document.getElementById("boosters_input").value = isBooster;
    if (!isBooster) receivedDosesInput.value = selectedValue;
  });
}

export { updateManufacturerOptions, setupDosesListener }

document.addEventListener("turbolinks:load", () => {
  updateManufacturerOptions();
})
