import Highcharts from "../highcharts_config";

export default function renderChart (data) {
  const chartContainer = document.getElementById("cases-distribution-chart");

  if(!chartContainer) return;

  chartContainer.setAttribute("style", null)

  Highcharts.chart(chartContainer.id, {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column"
    },
    title: {
      text: data.name,
      align: "left",
      margin: 15,
      style: {
        fontWeight: "bold"
      }
    },
    xAxis: {
      categories: data.categories,
      title: {
        text: "Week",
      },
      style: {
        marginTop: 5
      },
      maxPadding: 0,
      pointWidth: 5

    },
    yAxis: {
      min: 0,
      gridLineDashStyle: 'shortdash',
      opposite: true,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: false,
        style: {
          fontWeight: "bold",
          color: ( // theme
              Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color
          ) || "gray"
        }
      }
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "#000",
      borderColor: "#000",
      borderRadius: 15,
      style: {
        color: "#fff",
      },
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{point.y:,.0f}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      }

    },
    series: data.series.map((series, i) => {
      series.color = "#F0791d"
      series.borderColor = "#F0791d"
      return series
    })
  })
}
