import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const setupDeltaTooltips = () => {
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const options =  { headers: { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/text' } };
  const deltas = document.querySelectorAll('[id^="chart-delta-"]');

  deltas.forEach((delta) => {
    const availability = delta.dataset.availability;
    const detrimental = delta.dataset.detrimental;
    const dateReference = delta.dataset.dateReference;
    const deltaRow = delta.closest('tbody');
    const jobTitleId = deltaRow.dataset.jobTitleId;
    const groupId = deltaRow.dataset.groupId;
    const physicalLocationId = deltaRow.dataset.physicalLocationId;
    let url = `/staffing_levels/${jobTitleId}/?delta_details=true&date_reference=${dateReference}&availability=${availability}&detrimental=${detrimental}`;

    if (groupId) { url += `&group_id=${groupId}`; }
    if (physicalLocationId) { url += `&physical_location_id=${physicalLocationId}`; }

    tippy(`#${delta.id}`, {
      theme: 'black',
      allowHTML: true,
      content: 'Loading...',
      delay: 500,
      onCreate(instance) {
        instance._isFetching = false;
        instance._src = null;
        instance._error = null;
      },
      onShow(instance) {
        if (instance._isFetching || instance._src || instance._error) {
          return;
        }

        instance._isFetching = true;

        fetch(url, options)
          .then(response => response.text())
          .then(data => {
            instance._src = data;
            instance.setContent(data)
          })
          .catch((error) => {
            instance._error = error;
            instance.setContent('Failed to load.');
          })
          .finally(() => {
            instance._isFetching = false;
          });
      }
    });
  });
};

export default setupDeltaTooltips;
