import setupDestroyableButton from './components/destroyable_button';

export default function initThresholdsControls () {
  const addThresholdBtn = document.getElementById("add-threshold-btn")
  if (!addThresholdBtn) return

  setupDestroyableButton()
  setupAddTresholdButton(addThresholdBtn)
}

window.initThresholdsControls = initThresholdsControls

function setupAddTresholdButton (addThresholdBtn) {
  addThresholdBtn.addEventListener("click", (event) => {
    event.preventDefault();

    const thresholdContainers = document.querySelectorAll(".threshold-container")
    let found = false

    thresholdContainers.forEach((container) => {
      if (found) return

      const enabledInput = container.querySelector("input[type='hidden'][name*=enabled]")
      const destroyInput = container.querySelector("input[type='hidden'][name*=destroy]")

      if (enabledInput.value.length === 0 ||
        enabledInput.value === 'false' ||
        destroyInput.value === 'true') {
        found = true
        container.classList.remove('hidden')

        enabledInput.value = true
        destroyInput.value = false

        const hiddenThresholdContainers = document.querySelectorAll(".threshold-container.hidden")

        if (hiddenThresholdContainers.length === 0) {
          addThresholdBtn.classList.add("hidden")
        }

        return
      }
    })
  })

  const removeBtns = document.querySelectorAll(".threshold-container button[component='destroyable']")
  removeBtns.forEach((removeBtn) => {
    removeBtn.addEventListener('click', (event) => {
      const thresholdContainer = document.getElementById(event.currentTarget.dataset.container)

      thresholdContainer.querySelector("input[type='hidden'][name*=enabled]").value = false
      thresholdContainer.querySelector("input[name*=value]").value = null
      thresholdContainer.querySelector("input[name*=label]").value = null

      const hiddenThresholdContainers = document.querySelectorAll(".threshold-container.hidden")
      if (hiddenThresholdContainers.length < 2) {
        addThresholdBtn.classList.remove("hidden")
      }
    })
  })
}
