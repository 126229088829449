document.addEventListener('turbolinks:load', () => {
  handleRowLinks();
});

// TODO: Find a way to reuse this behavior. Maybe with a stimulus controller?
const handleRowLinks = () => {
  const managersTable = document.getElementById('work-climate-managers-table');
  if (!managersTable) return;

  document.querySelectorAll('tr[data-href]').forEach((row) => {
    row.addEventListener('click', () => {
      window.document.location = row.dataset.href;
    });
  });
}
