document.addEventListener('turbolinks:load', function (_event) {
  // Person search in contact form
  const searchForm = document.querySelector('#personSearch');
  const newContactForm = document.querySelector('#new_contact');

  if (searchForm && newContactForm) {
    const contactable_id = newContactForm.querySelector('#contact_contactable_id');
    const contactable_type = newContactForm.querySelector('#contact_contactable_type');
    const email = newContactForm.querySelector('#contact_email');
    const first_name = newContactForm.querySelector('#contact_first_name');
    const last_name = newContactForm.querySelector('#contact_last_name');
    const cell_phone = newContactForm.querySelector('#contact_cell_phone');
    const external_relationship = newContactForm.querySelector('#contact_external_relationship');

    // When selecting a search result:
    // - Contact form hidden fields are populated with the selected person's info
    searchForm.querySelector('#searchResults').addEventListener('click', function (e) {
      if (e.target.tagName === 'LI') {
        contactable_id.value = e.target.dataset.personId;
        contactable_type.value = e.target.dataset.personType;
        email.value = e.target.dataset.email;
        first_name.value = e.target.dataset.firstName;
        last_name.value = e.target.dataset.lastName;
        cell_phone.value = e.target.dataset.cellPhone;
        external_relationship.value = e.target.dataset.personType;
      }
    });
    // When clicking the search field and field has a person info:
    // - Clear the Contact form hidden fields
    searchForm.querySelector('#search').addEventListener('click', function (_e) {
      contactable_id.value = '';
      contactable_type.value = '';
      email.value = '';
      first_name.value = '';
      last_name.value = '';
      cell_phone.value = '';
      external_relationship.value = '';
    });
  }
});
