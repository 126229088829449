import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="absence-form"
export default class extends Controller {
  static targets = [
    'personType',
    'backupId',
    'backupContainer',
    'backupMessage',
    'localityContainer',
    'locality',
    'workCapacity',
    'backupSearchField'
  ]
  static values = { currentEmployeeId: Number }

  connect() {
    this.toggleBackupId();
  }

  toggleBackupId() {
    const { canLogin } = this.personTypeTarget.dataset;
    canLogin == 'true' ? this.showBackupId() : this.hideBackupId();
    this.backupSearchFieldTarget.value = this.backupSearchFieldTarget.dataset.backupFormatedIdentifiers;
  }

  toggleBackupMessage() {
    this.shouldShowBackupMessage() ? this.showBackupMessage() : this.hideBackupMessage();
  }

  shouldShowBackupMessage() {
    const backupId = this.backupIdTarget.value;
    const { managerId } = this.personTypeTarget.dataset;
    return backupId && managerId && backupId != this.currentEmployeeIdValue;
  }

  hideBackupMessage() {
    this.backupMessageTarget.classList.add('hidden');
    this.backupMessageTarget.innerText = '';
  }

  showBackupMessage() {
    const subjectFirstName = this.personTypeTarget.dataset.firstName;
    const managerFirstName = this.backupIdTarget.dataset.firstName;
    const message = `Notifications will be sent to ${subjectFirstName}'s manager: ${managerFirstName}`;
    this.backupMessageTarget.innerText = message;
    this.backupMessageTarget.classList.remove('hidden');
  }

  showBackupId() {
    this.backupIdTarget.removeAttribute('readonly');
    this.backupContainerTarget.classList.remove('hidden');
  }

  hideBackupId() {
    this.backupIdTarget.setAttribute('readonly', true);
    this.backupContainerTarget.classList.add('hidden');
  }

  toggleLocality() {
    const shouldShowLocality = this.workCapacityTarget.value != 'not_working';
    shouldShowLocality ? this.showLocality() : this.hideLocality();
  }

  hideLocality() {
    this.localityContainerTarget.classList.add('hidden');
    this.localityTarget.setAttribute('readonly', true);
  }

  showLocality() {
    this.localityContainerTarget.classList.remove('hidden');
    this.localityTarget.removeAttribute('readonly');
  }
}
