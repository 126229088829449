document.addEventListener("turbolinks:load", function(event) {
  const visitorRegistrations = $('#visitor-registration')

  if (visitorRegistrations.length > 0) {
    $('#registration-button').on('click', function() {
      $('#registration-screen-1').addClass('hidden')
      $('#registration-screen-2').removeClass('hidden')
    });

    $("#proxy-registration span").on("click", function() {
      $("#proxy-registration").find(".hidden").removeClass("hidden")
      $("#guardian_email").attr("required", true)
      $(this).closest("p").addClass("hidden")
    })

    // Remember some fields upon submit, and fill them back in next time
    const fieldsToRemember = [
      "first_name",
      "last_name",
      "cell_phone",
      "email",
      "company_name",
      "visiting",
    ]

    const rememberedData = Cookies.get("visitor_registration_remembers")
    if (rememberedData) {
      const rememberedFields = JSON.parse(rememberedData)
      Object.keys(rememberedFields).map((field) => {
        const value = rememberedFields[field]
        $(`#visitor-registration input#${field}`).val(value)
      })
    }

    var form = $('#visitor-registration form').submit((event) => {
      const contact_required = form.data("contact-required");
      const cell_phone = $("#visitor-registration form [data-contact-field='cell_phone']").val();
      const email = $("#visitor-registration form [data-contact-field='email']").val();
      let values = [ cell_phone, email ].filter((obj) => obj );

      if(contact_required === "either") {
        if(values.length < 1) {
          alert("Either email or cell phone is required");
          event.preventDefault();
          return false;
        }
      } else if(contact_required === "both") {
        if(values.length < 2) {
          alert("Both email and cell phone are required");
          event.preventDefault();
          return false;
        }
      }

      const formData = fieldsToRemember.reduce((data, field) => {
        data[field] = $(`#visitor-registration input#${field}`).val()
        return data
      }, {})

      // expires in 100 days
      Cookies.set("visitor_registration_remembers", JSON.stringify(formData), {expires: 100})
    })
  }
});


