import {Controller} from "@hotwired/stimulus"

/**
 * Handles the UI transition from trial mode to full paying subscriber mode.
 *
 * Connects to data-controller="onboarding-activate"
 */
export default class extends Controller {
  activate() {
    let bigButton = document.getElementById("activate_account_container");
    let desktopMenu = document.getElementById("desktop-menu");
    let navbar = document.getElementById("navbar");
    let launchpad = document.getElementById("launchpad");

    window.history.replaceState({}, document.title, "/");

    setTimeout(function () {
      bigButton.classList.remove("opacity-100");
      bigButton.classList.add("opacity-0", "hidden", "pointer-events-none");
    }, 0);
    setTimeout(function () {
      desktopMenu.classList.remove("opacity-0");
      desktopMenu.classList.add("opacity-100");
    }, 250);
    setTimeout(function () {
      navbar.classList.remove("opacity-0");
      navbar.classList.add("opacity-100");
    }, 1000);
    setTimeout(function () {
      launchpad.classList.remove("opacity-0");
      launchpad.classList.add("opacity-100");
    }, 1500);
  }
}
