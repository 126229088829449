const MOBILE_CARD_COLORS = {
  'red': { 'background': '#EF4444', 'border': '#DC2626' },
  'orange': { 'background': '#F97316', 'border': '#EA580C' },
  'yellow': { 'background': '#EAB308', 'border': '#CA8A04' },
  'lime': { 'background': '#84CC16', 'border': '#65A30D' },
  'green': { 'background': '#22C55E', 'border': '#16A34A' },
  'teal': { 'background': '#14B8A6', 'border': '#0D9488' },
  'cyan': { 'background': '#06B6D4', 'border': '#0891B2' },
  'blue': { 'background': '#3B82F6', 'border': '#2563EB' },
  'violet': { 'background': '#8B5CF6', 'border': '#7C3AED' },
  'purple': { 'background': '#A855F7', 'border': '#9333EA' },
  'pink': { 'background': '#EC4899', 'border': '#DB2777' },
  'rose': { 'background': '#F43F5E', 'border': '#E11D48' },
  'gray': { 'background': '#64748B', 'border': '#475569' }
}

document.addEventListener('turbolinks:load', () => {
  const caseTypeMobileEnabledSelect = document.getElementById('caseTypeMobileEnabledSelect');
  const cardTitleInput = document.getElementById('caseTypeCardTitleInput');
  const cardColorInput = document.getElementById('caseTypeCardColorInput');
  const cardDescriptionInput = document.getElementById('caseTypeCardDescriptionInput');
  const cardIconInputs = document.querySelectorAll('input[name="case_type[icon]"]');
  if(!caseTypeMobileEnabledSelect) return;

  setupCardVisibility(caseTypeMobileEnabledSelect);
  setupCardDescriptionLiveUpdate(cardDescriptionInput);
  setupCardTitleLiveUpdate(cardTitleInput);
  setupCardColorLiveUpdate(cardColorInput);
  cardIconInputs.forEach((cardIconInput) => setupCardIconLiveUpdate(cardIconInput));
});


const setupCardVisibility = (caseTypeMobileEnabledSelect) => {
  const cardMobileContainer = document.getElementById('cardMobileContainer');
  caseTypeMobileEnabledSelect.addEventListener('change', () => {
    const titleInput = document.querySelector('input[name="case_type[card_title_en_US]"]');
    const descriptionInput = document.querySelector('textarea[name="case_type[card_description_en_US]"]');
    if(caseTypeMobileEnabledSelect.value == 'true') {
      cardMobileContainer.classList.remove('hidden');
      titleInput.setAttribute('required', true);
      descriptionInput.setAttribute('required', true);
    } else {
      cardMobileContainer.classList.add('hidden');
      titleInput.removeAttribute('required');
      descriptionInput.removeAttribute('required');
    }
  });
};

const setupCardTitleLiveUpdate = (cardTitleInput) => {
  cardTitleInput.addEventListener('change', () => {
    const mobileCardTitle = document.getElementById('mobileCardTitle');
    mobileCardTitle.innerText = cardTitleInput.value;
  });
}

const setupCardDescriptionLiveUpdate = (cardDescriptionInput) => {
  cardDescriptionInput.addEventListener('change', () => {
    const mobileCardDescription = document.getElementById('mobileCardDescription');
    mobileCardDescription.innerText = cardDescriptionInput.value;
  });
};

const setupCardIconLiveUpdate = (cardIconInput) => {
  cardIconInput.addEventListener('change', () => {
    const iconContainers = document.querySelectorAll('[id*="caseTypeCardIconContainer"]');
    iconContainers.forEach((container) => container.classList.add('hidden'));
    const iconContainerId = `caseTypeCardIconContainer${cardIconInput.value}`;
    document.getElementById(iconContainerId).classList.remove('hidden');
  });
};

const setupCardColorLiveUpdate = (cardColorInput) => {
  cardColorInput.addEventListener('change', () => {
    const mobileCard = document.getElementById('mobileCard');
    const mobileCardNewColor = cardColorInput.value;
    const cardBackgroundColor = MOBILE_CARD_COLORS[mobileCardNewColor]['background'];
    const cardBorderColor = MOBILE_CARD_COLORS[mobileCardNewColor]['border'];
    mobileCard.style = `border: solid 1px ${cardBorderColor}; background-color: ${cardBackgroundColor};`;
  });
}
