document.addEventListener('turbolinks:load', () => {
  if(!document.getElementById('case-question-container')) return;

  const questionCategorySelects = Array.from(document.querySelectorAll('select[name*="question_type"]'));
  questionCategorySelects.forEach(select => {
    select.addEventListener('change', () => toggleAnswerContainer(select));
  });

  const removeQuestionButtons = Array.from(document.querySelectorAll('button[component="destroyable"]'));
  removeQuestionButtons.forEach((button) => {
    button.addEventListener('click', () => {
      updateQuestionsContainerTitle();
    });
  });

  const toggleAnswerContainer = (select) => {
    const answersContainerId = select.getAttribute('data-target');
    const answersContainer = document.getElementById(answersContainerId);
    if (select.value === 'text_input' ) {
      answersContainer.classList.add('hidden');
    } else {
      answersContainer.classList.remove('hidden');
    };
  };

  const updateQuestionsContainerTitle = () => {
    const caseTypeQuestionsSelector = 'div[id*="case-type-question-container"]:not(.hidden) h3';
    const questionTitles = Array.from(document.querySelectorAll(caseTypeQuestionsSelector));
    questionTitles.forEach((titleTag, questionIndex) => {
      const questionNumber = questionIndex + 1;
      titleTag.innerHTML = titleTag.textContent.replace(/\d+/, questionNumber);
    });
  };

  updateQuestionsContainerTitle();
});
