const numberOfClusterZoneRanges = 20; 
const maxMarkSize = 25;
const minMarkSize = 7;
const minOfClusterGroupsSize = 5;

const parseData = (data) => {
  const scoresCounts = [];
  const series = Object.keys(data).map((facetIndex) => {
    return Object.entries(data[facetIndex]).map(([score, scoreCount]) => {
      scoresCounts.push(scoreCount);
      return {
        type: 'scatter',
        data: Array(scoreCount).fill([parseInt(score), parseInt(facetIndex)]),
        color: setColor(score)
      };
    });
  }).flat();

  const limits = {
    max: Math.max(...scoresCounts),
    min: Math.min(...scoresCounts)
  };

  return { series, limits };
};

const setColor = (score) => ({
  '-5': '#FC1F1F',
  '-4': '#FD4E4E',
  '-3': '#FE7C7C',
  '-2': '#FEA7A7',
  '-1': '#FFD1D1',
  '0': '#D1D5DB',
  '1': '#D1F2D2',
  '2': '#A1E5A4',
  '3': '#6FD773',
  '4': '#3FC944',
  '5': '#0EBC14'
})[score.toString()];

const segmentsZones = (limits) => {
  const deltaSize = (maxMarkSize - minMarkSize) / numberOfClusterZoneRanges
  const maxLimit = Math.max(limits.max, minOfClusterGroupsSize*limits.min)
  const blockSize = (maxLimit - limits.min) / numberOfClusterZoneRanges;
  const intervals = Array.from({ length: numberOfClusterZoneRanges }, (_, i) => {
    const start = limits.min + i * blockSize;
    const end = start + blockSize;
    return {
      from: start,
      to: end,
      marker: {
        symbol: 'circle',
        radius: minMarkSize + (i + 1) * deltaSize
      }
    };
  });
  return intervals;
};

export const highChartsConfig = (locationName, scoresData) => {
  const facetsName = Object.keys(scoresData);
  const parsedData = parseData(Object.values(scoresData));
  const zones = segmentsZones(parsedData.limits);
  return {
    chart: {
      zoomType: 'xy',
      plotBorderColor: '#D1D5DB',
      plotBorderWidth: 2,
      spacing: [0,1,1,0],
    },
    series: parsedData.series,
    title: {
      text: locationName,
      align: 'left',
      margin: 0,
      style: {
        fontWeight: 'bold'
      }
    },
    xAxis: {
      min: -5.25,
      max: 5.25,
      gridLineWidth: 1,
      tickInterval: 1,
      tickWidth: 0,
      opposite: true,
      gridLineColor: '#F3F4F6',
      lineWidth: 0,
      labels: {
        style: {
            color: '#4B5563'
        }
      }
    },
    yAxis: {
      categories: facetsName,
      gridLineColor: '#F3F4F6',
      title: {
        enabled: false
      },
      labels: {
        style: {
            color: '#4B5563'
        }
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      backgroundColor: "#000",
      borderColor: "#000",
      borderRadius: 15,
      style: {
        color: "#fff",
      },
      formatter: function () {
        return `<b>Score:</b> ${Math.round(this.point.x)}
        <br>
        <b>People:</b> ${this.series.xData.length}`
      }
    },
    plotOptions: {
      series: {
        marker: {
          symbol: 'circle',
          radius: minMarkSize
        },
        cluster: {
          enabled: true,
          zones: zones,
          minimumClusterSize: 0,
          drillToCluster: false,
          allowOverlap: false,
          marker: {
            lineWidth: 1
          },
          layoutAlgorithm: {
            type: 'grid',
            gridSize: 1
          },
        }
      }
    }
  }
};
