document.addEventListener("turbolinks:load", () => {
  checkBoxUpdatesSelect('location');
  checkBoxUpdatesSelect('group');
});

const checkBoxUpdatesSelect = (type) => {
  document.querySelectorAll(`.${type}-checkbox`).forEach((checkbox) => {
    checkbox.addEventListener("change", (e) => {
      let typeSelect = document.querySelector(`[data-${type}s-select]`);
      if (e.target.checked) {
        let option = document.createElement("option");
        option.text = e.target.dataset.optionLabel;
        option.value = e.target.dataset.optionValue;
        typeSelect.add(option);
      } else {
        let option = document.querySelector(`[data-${type}s-select] [value='${e.target.dataset.optionValue}']`)
        option.remove();
      }
    })
  })
}