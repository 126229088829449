const isANumber = (value) => typeof value == 'number';

function removeParamsFromUrl() {
  const url = new URL(window.location);

  url.search = '';
  window.location.href = url.href;
};

function addParamToUrl(paramName, paramValue) {
  const url = new URL(window.location)
  const urlParams = new URLSearchParams(url.search.slice(1));

  urlParams.set(paramName, paramValue);
  if (paramName != "page") urlParams.delete("page");

  url.search = urlParams.toString();
  window.location.href = url.href;
};

function deleteParamFromUrl(paramName) {
  const url = new URL(window.location)
  const urlParams = new URLSearchParams(url.search.slice(1));
  urlParams.delete(paramName);

  url.search = urlParams.toString();
  window.location.href = url.href;
};

// if we receive `null`  or `` we remove param from url,
// otherwise param is added
function toggleUrlParam(param, value) {
  const safetyValue = isANumber(value) ? String(value) : value;

  if (safetyValue) {
    addParamToUrl(param, safetyValue)
  } else {
    deleteParamFromUrl(param);
  };
}

export { removeParamsFromUrl, addParamToUrl, deleteParamFromUrl, toggleUrlParam };
