document.addEventListener("turbolinks:load", function (event) {
  const adminBanner = document.getElementById("admin-banner");
  const adminBannerToggles = document.getElementsByClassName("admin-banner-toggle");

  if (!adminBanner) return;

  if (Cookies.get("show_admin_banner") == 'true') {
    adminBanner.classList.remove('hidden')
  }

  adminBannerToggles.forEach(function (toggle) {
    toggle.addEventListener('click', function (event) {
      event.preventDefault();

      if (Cookies.get("show_admin_banner") == 'true') {
        adminBanner.classList.add('hidden')
        Cookies.set("show_admin_banner", false, {expires: 7})
      } else {
        adminBanner.classList.remove('hidden')
        Cookies.set("show_admin_banner", true, {expires: 7})
      }
    });
  });
});
