import { hideActiveModal } from './utils/modals'

document.addEventListener('turbolinks:load', function (_event) {
  const searchForm = document.querySelector('#personSearch');
  const managerInfo = document.querySelector('#manager_info');
  const setManagerBtn = document.querySelector('#set_manager_btn');

  if (searchForm && managerInfo) {
    searchForm.querySelector('#searchResults').addEventListener('click', function (e) {
      if (e.target.tagName === 'LI') {
        managerInfo.dataset.selectedManagerId = e.target.dataset.personId;
        managerInfo.dataset.selectedManagerFirstName = e.target.dataset.firstName;
        managerInfo.dataset.selectedManagerLastName = e.target.dataset.lastName;
        managerInfo.dataset.selectedManagerFullName = `${e.target.dataset.firstName} ${e.target.dataset.lastName}`;
      }
    });

    setManagerBtn.addEventListener('click', function (_e) {
      hideActiveModal();
      const managerLink = document.querySelector('#manager_link');
      const personManagerId = document.querySelector('#person_manager_id');
      const managerInactiveLabel = document.querySelector('#manager_inactive_label');


      if (managerLink && personManagerId) {
        if(managerInactiveLabel) {
          managerInactiveLabel.remove();
        }
        managerLink.innerHTML = managerInfo.dataset.selectedManagerFullName;
        managerLink.href = `/employees/${managerInfo.dataset.selectedManagerId}/edit`;
        personManagerId.value = managerInfo.dataset.selectedManagerId;
      }
    });
  }
});
