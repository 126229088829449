/**
 * This "header.js" (should be named better) is dealing with the floating mobile menu button.
 */
document.addEventListener("turbolinks:load", (event) => {
  const foundElement = document.querySelector('#floating-mobile-menu-btn');
  if (!foundElement) return;

  foundElement.addEventListener('click', mobileMenuClickListener);
});

const mobileMenuClickListener = () => {
  toggleVisibility('#open-menu-svg');
  toggleVisibility('#close-menu-svg');

  const element = document.getElementById('mobile-menu');
  if (element.classList.contains('pointer-events-none')) {
    // Showing the menu
    element.classList.remove('ease-out', 'duration-200', 'translate-y-4', 'opacity-0');
    element.classList.add('ease-in', 'duration-150', 'opacity-100');
  } else {
    // Hiding the menu
    element.classList.remove('ease-in', 'duration-150', 'opacity-100');
    element.classList.add('ease-out', 'duration-200', 'translate-y-4', 'opacity-0');
  }
  element.classList.toggle('pointer-events-none');
}

const toggleVisibility = (elementCssPath) => {
  const element = document.querySelector(elementCssPath);
  setTimeout(() => { element.classList.toggle('hidden') }, 200);
}
