// Instructions
// How to consume this as a component
// 1. Container must have `data-component="paginator"`
// 2. Each of your 'pages' must be section tags (first section must have .block and the rest .hidden)
// 3. Paginator must have `data-paginator-action="next-btn"` and optionally `data-paginator-action="back-btn"`
//    then must have .block and .hidden respectively
// Optionally. You can dinamically include 'data-next-section' on next-btn if you need non-linear navigation.
//             Known issue: Don't to use backward navigation with dropdowns, because 'next' reference is lost after one use.
//             You'll need to set it again once you press back-btn

const toggleVisibility = (element) => {
  element.classList.toggle('block');
  element.classList.toggle('hidden');
};

const showElement = (element) => {
  element.classList.add('block');
  element.classList.remove('hidden');
};

const hideElement = (element) => {
  element.classList.add('hidden');
  element.classList.remove('block');
};

const updateCurrentSection = (element, allSections, newSectionIndex) => {
  const currentSection = element.querySelector('section[class~="block"]');
  toggleVisibility(currentSection);
  // -1 is the array compensation
  toggleVisibility(allSections[newSectionIndex - 1]);

  return newSectionIndex;
};

const updateDirectionButtons = (allSections, currentSectionNumber, nextBtn, backBtn) => {
  // Arrays index start in 0!
  const currentSectionIndex = currentSectionNumber - 1;
  const isNextSectionAvailable = allSections[currentSectionIndex + 1] !== undefined;
  const isPreviosSectionAvailable = allSections[currentSectionIndex - 1] !== undefined;

  isNextSectionAvailable ? showElement(nextBtn) : hideElement(nextBtn);
  if (!backBtn) return;

  isPreviosSectionAvailable ? showElement(backBtn) : hideElement(backBtn);
};

const initializePaginator = (element) => {
  const backBtn = element.querySelector('[data-paginator-action="back-btn"]');
  const nextBtn = element.querySelector('[data-paginator-action="next-btn"]');
  const allSections = Array.from(element.querySelectorAll('section'));
  let currentSectionNumber = 1;
  const sectionsHistory = [currentSectionNumber]

  nextBtn.addEventListener('click', () => {
    const dataNextSection = nextBtn.getAttribute('data-next-section');
    let nextSectionIndex = parseInt(currentSectionNumber) + 1;
    // Ignore if empty string or zero
    if (dataNextSection && dataNextSection !== '0') {
      nextSectionIndex = dataNextSection;
    };
    currentSectionNumber = updateCurrentSection(element, allSections, nextSectionIndex);
    sectionsHistory.push(currentSectionNumber);
    updateDirectionButtons(allSections, currentSectionNumber, nextBtn, backBtn);
    // Reset nextBtn
    nextBtn.setAttribute('data-next-section', '')
  });

  if (!backBtn) return;

  backBtn.addEventListener('click', () => {
    sectionsHistory.pop();
    currentSectionNumber = updateCurrentSection(element, allSections, sectionsHistory[sectionsHistory.length - 1]);
    updateDirectionButtons(allSections, currentSectionNumber, nextBtn, backBtn);
  });
};

export default function setupPaginators() {
  const componentElements = Array.from(document.querySelectorAll('[data-component="paginator"]'));
  componentElements.forEach((element) => initializePaginator(element));
};
