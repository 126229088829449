import filterParams from "./filter_params";

document.addEventListener("turbolinks:load", () => {
  const datepicker = require("js-datepicker");
  // I hate dragging in another package for something as simple as
  // a unique ID, but Turbolinks was chewing up a ton of my time
  // trying to debug why I couldn't get a uniq ID on page navigations
  // which were partial loads. UUID solves this and prevents the
  // datepicker from breaking on Rails page navigation.
  const { v4: uuidv4 } = require("uuid");
  const uniqDatePickerId = uuidv4();

  const datepickerStartDate = $("#start_date").data("start-date");
  let datepickerSelectedStartDate = undefined;

  if (datepickerStartDate) {
    const date = datepickerStartDate.split("/");
    datepickerSelectedStartDate = new Date(date[2], date[0] - 1, date[1]);
    // Set for end of day so we can allow users
    // to select current day, only if the date hasn't passed.
    datepickerSelectedStartDate.setHours(23, 59, 59, 999);
  }

  // Use the "id" option to share the datepicker instance
  // to use start date and end date together (shows range).
  // https://github.com/qodesmith/datepicker#id

  if ($(".date-select-start").length) {
    $(".date-select-start").each((i, node) => {
      const manuallyTypingAllowed = node.dataset.manuallyTypingAllowed == 'true';
      if (!manuallyTypingAllowed) {
        node.setAttribute("onkeydown", "return false");
      };
    })

    datepicker(".date-select-start", {
      id: uniqDatePickerId,
      position: "bl", // Use so this doesn't overflow on mobile
      dateSelected: datepickerSelectedStartDate,
      onSelect: (instance, date) => {
        // There is no onUnselect, the date object instead returns
        // undefined. This is why we need a check here.
        if (date) {
          const month = date.getMonth() + 1; // month is 0 indexed
          const stringifiedDate = `${month}/${date.getDate()}/${date.getFullYear()}`;
          // TODO: We duplicating the code need to initialize a claendar just becuase the param name is diferent
          // We should have one single initializer and support dynamic param name
          filterParams("start_date", stringifiedDate);
        } else {
          filterParams("start_date", "");
        }
      },
    });
  } else if ($(".date-select-form-start").length) {
    $(".date-select-form-start").each((i, node) => {
      const manuallyTypingAllowed = node.dataset.manuallyTypingAllowed == 'true';
      if (!manuallyTypingAllowed) {
        node.setAttribute("onkeydown", "return false");
      };
    });
    let minDate = new Date();
    // Only trigger datepicker if the start date is after the current date.
    // prevent editing of schedules for dates that already occured.
    // We use this branch of logic for editing
    if (minDate <= datepickerSelectedStartDate) {
      datepicker(".date-select-form-start", {
        id: uniqDatePickerId,
        position: "bl", // Use so this doesn't overflow on mobile
        dateSelected: datepickerSelectedStartDate,
        minDate: minDate,
      });
      // We use this branch of logic for creation
    } else if (datepickerSelectedStartDate === undefined) {
      datepicker(".date-select-form-start", {
        id: uniqDatePickerId,
        position: "br",
        minDate: minDate,
      });
    }
  }

  const datepickerEndDate = $("#end_date").data("end-date");
  let datepickerSelectedEndDate = undefined;

  if (datepickerEndDate) {
    const date = datepickerEndDate.split("/");
    datepickerSelectedEndDate = new Date(date[2], date[0] - 1, date[1]);
    // Set for end of day so we can allow users
    // to select current day, only if the date hasn't passed.
    datepickerSelectedEndDate.setHours(23, 59, 59, 999);
  }

  if ($(".date-select-end").length) {
    $(".date-select-end").each((i, node) => {
      const manuallyTypingAllowed = node.dataset.manuallyTypingAllowed == 'true';
      if (!manuallyTypingAllowed) {
        node.setAttribute("onkeydown", "return false");
      };
    })
    datepicker(".date-select-end", {
      id: uniqDatePickerId,
      position: "br",
      dateSelected: datepickerSelectedEndDate,
      onSelect: (instance, date) => {
        if (date) {
          const month = date.getMonth() + 1; // month is 0 indexed
          const stringifiedDate = `${month}/${date.getDate()}/${date.getFullYear()}`;
          filterParams("end_date", stringifiedDate);
        } else {
          filterParams("end_date", "");
        }
      },
    });
  } else if ($(".date-select-form-end").length) {
    $(".date-select-form-end").each((i, node) => {
      const manuallyTypingAllowed = node.dataset.manuallyTypingAllowed == 'true';
      if (!manuallyTypingAllowed) {
        node.setAttribute("onkeydown", "return false");
      };
    })
    let minDate = new Date();

    // We use this branch of logic for editing
    if (minDate < datepickerSelectedEndDate) {
      datepicker(".date-select-form-end", {
        id: uniqDatePickerId,
        position: "br",
        dateSelected: datepickerSelectedEndDate,
        minDate: minDate,
      });
      // We use this branch of logic for creation
    } else if (datepickerSelectedEndDate === undefined) {
      datepicker(".date-select-form-end", {
        id: uniqDatePickerId,
        position: "br",
        minDate: minDate,
      });
    }
  }

  if($(".single-date-picker").length){
    $(".single-date-picker").each((i, node) => {
      const manuallyTypingAllowed = node.dataset.manuallyTypingAllowed == 'true';
      if (!manuallyTypingAllowed) {
        node.setAttribute("onkeydown", "return false");
      };
    })
    datepicker(".single-date-picker",{
      position: "bl",
      onSelect: (instance, date) => {
        const month = date.getMonth() + 1; // month is 0 indexed
        const stringifiedDate = `${date.getFullYear()}-${month}-${date.getDate()}`;
        const attrName = instance.el.getAttribute('name');
        filterParams(attrName, stringifiedDate);
      }
    });
  }
});
