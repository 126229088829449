import Highcharts from "./highcharts_config";

document.addEventListener("turbolinks:load", () => {
  const chartContainer = document.getElementById('mood-swings-chart');
  if (!chartContainer) return;

  createMoodSwingSeriesType(Highcharts);

  const chartData = JSON.parse(chartContainer.dataset.chartData);

  // Separate data into drops and turnarounds
  const drops = chartData.filter(d => d.from > d.to);
  const turnarounds = chartData.filter(d => d.from < d.to);

  Highcharts.chart(chartContainer.id, options(drops, turnarounds));
});

function createMoodSwingSeriesType(Highcharts) {
  Highcharts.seriesType('moodSwing', 'scatter', {
    // Custom series options here
    marker: {
      enabled: true,
      symbol: 'circle',
      radius: 6
    },
    events: {
      legendItemClick: function() {
        // Toggle visibility of the series and its custom elements
        var series = this;
        series.visible = !series.visible; // Toggle the series visibility

        // Loop through each point to toggle the visibility of custom elements
        series.points.forEach(point => {
          if (point.graphic) { // Check if the point has a graphic (arrow) associated
            point.graphic.attr({
              visibility: series.visible ? 'visible' : 'hidden'
            });
          }
        });

        return false; // Prevent the default action
      }
    }
  }, {
    drawPoints: function() {
      const { chart, yAxis, xAxis } = this;

      this.points.forEach(point => {
        const { x, fromY, toY } = point;

        // Convert to pixel values
        const p_fromY = yAxis.toPixels(fromY);
        const p_toY = yAxis.toPixels(toY);
        const p_x = xAxis.toPixels(x);

        // Remove existings points (arrows) if they exist
        if (point.graphic) {
          point.graphic.destroy();
        }

        // Draw the arrow and store the reference in the point
        point.graphic = drawArrow(chart, p_x, p_fromY, p_toY);
      });
    }
  });
}

const options = (drops, turnarounds) => {
  return {
    chart: {
      type: 'scatter'
    },
    title: { text: 'Mood Swings', align: 'left', margin: 25 },
    xAxis: {
      type: 'datetime',
      gridLineWidth: 1,
      gridLineColor: 'rgb(244, 245, 246)',
      min: Date.now() - (365 * 24 * 60 * 60 * 1000), // 1 year ago
      max: Date.now()
    },
    yAxis: {
      title: null,
      gridLineColor: 'rgb(244, 245, 246)',
      plotLines: [{ value: 0, color: 'rgb(214, 218, 223)', width: 2 }],
      min: -5,
      max: 5,
      tickInterval: 1,
    },
    legend: {
      align: 'left'
    },
    plotOptions: {
      moodSwing: {
        states: {
          hover: {
            enabled: true,
            halo: {
              size: 0 // Disable the halo effect around the point on hover
            }
          }
        }
      }
    },
    tooltip: {
      useHTML: true,
      outside: true,
      formatter: function() {
        const { x, point: { options: { fromY, toY } }, color } = this;
        const dateStr = `<span style="font-size: 10px;">${Highcharts.dateFormat('%A, %b %d %Y', x)}</span>`;
        const fromStr = `<strong>${fromY}</strong>`;
        const toStr = `<strong>${toY}</strong>`;

        return `<span style="color: ${color};">${dateStr}</span><br><span>From: ${fromStr}<br>To: ${toStr}</span>`;
      }
    },
    series: [
      {
        name: 'Drops',
        type: 'moodSwing',
        color: '#FF1F59', // Red
        data: drops.map(item => ({
          x: item.datetime_ms,
          fromY: item.from,
          toY: item.to,
          y: item.to
        }))
      },
      {
        name: 'Turnarounds',
        type: 'moodSwing',
        color: '#00BC7C', // Green
        data: turnarounds.map(item => ({
          x: item.datetime_ms,
          fromY: item.from,
          toY: item.to,
          y: item.to
        }))
      }
    ]
  }
};

const drawArrow = (chart, x, from, to) => {
  const ARROW_HEAD_SIZE = 8;
  const LINE_WIDTH = 2;
  const DOT_RADIUS = 2.5;
  const isArrowPointingDown = from < to;
  const arrowColor = isArrowPointingDown ? '#FF1F59' : '#00BC7C'; // Red or Green
  const lineAdjustment = isArrowPointingDown ? -2 : 2;

  // Create a group for the arrow
  const arrowGroup = chart.renderer.g().add();

  // Draw line and add it to the group
  chart.renderer.path(['M', x, from, 'L', x, to + lineAdjustment])
    .attr({
      'stroke-width': LINE_WIDTH,
      stroke: arrowColor
    })
    .add(arrowGroup);

  // Draw a dot at the start of the arrow and add it to the group
  chart.renderer.circle(x, from, DOT_RADIUS)
    .attr({
      fill: arrowColor
    })
    .add(arrowGroup);

  // Determine the points for the arrow head based on its direction and add it to the group
  const arrowHeadPath = isArrowPointingDown
    ? ['M', x, to, 'L', x - ARROW_HEAD_SIZE / 2, to - ARROW_HEAD_SIZE, 'L', x + ARROW_HEAD_SIZE / 2, to - ARROW_HEAD_SIZE, 'Z']
    : ['M', x, to, 'L', x - ARROW_HEAD_SIZE / 2, to + ARROW_HEAD_SIZE, 'L', x + ARROW_HEAD_SIZE / 2, to + ARROW_HEAD_SIZE, 'Z'];

  // Draw arrow head and add it to the group
  chart.renderer.path(arrowHeadPath)
    .attr({
      fill: arrowColor
    })
    .add(arrowGroup);

  // Bring the arrow group to the front
  arrowGroup.toFront();

  // Return the group so it can be managed (shown/hidden) based on series visibility
  return arrowGroup;
};
