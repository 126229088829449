import { Controller } from "@hotwired/stimulus"
import Highcharts from 'highcharts';

// Connects to data-controller="highchart"
export default class extends Controller {
  static values = {
    options: Object
  }

  connect() {
    this.initializeChart()
  }

  initializeChart() {
    Highcharts.chart(this.element, this.optionsValue);
  }
}
