import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reveal"
export default class extends Controller {
  static targets = ['item']
  static values = {
    hiddenClass: { type: String, default: 'opacity-0' },
    displayClass: { type: String, default: 'opacity-100' }
  }

  connect() {}

  toggle() {
    this.itemTargets.forEach(item => {
      if (item.classList.contains(this.hiddenClassValue)) {
        this.showItem(item);
      } else {
        this.hideItem(item);
      }
    })
  }

  show() {
    this.itemTargets.forEach(item => {
      this.showItem(item);
    })
  }

  hide() {
    this.itemTargets.forEach(item => {
      this.hideItem(item);
    })
  }

  showItem(item) {
    item.classList.remove(this.hiddenClassValue, 'invisible');
    item.classList.add(this.displayClassValue);
  }

  hideItem(item) {
    item.classList.remove(this.displayClassValue);
    item.classList.add(this.hiddenClassValue, 'invisible');
  }
}
