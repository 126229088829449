import { Controller } from "@hotwired/stimulus"

/**
 * CheckboxSelectAll Controller
 * Provides the ability to select/deselect all checkboxes in a group.
 * 
 * How to use:
 * 1. Add data-controller="checkbox-select-all" to the parent element of the checkboxes.
 * 2. Add data-checkbox-select-all-target="checkboxAll" to the checkbox that will select/deselect all.
 * 3. Add data-checkbox-select-all-target="checkbox" to the checkboxes that will be selected/deselected.
 * 
 * Example:
 * <div data-controller="checkbox-select-all">
 *   <input type="checkbox" data-checkbox-select-all-target="checkboxAll">
 *   <input type="checkbox" data-checkbox-select-all-target="checkbox">
 *   <input type="checkbox" data-checkbox-select-all-target="checkbox">
 *   <input type="checkbox" data-checkbox-select-all-target="checkbox">
 * </div>
 */

export default class extends Controller {
  static targets = [ 'checkboxAll', 'checkbox' ]

  checkboxAllTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.toggle)
    this.refresh()
  }

  checkboxTargetConnected(checkbox) {
    checkbox.addEventListener('change', this.refresh)
    this.refresh()
  }

  checkboxAllTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.toggle)
    this.refresh()
  }

  checkboxTargetDisconnected(checkbox) {
    checkbox.removeEventListener('change', this.refresh)
    this.refresh()
  }

  toggle = (event) => {
    event.preventDefault()

    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = event.target.checked
      this.triggerInputEvent(checkbox)
    })
  }

  refresh = () => {
    const checkboxesCount = this.checkboxTargets.length
    const checkboxesCheckedCount = this.checked.length

    this.checkboxAllTarget.checked = checkboxesCheckedCount > 0
    this.checkboxAllTarget.indeterminate = checkboxesCheckedCount > 0 && checkboxesCheckedCount < checkboxesCount
  }

  triggerInputEvent(checkbox) {
    const event = new Event('input', {
      bubbles: true,
      cancelable: true,
    })

    checkbox.dispatchEvent(event)
  }

  get checked() {
    return this.checkboxTargets.filter((checkbox) => checkbox.checked)
  }
}
