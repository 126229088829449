// Used for remove DOM elements when clicking something
// What you need:
// 1. An element A with data-component="element-remover"
// 2. Element A with data-target attribute, which values is the id of the element to be hidden

const setupElementRemoverComponents = () => {
  const components = Array.from(document.querySelectorAll('[data-component="element-remover"]'));
  components.forEach((element) => {
    element.addEventListener('click', () => {
      const targetId = element.getAttribute('data-target');
      const elementToBeRemoved = document.getElementById(targetId)
      if(!elementToBeRemoved) return;

      elementToBeRemoved.remove();
    });
  });
};

export default setupElementRemoverComponents
