import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="range-slider"
export default class extends Controller {
  static targets = [ 'slider' ]
  static values = { start: String, middle: String, end: String }
  static outlets = ['reveal']

  connect() {
    this.updateThumbColor();
    this.revealOnInputChange();
  }

  updateThumbColor() {
    this.sliderTarget.addEventListener('input', (event) => {
      let value = (event.target.value - event.target.min) / (event.target.max - event.target.min);

      // Parse the RGB values from the start, middle, and end color strings
      let startColor = this.startValue.split(',').map(Number);
      let middleColor = this.middleValue.split(',').map(Number);
      let endColor = this.endValue.split(',').map(Number);

      // Interpolate between the colors of the gradient
      let red, green, blue;
      if (value < 0.5) {
        // Interpolate between start and middle
        red = Math.round((middleColor[0] - startColor[0]) * value * 2 + startColor[0]);
        green = Math.round((middleColor[1] - startColor[1]) * value * 2 + startColor[1]);
        blue = Math.round((middleColor[2] - startColor[2]) * value * 2 + startColor[2]);
      } else {
        // Interpolate between middle and end
        red = Math.round((endColor[0] - middleColor[0]) * (value - 0.5) * 2 + middleColor[0]);
        green = Math.round((endColor[1] - middleColor[1]) * (value - 0.5) * 2 + middleColor[1]);
        blue = Math.round((endColor[2] - middleColor[2]) * (value - 0.5) * 2 + middleColor[2]);
      }

      let color = `rgb(${red}, ${green}, ${blue})`;

      event.target.style.setProperty('--thumb-color', color);
    });
  }

  revealOnInputChange() {
    const toggleReveal = () => {
      if (this.hasRevealOutlet) {
        this.revealOutlets.forEach(reveal => {
          reveal.toggle();
        });
      }
      this.sliderTarget.removeEventListener('input', toggleReveal);
    };
  
    this.sliderTarget.addEventListener('input', toggleReveal);
  }
}
