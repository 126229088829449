const setupStripeCardTokenHandler = () => {
  const stripeDOMElement = document.getElementById('stripe_publishable_key');
  if (!stripeDOMElement) return;

  const stripePublishableKey = stripeDOMElement.value;
  const stripe = Stripe(stripePublishableKey);
  const style = {
    base: {
      fontSize: '16px',
      color: '#32325d',
    },
  };

  const card = stripe.elements().create('card', { style });
  card.mount('#card-element');

  // Create a token or display an error when the form is submitted.
  const form = document.getElementById('new_employee');
  form.addEventListener('submit', async (event) => {
    event.preventDefault();

    const { token, error } = await stripe.createToken(card);

    if (error) {
      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
    } else {
      stripeTokenHandler(token);
    }
  });
}

const stripeTokenHandler = (token) => {
  const form = document.getElementById('new_employee');

  const tokenInput = document.createElement('input');
  tokenInput.setAttribute('type', 'hidden');
  tokenInput.setAttribute('name', 'stripe_token');
  tokenInput.setAttribute('value', token.id);
  form.appendChild(tokenInput);

  form.submit();
}

export { setupStripeCardTokenHandler, stripeTokenHandler }

document.addEventListener('turbolinks:load', () => {
  setupStripeCardTokenHandler();
});
