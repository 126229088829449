document.addEventListener("turbolinks:load", () => {
  const rangeField = document.querySelector('.demo-facet');
  const demoFacetResult = document.querySelector('#demo-facet-result');
  if (!rangeField || !demoFacetResult) return;

  const paragraphs = {
    negative: document.querySelector('#negative-paragraph'),
    positive: document.querySelector('#positive-paragraph'),
    neutral: document.querySelector('#neutral-paragraph')
  };

  rangeField.addEventListener('input', () => {
    let score = parseInt(rangeField.value);
    let paragraphToShow;

    if (score <= -2) {
      paragraphToShow = 'negative';
    } else if (score >= 2) {
      paragraphToShow = 'positive';
    } else {
      paragraphToShow = 'neutral';
    }

    // This will allow the text typing animation to be triggered
    Object.keys(paragraphs).forEach(key => {
      if (key === paragraphToShow) {
        paragraphs[key].dataset.textTypingAnimationTarget = 'typing';
      } else {
        paragraphs[key].removeAttribute('data-text-typing-animation-target'); 
      }
    });
  });
});
