
document.addEventListener('turbolinks:load', () => {
  const geoFencingEnabled = document.querySelector('#physical_location_geofencing_enabled');
  if (!geoFencingEnabled) return;

  geoFencingEnabled.addEventListener('change', (e) => {
    const geofenceMap = document.querySelector('#geofence_map');
    if (e.target.value === 'true') {
      geofenceMap.classList.remove('hidden');
    } else {
      geofenceMap.classList.add('hidden');
    }
  })
});

window.initMap = () => {
  const radiusElement = document.querySelector('#physical_location_radius_in_meters');
  const latitudeElement = document.querySelector('#physical_location_latitude');
  const longitudeElement = document.querySelector('#physical_location_longitude');
  const geofenceDetails = document.querySelector('#geofence_details');

  // Default is the geographic center of the US.
  const loadedLat = parseFloat(latitudeElement.value) || parseFloat('39.8097343');
  const loadedLng = parseFloat(longitudeElement.value) || parseFloat('-98.5556199');
  let loadedRadius = parseFloat(radiusElement.value) || parseFloat('10.0');

  // If we can't parse their latitude and longitude (usually due to Geocoder), we default
  // to the center of the US and make the circle extremely large so they can move it where
  // they need to and adjust it manually.
  if (loadedLat === 39.8097343 && loadedLng === -98.5556199) {
    loadedRadius = 200000
  }

  const mapCenter = {
    lat: loadedLat,
    lng: loadedLng
  };
  let zoomLevel = 19;

  if (loadedRadius >= 100000) {
    zoomLevel = 5
  } else if (loadedRadius >= 8000) {
    zoomLevel = 8
  } else if (loadedRadius >= 4000) {
    zoomLevel = 10
  } else if (loadedRadius >= 400) {
    zoomLevel = 13
  } else if (loadedRadius >= 200) {
    zoomLevel = 15
  } else if (loadedRadius >= 100) {
    zoomLevel = 16
  } else if (loadedRadius >= 30) {
    zoomLevel = 18
  }

  const mapOptions = {
    center: mapCenter,
    zoom: zoomLevel
  };

  const newMap = new google.maps.Map(document.getElementById('geofence_map'), mapOptions);

  const radiusChanged = () => {
    radiusElement.value = Math.round((circle.getRadius() + Number.EPSILON) * 100) / 100

    let details = "<span class='font-medium'>Geofence Radius: <span class='font-semibold text-gray-900'>" + radiusElement.value + "m</span> </span>";
    if(radiusElement.value < 200) {
      details += "<span class='rounded px-2 py-1 mt-1 bg-rose-50 border border-rose-300 text-rose-700 inline-block'>Warning: a radius of less than 200m will fail to trigger reliably for many buildings. However, down to 100m may work in wide-open areas.</span>";
    }
    geofenceDetails.innerHTML = details;
  }

  const centerChanged = () => {
    latitudeElement.value = circle.getCenter().lat();
    longitudeElement.value = circle.getCenter().lng();
  }

  const circleOptions = {
    strokeColor: '#5145cd',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#5145cd',
    fillOpacity: 0.35,
    center: mapCenter,
    radius: loadedRadius, // This is in meters https://developers.google.com/android/reference/com/google/android/gms/maps/model/Circle
    map: newMap,
    editable: true,
    draggable: true,
    radius_changed: radiusChanged,
    center_changed: centerChanged
  };

  const circle = new google.maps.Circle(circleOptions);
  circle.setMap(newMap);
  radiusChanged();
}
