// Usage:
// 1 - You need to have two nested html elements with 'menu' and 'menu-content' classes.(see _menu.scss)
// 2 - Add `component: "menu"` as an attribute for main element

const removeMenus = () => {
  const tooltips = $('[component="menu"]');
  tooltips.each((i, node) => {
    $(node).off('click');
  });
};

const setupMenus = (components = null) => {
  const tooltips = components ?? document.querySelectorAll('[component="menu"]');
  tooltips.forEach(node => {
    node.querySelectorAll("[data-menu-prevent-closing]").forEach(element => {
      element.addEventListener('click', (event) => event.stopPropagation());
    });
    // If you want to remove JQuery you need to handle the listenner removal
    // You can not rely on html attributes as a flag, because when navigating through history
    // Turbolinks is not fetching HTML again but attaching the locally stored, injecting the attribute
    // and skipping the listenner setup
    $(node).on('click', (event) => {
      closeOthers(event);
      node.classList.toggle('active');
    });
    // Hide menu after clicking element
    node.querySelectorAll("[data-disable-menu]").forEach(disablerBtn => {
      disablerBtn.addEventListener('click', () => {
        node.classList.remove('active');
      });
    });
  });
  autoCloseMenus();
};

// Removes active class from all menus but the clicked one.
const closeOthers = (event) => {
  let currentActive = event.currentTarget;
  $('[component="menu"]').not(currentActive).map((i, element) => {
    $(element).removeClass('active');
  })
}

const closeMenus = () => {
  const tooltips = $('[component="menu"]');
  tooltips.each((i, node) => {
    $(node).removeClass('active');
  });
};

// When clicking outside of menu, close all menus.
const autoCloseMenus = () => {
  $(document).on('click', function(event) {
    if (!$(event.target).closest('[component="menu"]').length) {
      closeMenus();
    }
  });
};

export { setupMenus, closeMenus, removeMenus };
