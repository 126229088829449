import Highcharts from "./highcharts_config";

document.addEventListener("turbolinks:load", function (event) {
  // Charts
  const colors = [
    "#55B686",
    "#FCC515",
    "#F97316",
    "#FC4647",
    "#EB3334",
    "#DC2626",
  ]

  const nodes = $(".vaccine-coverage-chart").each((i, node) => {
    const data = $(node).data("chart")

    Highcharts.chart(node.id, {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column"
        },
      title: {
        text: data.name,
        align: "left",
        margin: 20,
        style: {
          fontWeight: "bold"
        }
      },
      xAxis: {
        categories: data.categories,
        title: {
          text: "Day",
        },
        style: {
          marginTop: 20
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: "Personnel",
        },
        // plotLines: [{
        //   dashStyle: "Dot",
        //   value: data.one_response_per_day_line,
        //   color: "#666",
        //   label: {
        //     text: "1 response/person/day",
        //   },
        // }],
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "bold",
            color: ( // theme
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || "gray"
          }
        }
      },
      legend: {
        // enabled: false,
        align: "right",
        x: 0,
        verticalAlign: "top",
        y: 0,
        floating: false,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#CCC",
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        backgroundColor: "#000",
        borderColor: "#000",
        borderRadius: 15,
        style: {
          color: "#fff",
        },
        headerFormat: "<b>{point.x}</b><br/><br/>",
        pointFormat: "{series.name}: {point.y:,.0f}<br/>Total: {point.stackTotal}",
      },
      plotOptions: {
        column: {
          borderWidth: 1,
          stacking: "normal",
          dataLabels: {
            enabled: false
          }
        }
      },
      series: data.series.map((series,i) => {
        series.color = colors[i]
        series.borderColor = colors[i]
        return series
      })
    })
  })
})
