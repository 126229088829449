// Usage: <div data-tippy-content="Tooltip content">Hover me</div>
// For disabled elements (like buttons) wrap the element in a span and add the data-tippy-content attribute to the span
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';
import 'tippy.js/themes/light.css';

document.addEventListener('turbolinks:load', () => {
  const tooltipElements = document.querySelectorAll('[data-tippy-content]');

  tooltipElements.forEach((element) => {
    const delay = element.getAttribute('data-tippy-delay') || 500;
    const theme = element.getAttribute('data-tippy-theme') || 'black';

    tippy(element, {
      theme: theme,
      allowHTML: true,
      touch: true,
      placement: 'bottom',
      delay: delay
    });
  });
});
