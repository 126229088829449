const CASE_FIELDS_CATEGORIES = ['case_progress', 'subject_status', 'diagnosis']
const ENTER_KEY_VALUE = 13;

document.addEventListener('turbolinks:load', () => {
  const caseFeildForm = document.getElementById('case_type_fields_form');
  if(!caseFeildForm) return;

  setupFormSubmitWhenInputChange(caseFeildForm, 'case_progress_fields_enabled_input');
  setupFormSubmitWhenInputChange(caseFeildForm, 'subject_status_fields_enabled_input');
  setupFormSubmitWhenInputChange(caseFeildForm, 'diagnosis_fields_enabled_input');
  CASE_FIELDS_CATEGORIES.forEach(((category) => setupFieldCategoryInputs(category)));
});

const setupFormSubmitWhenInputChange = (form, inputId) => {
  const input = document.getElementById(inputId);
  input.addEventListener('change', () => form.submit());
};

const setupFieldCategoryInputs = (category) => {
  const fieldsModal = document.getElementById(`${category}_modal`)
  const fieldNameInputs = Array.from(fieldsModal.querySelectorAll('input[name*="[name]"]'));
  fieldNameInputs.forEach((input) => {
    const alreadySettedUp = input.getAttribute('listennerSettedUp');
    if(alreadySettedUp) return;

    input.setAttribute('listennerSettedUp', true);
    input.addEventListener('keypress', (e) => handleInputEnterPressed(category, e));
  });
}

const handleInputEnterPressed = (category, e) => {
  const key = e.keyCode || e.which;
  if (key != ENTER_KEY_VALUE) return;

  e.preventDefault();
  const newFieldLink = document.getElementById(`add_${category}_link`)
  newFieldLink.click();
};
