document.addEventListener("turbolinks:load", function(event) {
  const participationRates = document.getElementById('participation-rates')

  if (participationRates) {
    const rawData = participationRates.getAttribute('data-participation_rate')
    const parsedData = JSON.parse(rawData).data

    function setBarSizes() {
      const containerWidth = participationRates.offsetWidth

      for (i=0; i<parsedData.length; i++) {
        const day = document.getElementById(`day${i}`)
        // Clear out previous data so we can deal with resizing
        day.innerHTML = '';

        const barNode = document.createElement('DIV')
        const offsetNode = document.createElement('DIV')
        const dayNode = document.createElement('DIV')
        const barHeight = parsedData[i].participation_percentage
        const offsetHeight = 100 - barHeight;
        const barColor = parsedData[i].color
        const barPadding = 18
        const totalPaddingBetweenBars = 2 * barPadding * 7 // 7 days.
        const barWidth = `${(containerWidth - totalPaddingBetweenBars) / 7 - 5}px`

        barNode.style.height = `${barHeight}px`
        barNode.style.width = barWidth
        barNode.style.background = barColor
        barNode.style.display = 'block'

        offsetNode.style.height = `${offsetHeight}px`
        offsetNode.style.width = barWidth
        offsetNode.style.display = 'block'

        dayNode.style.display = 'block'
        dayNode.style.width = barWidth
        dayNode.innerHTML = parsedData[i].day

        day.style.paddingLeft = `${barPadding}px`
        day.style.paddingRight = `${barPadding}px`

        day.append(offsetNode)
        day.append(barNode)
        day.append(dayNode)
      }

      $('#legend').height('125px')
    }

    setBarSizes()
    window.onresize = function() { setBarSizes() }
  }
})