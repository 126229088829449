import Ably from 'ably';

document.addEventListener('turbolinks:load', () => {
  const dataImportDataContainer = document.querySelector('#data_import_live_update_container');
  if(!dataImportDataContainer) return;

  const channelName = dataImportDataContainer.dataset.realtimeChannel;
  const client = new Ably.Realtime({ authUrl: '/auth/realtime/token' });
  const channel = client.channels.get(channelName);
  channel.subscribe('refresh', (message) => refreshPage(message.data));
});

const refreshPage = (data) => {
  if(data.status == 'completed'){
    setTimeout(() => { location.reload(); }, 1500);
  }
};
