import mapboxgl from '!mapbox-gl';
import {
  renderData,
  buildFeatureTitle,
  coordsInSimilarPosition,
} from './disruptions/map_utils';
import { subscribeToNewMapEvent } from './disruptions/disruptions_subscriber';
import { setupCopyToClipboard } from './components/clipboard_copy';
import { setupMenus } from './components/menu';
import { setupModal } from './components/modals';
import { setupAlertMenu } from './components/alert_form';

mapboxgl.accessToken = 'pk.eyJ1IjoibWlrZXdpbGxlIiwiYSI6ImNrejVsdTIzajAwOXIyb3BxcXRiNm5taTkifQ.wtSlZmChzUf5KRAuZkAYuw';

let map;

document.addEventListener('turbolinks:load', () => {
  const disruptionMap = document.getElementById('disruption_map');
  if (disruptionMap == null) return;

  resizeMapHeight();
  window.addEventListener('resize', resizeMapHeight);
  const disruptionCategory = disruptionMap.dataset['disruption-category'];
  let params = ''
  if (disruptionCategory) params = `?disruption_category=${disruptionCategory}`;

  map = new mapboxgl.Map({
    container: 'disruption_map', // container ID
    // style: 'mapbox://styles/mapbox/streets-v11', // style URL
    style: 'mapbox://styles/mikewille/ckze8ezja000314mra09xejob',
    center: [-96.4247, 40], // starting position [lng, lat]
    zoom: 4 // starting zoom
  });
  map.addControl(new mapboxgl.NavigationControl())
  map.on('load', () => {
    setupSystemEvents(map, disruptionCategory);
    setupAffectedEvents(map, params);
    setupLocations(map);
    setupMapLayerToggle(map);
    setupDropdownItemsListeners(map);
    setupLocationSelectListener(map);
  });
  const closeSidePanel = document.getElementById('close-side-panel')
  const sidePanelContainer = document.getElementById('side-panel-container')
  closeSidePanel.addEventListener('click', () => {
    sidePanelContainer.classList.remove('active');
    animateAllCloseIcon();
    removeColoringFromListItems();
  });
  map.on('idle', () => {
    const tooltipElement = document.querySelector('.mapboxgl-popup-content');
    if (!tooltipElement) {
      sidePanelContainer.classList.remove('active');
    }
  })
  subscribeToNewMapEvent(processMapEvent, map);
});


// We're expecting message.data to be json we need for rendering a mapbox source
// see https://docs.mapbox.com/mapbox-gl-js/api/sources/#geojsonsource
const processMapEvent = async (map, message) => {
  const data = message.data;
  if (!message.data) return;

  const eventId = data['properties']['id'];
  renderData({ map: map, data: [data], index: eventId, animated: true });
  setupTooltips(map, `system-events-boundary-${eventId}`);
}

const setupSystemEvents = async (map, disruptionCategory) => {
  let systemEventsPages = 0;
  let hasNextPage = false;
  do {
    systemEventsPages += 1;
    const params = `?page=${systemEventsPages}&disruption_category=${disruptionCategory || ''}`;
    let response = await fetch(`/map_sources/system_events${params}`)
      .then((response) => response.json());
    renderData({ map: map, data: response.data, index: systemEventsPages });
    hasNextPage = response.has_next_page;
    setupTooltips(map, `system-events-boundary-${systemEventsPages}`);
  } while (hasNextPage);
}

const setupAffectedEvents = (map, params) => {
  const sourceId = 'affected-events';
  map.addSource(sourceId, {
    type: 'geojson',
    data: `/map_sources/affected_events${params}`

  });
  map.addLayer({
    'id': `${sourceId}-boundary`,
    'type': 'fill',
    'source': sourceId,
    'paint': {
      'fill-color': '#e11d48',
      'fill-opacity': 0.25,
    },
  });
  map.addLayer({
    'id': `${sourceId}-border`,
    'type': 'line',
    'source': sourceId,
    'paint': {
      'line-color': '#e11d48',
      'line-width': 1,
    },
  });
  setupTooltips(map, `${sourceId}-boundary`);
}

const resizeMapHeight = () => {
  const mapContainer = document.getElementById('disruption_map');
  const mapContainerPositicionInX = mapContainer.getBoundingClientRect().top;
  const mainContainerPadding = 18; // Warning, magic number! What does it represent??
  mapContainer.style.height = `calc(100dvh - ${mapContainerPositicionInX}px - ${mainContainerPadding}px)`;
}

const setupLocations = (map) => {
  map.addSource('locations', {
    type: 'geojson',
    data: '/map_sources/locations'
  });
  map.addLayer({
    'id': 'location-markers',
    'type': 'symbol',
    'source': 'locations',
    'paint': {
      'icon-color': '#1d4ed8',
      'icon-halo-blur': 2,
      'text-color': '#1f2937'
    },
    'layout': {
      // 'icon-image': '{marker-symbol}', //but monument-15 works
      'icon-image': ['coalesce', ['image', 'pin-{marker-symbol}'], ['image', 'marker']],
      'text-field': '{title}',
      'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
      'text-size': 14,
      'text-anchor': 'bottom',
      'text-offset': [0, -1],
    }
  });
}

const setupMapLayerToggle = (map) => {
  const toggleButtons = document.querySelectorAll('[data-mapbox-layer-toggle]');
  if (!toggleButtons) return;

  toggleButtons.forEach(button => {
    const isMobile = button.dataset.mobile;
    button.addEventListener('click', () => {
      const affectedEventsLayers = Object.keys(map.style._layers).filter(layer => layer.match('affected-events'));
      const systemEventsLayers = Object.keys(map.style._layers).filter(layer => layer.match('system-events'));
      const oldLayerPrefix = button.getAttribute('data-active-layer-prefix');
      const currentLayerPrefix = oldLayerPrefix == 'all-events' ? 'affected-events' : 'all-events';
      button.setAttribute('data-active-layer-prefix', currentLayerPrefix);

      if (currentLayerPrefix == 'all-events') {
        button.setAttribute('title', 'Show events affecting you');
        if (isMobile) {
          button.querySelector("svg").classList.add("opacity-10")
        } else {
          button.classList.add('text-gray-500', 'bg-white', 'border-gray-300');
          button.classList.remove('text-white', 'bg-teal-600');
        }
      }
      else {
        button.setAttribute('title', 'Show all events');

        if (isMobile) {
          button.querySelector("svg").classList.remove("opacity-10")
        } else {
          button.classList.remove('text-gray-500', 'bg-white');
          button.classList.add('text-white', 'bg-teal-600');
        }
      }
      affectedEventsLayers.forEach((layer) => {
        map.setLayoutProperty(layer, 'visibility', 'visible');
      });
      systemEventsLayers.forEach((layer) => {
        const visibility = currentLayerPrefix == 'all-events' ? 'visible' : 'none';
        map.setLayoutProperty(layer, 'visibility', visibility);
      });
    });
  });
}

// Everytime we insert a new layer on the map; system events paginated, new live update even
// we need to remove current listeners and set them up again. This is because of the possibility
// of layer being overlapped
const setupTooltips = (map, newLayer) => {
  const boundaryLayers = filterMapLayers(map, 'events-boundary');
  const previousLayers = boundaryLayers.filter((layer) => layer != newLayer);
  map.off('click', previousLayers, layersEventListeners);
  // Live events not only add new layers but also could replace an existing one
  // That's why we need to remove listeners from boundaryLayers as well
  map.off('click', boundaryLayers, layersEventListeners);
  map.on('click', boundaryLayers, layersEventListeners);
}

const filterMapLayers = (map, regex) => (
  Object.keys(map.style._layers).filter(layer => layer.match(regex))
)

const layersEventListeners = (e) => {
  insertMapPopUp(e.features, e.lngLat.lat, e.lngLat.lng);
  const firstItem = document.querySelector('.tooltip-list-item');
  triggerReactionOnClick(firstItem);
}

const insertMapPopUp = (features, lat, lng) => {
  const titleList = features.map((feature, index, originalArray) => {
    // This helps removing duplicates (kinda rare happening)
    if (originalArray.findIndex((element) => element.properties.id === feature.properties.id) === index) {
      return buildFeatureTitle(feature);
    };
  }).join('');
  const featureList = `<ul style='width: 250px;'>${titleList}</ul>`;
  const popup = new mapboxgl.Popup({ maxWidth: '300px', closeButton: false })
    .setLngLat([lng, lat])
    .setHTML(featureList)

  let latDif = 0;
  // On mobile, we need to offset the map to display the popup on the top part of the map.
  if (window.innerWidth < 768) {
    // Calculate the map offset by comparing the map center and the map north
    const mapCenterLat = map.getCenter().lat;
    const mapNorth = map.getBounds().getNorth();
    latDif = mapNorth - mapCenterLat - 0.5;
  }

  popup.on('open', () => {
    setupListItemsListener();
    map.flyTo({
      center: [lng, lat - latDif],
      speed: 0.5
    });
  })
  popup.addTo(map);
  const sidePanelContainer = document.getElementById('side-panel-container');
  popup.on('close', () => sidePanelContainer.dataset.disruptiveEventId = '');
}

const setupListItemsListener = () => {
  const listItems = document.getElementsByClassName('tooltip-list-item');
  listItems.forEach((listItem) => {
    listItem.addEventListener('click', () => {
      triggerReactionOnClick(listItem);
    })
  })
}

const triggerReactionOnClick = (element) => {
  animateAllCloseIcon();
  removeColoringFromListItems();
  const sidePanelContainer = document.getElementById('side-panel-container');
  const clickedDisruptiveEventId = element.dataset.disruptiveEventId;
  const currentDisruptiveEventId = sidePanelContainer.dataset.disruptiveEventId;

  if (sidePanelContainer.classList.contains('active') && clickedDisruptiveEventId === currentDisruptiveEventId) {
    element.querySelector('.cross-plus-icon').classList.remove('active');
    sidePanelContainer.classList.remove('active');
    element.classList.remove('bg-gray-200');
    sidePanelContainer.dataset.disruptiveEventId = '';
    if (!element.classList.contains('affected-event')) {
      element.classList.add('border-l-white');
    }
  } else {
    setElementActive(element);
    element.querySelector('.cross-plus-icon').classList.add('active');
    openAndLoadSidePanel(sidePanelContainer, element.dataset.disruptiveEventId);
  }
}

const removeColoringFromListItems = () => {
  document.querySelectorAll('.tooltip-list-item').forEach((element) => {
    element.classList.remove('active');
    element.classList.replace('bg-gray-200', 'bg-white');
    if (element.classList.contains('affected-event')) return;

    element.classList.replace('border-l-gray-200', 'border-l-white');
  })
}

const setElementActive = (element) => {
  element.classList.add('active');
  element.classList.replace('bg-white', 'bg-gray-200');
  if (element.classList.contains('affected-event')) return;

  element.classList.replace('border-l-white', 'border-l-gray-200');
}

const animateAllCloseIcon = () => {
  document.querySelectorAll('.cross-plus-icon').forEach((icon) => {
    icon.classList.remove('active');
  })
}

const getDisruptionDetails = async (disruptiveEventId) => {
  const url = `/map_sources/disruption_details?disruptive_event_id=${disruptiveEventId}`;
  const csrfToken = document.getElementsByName('csrf-token')[0].content
  const sidePanelContent = document.getElementById('side-panel-content');
  const options = {
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  };
  const response = await fetch(url, options);
  const responseText = await response.text();
  sidePanelContent.innerHTML = responseText;

  // This setups all the components that are loaded with the side panel.
  const copyToClipboardComponents = sidePanelContent.querySelectorAll('[data-component="copy-to-clipboard"]');
  const menuComponents = sidePanelContent.querySelectorAll('[component="menu"]');
  const modalComponents = sidePanelContent.querySelectorAll(".modal-btn");
  const alertModalContainer = document.getElementById('alert-modal-container');
  const alertModalReceiver = document.getElementById('alert-modal-receiver');
  // I'm moving the content of the modal outside the map, so it's both
  // fullscreen and functional.
  if (alertModalContainer) {
    alertModalReceiver.innerHTML = (alertModalContainer.innerHTML)
    alertModalContainer.innerHTML = '';
  }

  Array.from(copyToClipboardComponents).forEach((component) => setupCopyToClipboard(component));
  setupMenus(menuComponents);
  Array.from(modalComponents).forEach((component) => setupModal(component));

  setupAlertMenu()
  setupArrowsListeners();
}

const setupArrowsListeners = () => {
  const currentElement = document.querySelector('.tooltip-list-item.active');
  const elementsAmount = document.querySelectorAll('.tooltip-list-item').length;
  const currentElementNumber = Array.from(document.querySelectorAll('.tooltip-list-item')).indexOf(currentElement) + 1;
  const previousElement = currentElement.previousElementSibling;
  const nextElement = currentElement.nextElementSibling;
  const arrowLeft = document.getElementById('arrow-left');
  const arrowRight = document.getElementById('arrow-right');

  document.getElementById('disruption-number-container').innerHTML = `${currentElementNumber} of ${elementsAmount}`
  if (!previousElement) {
    arrowLeft.classList.replace('cursor-pointer', 'cursor-not-allowed')
    arrowLeft.classList.add('text-gray-300');
  } else {
    arrowLeft.classList.replace('cursor-not-allowed', 'cursor-pointer')
    arrowLeft.classList.remove('text-gray-300');
    arrowLeft.addEventListener('click', () => {
      triggerReactionOnClick(previousElement);
    });
  }
  if (!nextElement) {
    arrowRight.classList.replace('cursor-pointer', 'cursor-not-allowed')
    arrowRight.classList.add('text-gray-300');
  } else {
    arrowRight.classList.replace('cursor-not-allowed', 'cursor-pointer')
    arrowRight.classList.remove('text-gray-300');
    arrowRight.addEventListener('click', () => {
      triggerReactionOnClick(nextElement);
    });
  }
}

const openAndLoadSidePanel = (sidePanelContainer, disruptiveEventId) => {
  sidePanelContainer.classList.add('active');
  sidePanelContainer.dataset.disruptiveEventId = disruptiveEventId;
  getDisruptionDetails(disruptiveEventId);
}

const setupDropdownItemsListeners = (map) => {
  const affectingEventsListItems = document.querySelectorAll("[data-event-selector='event-item']");
  affectingEventsListItems.forEach((listItem) => {
    const { disruptiveEventLatitude, disruptiveEventLongitude, disruptiveEventId } = listItem.dataset;
    const coords = new mapboxgl.LngLat(disruptiveEventLongitude, disruptiveEventLatitude);
    listItem.addEventListener('click', () => {
      closeMapOverlays(map);
      // If center is already in the event, Mapbox won't trigger the zoomed event
      if (coordsInSimilarPosition(coords, map.getCenter())) {
        clickDesiredTooltip(map, coords, disruptiveEventId)
      } else {
        map.flyTo({
          center: [disruptiveEventLongitude, disruptiveEventLatitude],
          speed: 0.8,
          zoom: 6
        });
        map.once('zoomend', () => clickDesiredTooltip(map, coords, disruptiveEventId));
      }
    });
  });
}

const setupLocationSelectListener = (map) => {
  const locationsSelectors = document.querySelectorAll('[data-location-selector]');
  if (!locationsSelectors) return;

  locationsSelectors.forEach(selector => {
    selector.addEventListener('change', () => {
      closeMapOverlays(map);
      const selectedOption = selector.options[selector.selectedIndex];
      const latitude = selectedOption.dataset.locationLatitude;
      const longitude = selectedOption.dataset.locationLongitude;
      const zoom = selector.value ? 8 : 4;
      const coords = new mapboxgl.LngLat(longitude, latitude);
      map.flyTo({
        center: [longitude, latitude],
        speed: 1,
        zoom: zoom
      });
      // only show events when not clicking "All Locations"
      if (selector.value) {
        map.once('zoomend', () => clickDesiredTooltip(map, coords));
      }
    });
  });
}

const closeMapOverlays = (map) => {
  const sidebar = document.querySelector('#side-panel-container');
  sidebar.classList.remove('active');
  map._popups.forEach(popup => popup.remove());
}

const clickDesiredTooltip = (map, coords, disruptiveEventId = null) => {
  const point = map.project(coords)
  // https://datatracker.ietf.org/doc/html/rfc7946#section-3.2
  const features = map.queryRenderedFeatures(point).filter((feature) => feature.layer.id.match('-events'))
  if (features.length) insertMapPopUp(features, coords.lat, coords.lng);
  if (disruptiveEventId) {
    const liveTooltips = document.querySelectorAll('.tooltip-list-item');
    const relatedTooltip = Array.from(liveTooltips).find(tooltip => {
      return tooltip.dataset.disruptiveEventId == disruptiveEventId
    });
    if (!relatedTooltip.classList.contains('active')) {
      triggerReactionOnClick(relatedTooltip);
    }
  }
}
