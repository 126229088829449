import { Dropzone } from 'dropzone';

Dropzone.autoDiscover = false;

document.addEventListener('turbolinks:load', () => {
  const caseDocumentsDropZone = document.querySelector('#case-documents-dropzone');
  if (!caseDocumentsDropZone) return;

  const unsuccessfulUpload = document.querySelector('#unsuccessful-upload');
  const successfulUpload = document.querySelector('#successful-upload');
  new Dropzone(caseDocumentsDropZone, {
    url: '/case_documents',
    paramName: 'file', // The name that will be used to transfer the file
    maxFilesize: 100, // MB
    addRemoveLinks: true,
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
    acceptedFiles: 'image/png, image/jpg, image/jpeg, image/tif, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    success: () => {
      unsuccessfulUpload.classList.add('hidden')
      successfulUpload.classList.remove('hidden')
      window.location = window.location.href // Redirect back to page.
    },
    error: (_file, error) => {
      successfulUpload.classList.remove('hidden')
      unsuccessfulUpload.classList.remove('hidden')
      unsuccessfulUpload.querySelector('p').innerHTML = error
    }
  })
})
