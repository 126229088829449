const retryEvery = 1500
const errorBackoff = 1.05
const fiveMinutes = 300000
let errorRetry = retryEvery
let occupancyPoll

function pollLocationOccupancy(locationPuid) {
  // return if currentLocationPuid is not the same as the locationPuid
  if(!locationPuid) return; // Sometimes it's being called without id
  if (currentLocationPuid() !== locationPuid) return

  $.ajax({
    url: `/occupancy/${locationPuid}.json`,
    type: "get",
    timeout: 500,
    retryLimit: 0,
    dataType: "json",
    complete: (response, textStatus) => {
      const data = response.responseJSON
      //  TODO: we could refactor a little bit
      if (textStatus === "success") {
        $("#location-capacity .spinner").addClass("hidden")
        if (data["capacity"] === 0) {
          $("#location-capacity").addClass("hidden")
          $("#location-capacity-input").addClass("hidden")
        } else {
          $("#location-capacity").removeClass("hidden")
          $("#location-capacity-input").removeClass("hidden")
          $("#location-capacity-input").removeAttr("style") // somehow display: none; is being added.
          $("#location-capacity > input").val(`${data["occupants"]} / ${data["capacity"]}`)

          errorRetry = retryEvery
          occupancyPoll = setTimeout(() => {pollLocationOccupancy(locationPuid)}, retryEvery)
        }
      } else {
        $("#location-capacity").removeClass("hidden")
        $("#location-capacity .spinner").show()
        $("#location-capacity > input").hide()

        errorRetry = errorRetry ** errorBackoff
        errorRetry = errorRetry > fiveMinutes ? fiveMinutes : errorRetry
        occupancyPoll= setTimeout(() => {pollLocationOccupancy(locationPuid)}, errorRetry)
      }
    }
  })
}

document.addEventListener("turbolinks:load", function() {
  $("#physical_location_track_occupancy").on("change", function() {
    if (this.value === "true") {
      $(".location-capacity-field").removeClass("hidden")
    } else {
      $(".location-capacity-field").addClass("hidden")
    }
  })

  const rollCallPageData = $("#roll-call-page").data() || {}
  if(rollCallPageData.occupancyTrackingEnabled) {
    pollLocationOccupancy(rollCallPageData.checkinLocationPuid)
  }

  const visitorListPageData = $("#visitor-list-page").data() || {}
  if(visitorListPageData.occupancyTrackingEnabled) {
    pollLocationOccupancy(visitorListPageData.checkinLocationPuid)
  }

  const scannerPageData = $("#scanner-page").data() || {}
  if(scannerPageData.occupancyTrackingEnabled) {
    pollLocationOccupancy($("#scanner-page #location-select").val())

    $("#scanner-page #location-select").change((event) => {
      clearTimeout(occupancyPoll)
      pollLocationOccupancy(event.target.value)
    });
  }
})

const currentLocationPuid = () => {
  const rollCallPageData = $("#roll-call-page").data() || {}
  const visitorListPageData = $("#visitor-list-page").data() || {}
  const scannerPageData = $("#scanner-page").data() || {}

  if (rollCallPageData.occupancyTrackingEnabled) {
    return rollCallPageData.checkinLocationPuid;
  } else if (visitorListPageData.occupancyTrackingEnabled) {
    return visitorListPageData.checkinLocationPuid;
  } else if (scannerPageData.occupancyTrackingEnabled) {
    return $("#scanner-page #location-select").val();
  }
}
