import filterParams from "./filter_params";
import { setupListener } from './components/dynamic_forms';
import Ably from 'ably';

document.addEventListener("turbolinks:load", function (event) {
  const table = document.getElementById("visitor-table");
  const visitorForm = document.getElementById("visitor-form-container");

  const physicalLocationSelect = document.getElementById("visitor_physical_location_id");
  if (physicalLocationSelect) {
    updateToSeeSelect(physicalLocationSelect.value);

    physicalLocationSelect.addEventListener("change", (event) => {
      const selectedLocation = event.target.value;
      updateToSeeSelect(selectedLocation);
    });
  }

  if (table) {
    $("#visitors-text-search").on("click", (e) => {
      const searchText = $("[data-text-search]").val();
      filterParams("q", searchText);
    });

    // setup PubSub
    let channel_name = table.dataset.realtimeChannel;
    let client = new Ably.Realtime({
      authUrl: "/auth/realtime/token"
    });
    client.connection.on( function(event) {
      if(event.reason) {
        // TODO: This needs to be turned into a Sentry error. After initial deployment, we can remove these lines entirely.
        console.log("PubSub State: " + event.current);
        console.log("PubSub Failure: " + event.reason.message);
        console.log("Reason: " + event.reason.cause);
        console.log(event);
      }
    });
    let channel = client.channels.get(channel_name);
    channel.subscribe("refresh",function(message) {
      location.reload();
    });
  }

  if (visitorForm) {
    const datepicker = require("js-datepicker");

    let minDate = new Date();
    const datepickerStartDate = $("#visitor_day_of_visit").data("date");
    let datepickerSelectedStartDate = undefined;

    if (datepickerStartDate) {
      const date = datepickerStartDate.split("/");
      datepickerSelectedStartDate = new Date(date[2], date[0] - 1, date[1]);
      // Set for end of day so we can allow users
      // to select current day, only if the date hasn't passed.
      datepickerSelectedStartDate.setHours(23, 59, 59, 999);
    }

    datepicker("#visitor_day_of_visit", {
      position: "tl",
      minDate: minDate,
      dateSelected: datepickerSelectedStartDate,
      onSelect: (instance, date) => {
        // visitor_day_of_visit NULL it's interpretated in backend as Today
        // plus, we're skiping notified_at_day input to be part of the form
        if (isSelectedDateToday(date)) {
          document.getElementById('visitor_day_of_visit').value = null;
          return;
        }

        const month = date.getMonth() + 1; // month is 0 indexed
        const stringifiedDate = `${month}/${date.getDate()}`;
        $("#default-visitor-notification-warning").addClass("hidden");
        $("#hidden-time-select").removeClass("hidden");
        $("#notified_at_day").text(stringifiedDate);
        showTimeSelect();
      },
    });

    showTimeSelect();
  }

  function showTimeSelect() {
    $("#faux-link").on("click", () => {
      $("#faux-link").addClass("hidden");
      $("#time-select").removeClass("hidden");
    });
  }
  setupFileListeners();
});

function isSelectedDateToday(date) {
  const nowDate = new Date();
  const today = `${nowDate.getMonth()}/${nowDate.getDate()}/${nowDate.getFullYear()}`;
  const selectedDate = `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
  return today == selectedDate;
}

const updateToSeeSelect = (selectedPhysicalLocation) => {
  const toSeeSelect = document.querySelector("#visitor_to_see_id");
  const currentToSeeId = document.querySelector("#visitor_current_to_see_id");
  toSeeSelect.querySelectorAll('option:not(:first-child)').forEach((option) => { option.remove() });
  if (!selectedPhysicalLocation) return;

  fetch(`/location_employees.json?physical_location_id=${selectedPhysicalLocation}`)
  .then((rawResponse) => rawResponse.json())
  .then((response) => {
    if (!response.employees) return;

    response.employees.forEach((employee) => {
      const option = document.createElement('option');
      option.textContent = employee.full_name;
      option.value = employee.id;
      toSeeSelect.append(option.cloneNode(true));
    });
    const currentValueExists = response.employees.find(employee => employee.id == currentToSeeId.value);
    toSeeSelect.value = currentValueExists ? currentToSeeId.value : '';
  });
}

function setupFileListeners(query) {
  // So yes, this is a bit tricky. Only the last file input for each document type
  // is reponsible of fetching new file input
  const wrappers = document.querySelectorAll(query || '[data-container="files-wrapper"]');
  wrappers.forEach((wrapper) => {
    const fileInputs = wrapper.querySelectorAll('input[type="file"]');
    const lastFileInput = fileInputs[fileInputs.length - 1];

    lastFileInput.addEventListener('change', function _listener() {
      const containerId = lastFileInput.getAttribute('data-form-name');
      setupListener(lastFileInput, false, () => setupFileListeners(`#${containerId}`))
      // Remove listener after a file is attached
      // since an new input field it's added.
      lastFileInput.removeEventListener('change', _listener);
    });
  });
}
