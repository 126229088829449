import { toggleUrlParam, removeParamsFromUrl } from './utils/location';
import { getSelectedValueByEvent } from './utils/selects';

document.addEventListener('turbolinks:load', () => {
  const failedScreeningSelect = document.querySelector('#failed-screening-select');
  if (failedScreeningSelect) {
    failedScreeningSelect.addEventListener('change', (e) => {
      const screeningFilterParam = getSelectedValueByEvent(e);
      toggleUrlParam('failed_screening', screeningFilterParam);
    });
  }

  // I believe this is used in several views; it doesn't belong here.
  const clearFilter = document.querySelector('#clear-filter');
  if (clearFilter) {
    clearFilter.addEventListener('click', () => removeParamsFromUrl());
  }
});
