
export default function setupMediumEditor() {
  const editableTextAreas = document.querySelectorAll('.medium-editor');
  if(!editableTextAreas) return;

  new MediumEditor(editableTextAreas, {
    toolbar: {
      buttons: ['bold', 'italic', 'underline', 'anchor', 'h1', 'h2', 'h3'],
    },
    anchor: {
      linkValidation: true,
    }
  });
}

document.addEventListener('turbolinks:load', () => {
  setupMediumEditor();
});
