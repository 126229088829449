import renderWorkforceAvailabilityChart from "./charts/executive_dashboard/workforce_availability"
import renderVaccinationCoverageChart from "./charts/executive_dashboard/vaccination_coverage"
import renderCasesDistributionChart from "./charts/executive_dashboard/cases_distribution"
import renderWorkClimateChart from "./charts/executive_dashboard/work_climate"
import renderWorkplaceCompliance from "./charts/executive_dashboard/workplace_compliance"
import renderEnvironmentalFactorsChart from "./charts/executive_dashboard/environmental_factors"
import Rails from '@rails/ujs';
import initThresholdsControls from "./availability_thresholds"

function initCharts () {
  getChartData("workforce-availability-chart", "workforce_availability", function (data) {
    renderWorkforceAvailabilityChart(data)
    showThresholdsControls()
  })
  getChartData("workplace-compliance-chart", "workplace_compliance", renderWorkplaceCompliance)
  getChartData("work-climate-chart", "work_climate", renderWorkClimateChart)
  getChartData("vaccination-coverage-chart", "vaccination_coverage", renderVaccinationCoverageChart)
  getChartData("cases-distribution-chart", "cases_distribution", renderCasesDistributionChart)
  getChartData("environmental-factors-chart", "environmental_factors", renderEnvironmentalFactorsChart)
}

function getChartData (containerId, chartId, onSuccess) {
  Rails.ajax({
    type: 'GET',
    url: `/chart_data/${chartId}${location.search}`,
    dataType: 'json',
    success: onSuccess,
    error: function (error) {
      console.error(error)
      hideSpinner(containerId)
      addErrorMessage(containerId)
    }
  })
}

function showThresholdsControls () {
  const link = document.querySelector("a[data-modal='availability-thresholds-modal']")

  link.classList.remove("hidden")
}

function addErrorMessage (containerId) {
  const container = document.getElementById(containerId)
  const errorMsg = document.createElement("p")
  errorMsg.classList.add("text-gray-600")
  errorMsg.classList.add("text-sm")
  errorMsg.innerText = "We don't have any statistics for this selection yet!"

  container.append(errorMsg)
}

function hideSpinner (containerId) {
  const container = document.getElementById(containerId)
  const spinner = container.querySelector(".animate-spin")
  spinner.classList.add("hidden")
}

document.addEventListener('turbolinks:load', () => {
  const chartContainer = document.getElementById("workforce-availability-chart");
  if (!chartContainer) return

  initThresholdsControls()
  initCharts()
});
