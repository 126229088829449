export default function stateOrProvince() {
  const currentCountry = $("#physical_location_country").val()

  if (currentCountry == "United States") {
    $("#state-field").removeClass("hidden")
    $("#physical_location_state").prop("disabled", false)
    $("#province-field").addClass("hidden")
    $("#physical_location_province").prop("disabled", true)
  } else {
    $("#province-field").removeClass("hidden")
    $("#physical_location_province").prop("disabled", false)
    $("#state-field").addClass("hidden")
    $("#physical_location_state").prop("disabled", true)
  }
}

document.addEventListener("turbolinks:load", () => {
  stateOrProvince()
  $("#physical_location_country").on("change", (e) => {
    $("#physical_location_province").val("")
    stateOrProvince()
  });
})
