import Highcharts from "./highcharts_config";

/*
  The data for this chart needs to come in the form of:
  {
      "name": "Vaccination Status",
      "categories": [
          "Fully Vaccinated",
          "Partial",
          "Expired",
          "Exempt",
          "Opt Out",
          "No Response"
      ],
      "series": [
          {
              "data": [41, 16, 16, 22, 0, 5], // data in this case is percent
          }
      ]
  }
 */
document.addEventListener("turbolinks:load", function (event) {
  // Charts
  const colors = [
    "#16A34A",
    "#FCC515",
    "#F97316",
    "#EB3334",
    "#EB3334",
    "#DC2626",
  ]
  const nodes = $(".chart-response-distribution").each((i, node) => {
    const data = $(node).data("chart")

    Highcharts.chart(node.id, {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column"
      },
      title: {
        text: data.name,
        align: "left",
        margin: 15,
        style: {
          fontWeight: "bold"
        }
      },
      xAxis: {
        categories: data.categories,
        title: {
          text: null,
        },
        style: {
          marginTop: 5
        },
        maxPadding: 0,
        pointWidth: 5

      },
      yAxis: {
        title: {
          text: null,
        },
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "bold",
            color: ( // theme
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || "gray"
          }
        }
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        backgroundColor: "#000",
        borderColor: "#000",
        borderRadius: 15,
        style: {
          color: "#fff",
        },
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat: "{point.y:,.0f}",
      },
      plotOptions: {
        series: {
          // pointWidth: 15,
          groupPadding: 0.0,
          dataLabels: {
            enabled: true,
            color: "#7D8692"
          },
          colorByPoint: true,
          colors: [
            "#16A34A",
            "#16A34A",
            "#FCC515",
            "#F97316",
            "#E11D48",
            "#E11D48",
          ]
        }

      },
      series: data.series.map((series,i) => {
        // series.color = colors[i]
        // series.borderColor = colors[i]
        return series
      })
    })
  })
})
