import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs/modular/sortable.core.esm.js'

// Connects to data-controller="sortable"
export default class extends Controller {
  static targets = ['item', 'position']
  static values = {
    groupName: String,
    handle: String
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      handle: this.handleValue || undefined,
      group: { name: this.groupNameValue },
      onUpdate: this.updateSortInputs,
    })
  }

  disconnect () {
    this.sortable.destroy()
    this.sortable = undefined
  }

  updateSortInputs = () => {
    this.positionTargets.forEach((position, index) => {
      position.value = index
    })
  }
}
