import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="text-typing-animation"
export default class extends Controller {
  static targets = ['typing'];
  static values = {
    typingDelay: { type: Number, default: 8 }, // Delay between each character being typed out.
    cursorBlinkCount: { type: Number, default: 2 }, // Number of times the cursor blinks.
    cursorBlinkDelay: { type: Number, default: 300 }, // Delay between each cursor blink.
    endOfSentenceDelay: { type: Number, default: 800 } // Delay after a sentence ends.
  }
  static outlets = [
    'reveal', // Use the reveal outlet to show content after the animation ends.
    'pin-scrolling-to-bottom'
  ]

  connect() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.animateText().then(() => this.revealContentOnAnimationEnd());
        }
      });
    });

    this.observer.observe(this.element);
  }

  disconnect() {
    this.observer.disconnect();
  }

  async animateText() {
    for (const element of this.typingTargets) {
      await this.typeOut(element);
      await this.blinkCursor(element);
    }
  }

  typeOut(element) {
    return new Promise(resolve => {
      const text = element.textContent;
      element.textContent = '';
      element.classList.remove('hidden');
      let i = 0;
      const cursor = this.appendCursor(element);
      
      const endOfSentenceCharacters = ['.', '!', '?'];
      const isEndOfSentence = (char) => endOfSentenceCharacters.includes(char);
      const isEllipsis = (text, i) => text.substr(i, 3) === '...';
      const isLastCharacter = (text, i) => text.substr(i + 1, 2) === '\n';

      const typeNextChar = () => {
        if (i < text.length) {
          element.textContent = text.slice(0, i);
          element.appendChild(cursor); // always add the cursor
  
          let delay = this.typingDelayValue;
          if (isEndOfSentence(text.charAt(i)) && i !== text.length - 1) {
            if (isEllipsis(text, i)) { i += 2; }

            delay = isLastCharacter(text, i) ? this.typingDelayValue : this.endOfSentenceDelayValue;
          }
          setTimeout(() => {
            element.textContent = text.slice(0, ++i); // remove the cursor
            if (i < text.length) {
              element.appendChild(cursor); // add the cursor back if it's not the end of the text
            }
            setTimeout(typeNextChar, delay);
          }, this.typingDelayValue);
        } else {
          element.textContent = text; // remove the cursor at the end
          resolve();
        }

        if (this.hasPinScrollingToBottomOutlet) {
          this.pinScrollingToBottomOutlet.scrollContentToBottom();
        }
      };
  
      typeNextChar();
    });
  }

  blinkCursor(element) {
    return new Promise(resolve => {
      let blinkCount = 0;
      const cursor = this.appendCursor(element);
      cursor.classList.add('blinking-cursor');

      const blinking = setInterval(() => {
        blinkCount++;
        if (blinkCount <= this.cursorBlinkCountValue * 2) {
          if (blinkCount % 2 === 0) {
            cursor.style.visibility = 'hidden'; // hide the cursor
          } else {
            cursor.style.visibility = 'visible'; // show the cursor
          }
        } else {
          clearInterval(blinking);
          cursor.remove(); // ensure the cursor is removed
          resolve();
        }
      }, this.cursorBlinkDelayValue);
    });
  }

  appendCursor(element) {
    const cursor = document.createElement('span');
    cursor.textContent = '|';
    cursor.style.fontWeight = 'bold'; // make the cursor bold
    element.textContent = element.textContent.trim(); // trim the whitespace
    element.appendChild(cursor);
    return cursor;
  }

  revealContentOnAnimationEnd() {
    if (this.hasRevealOutlet) this.revealOutlet.show();
  }
}
