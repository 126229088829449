// TODO: Remove jQuery code and use vanilla JS
document.addEventListener("turbolinks:load", function(event) {
  $("#visitor_type_auto_checkout_enabled").on("change", function() {
    if (this.value === "true") {
      $(".auto-checkout-field").removeClass("hidden")
    } else {
      $(".auto-checkout-field").addClass("hidden")
    }
  })

  $("#visitor_type_scheduled_invitations_enabled").on("change", function() {
    if (this.value === "true") {
      $(".scheduled_invitations_fields").removeClass("hidden")
    } else {
      $(".scheduled_invitations_fields").addClass("hidden")
    }
  })

  $("#visitor_type_cutoff_time").on("change", function () {
    const validUntilMidnightSelect = $("#visitor_type_valid_until_midnight");
    const selectContainer = $(".valid-until-midnight-select-container")
    const descriptionContainer = $(".valid-until-midnight-description-container")

    if (this.value === "") {
      selectContainer.hide();
      descriptionContainer.hide();
      validUntilMidnightSelect.val("false");
      validUntilMidnightSelect.hide();
      validUntilMidnightSelect.trigger();
      validUntilMidnightSelect.prop("disabled", true);
    } else {
      validUntilMidnightSelect.prop("disabled", false);
      validUntilMidnightSelect.show();
      selectContainer.show();
      descriptionContainer.show();
    }
  })
});
