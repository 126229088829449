document.addEventListener("turbolinks:load", function (event) {
  const navTabs = document.querySelectorAll(".nav-tab");
  const params = new URLSearchParams(document.location.search.substring(1));
  const selectedTab = params.get("selected_tab");

  if (selectedTab && navTabs.length > 0) {
    // forces to the top of the stack
    setTimeout(function () {
      $("nav").find(`[data-nav-tab="${selectedTab}"]`).trigger("click");
    }, 0);
  }

  navTabs.forEach((tab) => {
    $(tab).on("click", function (e) {
      e.preventDefault();
      const targetedPanelId = $(this).attr("data-nav-tab");

      $(".nav-tab").removeClass("border-b-2");
      $(this).addClass("border-b-2");

      $(".nav-tab").removeClass("text-indigo-600");
      $(".nav-tab").addClass("text-gray-500");

      $(".nav-tab svg").removeClass("text-indigo-600");
      $(".nav-tab svg").addClass("text-gray-500");

      $(this).removeClass("text-gray-500");
      $(this).addClass("text-indigo-600");

      $(this).find("svg").removeClass("text-gray-500");
      $(this).find("svg").addClass("text-indigo-600");

      $(".nav-panel").addClass("hidden");
      $(`#${targetedPanelId}`).removeClass("hidden");
    });
  });
});
