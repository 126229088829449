import Highcharts from "../../charts/highcharts_config";

const COLORS = ["#97A4AF", "#00A748", "#FF4000"];
const CATEGORIES = ['Not Responded', 'Unaffected', 'Affected'];

const renderChart = (data) => {
  const chartContainer = document.getElementById('incident-responses-chart');
  if (!chartContainer) return;

  chartContainer.setAttribute("style", null)
  Highcharts.chart(chartContainer.id, {
    credits: {
      enabled: false,
    },
    chart: {
      type: "bar",
      height: 200,
      spacingBottom: 0
    },
    title: {
      text: null,
    },
    xAxis: {
      categories: CATEGORIES,
      title: {
        text: null,
      },
      labels: {
        format: '{value}',
        style: {
          fontWeight: 'bold',
        }
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      gridLineDashStyle: 'longdash',
      title: {
        text: null,
      },
      labels: {
        style: {
          fontWeight: 'bold',
        },
        format: '{value}%',
      },
    },
    legend: {
      enabled: false
    },
    tooltip: {
      backgroundColor: "#000",
      borderColor: "#000",
      borderRadius: 15,
      style: {
        color: "#fff",
      },
      headerFormat: "",
      pointFormat: "{point.name} : {point.value:,.0f}<br/>Total: {point.stackTotal}",
    },
    series: data.series
  })
}

const processData = (chartContainer) => {
  const data = JSON.parse(chartContainer.getAttribute('data-responses'))
  const totalCheckIns = data.total;
  const serieData = CATEGORIES.reduce((acc, category, i) => {
    const categoryValue = data.items[i];
    const percentage = (categoryValue * 100) / totalCheckIns;
    return acc.concat({
      name: category,
      y: percentage,
      value: categoryValue,
      color: COLORS[i],
      borderColor: COLORS[i],
      stackTotal: totalCheckIns
    });
  }, []);
  return { categories: CATEGORIES, series:  [{ data: serieData }] };
}

export default function setupResponseChart() {
  const chartContainer = document.getElementById('incident-responses-chart');
  const data = processData(chartContainer);
  renderChart(data );
}
