import datepicker from 'js-datepicker';

document.addEventListener('turbolinks:load', () => {
  // Person search in case form
  const searchForm = document.querySelector('#personSearch');
  const newCaseForm = document.querySelector('#new_case');

  if (searchForm && newCaseForm) {
    const caseable_id = newCaseForm.querySelector('#case_caseable_id');
    const caseable_type = newCaseForm.querySelector('#case_caseable_type');
    const physical_location_id = newCaseForm.querySelector('#case_physical_location_id');

    // When selecting a search result:
    // - Case form hidden fields (caseable_type, caseable_id and physical_location_id)
    //   are populated with the selected person's info
    searchForm.querySelector('#searchResults').addEventListener('click', (e) => {
      if (e.target.tagName !== 'LI') return;

      caseable_type.value = e.target.dataset.personType;
      caseable_id.value = e.target.dataset.personId;
      physical_location_id.querySelectorAll('option').forEach((option) => {
        if (option.innerHTML !== e.target.dataset.personPrimaryLocationName) return;

        option.setAttribute('selected', 'selected');
      });
    });
    // When clicking the search field and field has a person info:
    // - Clear the Case form hidden fields
    searchForm.querySelector('#search').addEventListener('click', () => {
      caseable_type.value = '';
      caseable_id.value = '';
      physical_location_id.selectedIndex = 0;
    });
  }

  const newMessageForm = document.querySelector('#new-message-form');
  if (newMessageForm) {
    newMessageForm.addEventListener('submit', () => {
      newMessageForm.querySelector('button').setAttribute('disabled', true);
    })
  }

  const followupModal = document.getElementById('set-followup-modal');
  if (followupModal) {
    const minDate = new Date();
    const node = document.getElementById('case_followup_at');
    if (node.getAttribute('calendar-setted-up')) return;

    datepicker(node, {
      position: 'bl',
      minDate: minDate,
      dateSelected: undefined,
      onSelect: (_instance, date) => {
        const nextFollowupLabel = document.querySelector('[data-next-followup]');
        nextFollowupLabel.textContent = `${calculateDays(date) + 1} Days`;
      }
    });
    node.setAttribute('calendar-setted-up', 'true');
  }

  drawStatusBars();

  const caseTypeSelect = document.getElementById('case_type_select');
  if (caseTypeSelect) {
    caseTypeSelect.addEventListener('change', () => {
      requestDiagnosisMethodField(caseTypeSelect.value);
    });
  }

  window.addEventListener('resize', () => {
    redrawStatusBars();
  })
});

const calculateDays = (followupDate) => {
  const minDate = new Date();
  const diff_in_time = followupDate.getTime() - minDate.getTime();

  return ~~(diff_in_time / (1000 * 3600 * 24));
}

const drawStatusBars = () => {
  const caseStatusBar = document.querySelector('#case-status-bar');
  if (!caseStatusBar) return;

  const offsets = []
  const barColors = []
  const caseStatusbarContainerOffset = offset(caseStatusBar);
  document.querySelectorAll('.case-status-point')
    .forEach((element) => offsets.push(offset(element)));

  document.querySelectorAll('.case-status-point')
    .forEach((element) => barColors.push(element.dataset['bar-color']));

  offsets.forEach((element, index) => {
    if (index === offsets.length - 1) return;

    const svgWidth = Math.round(offsets[index + 1].left - offsets[index].left)
    const svgTopOffset = Math.round(element.top - caseStatusbarContainerOffset.top);
    const pointHeight = document.querySelector('.case-status-point').getBoundingClientRect().height;
    const pointWidth = document.querySelector('.case-status-point').getBoundingClientRect().height;
    const topOffset = Math.round(pointHeight / 2);
    const leftStart = Math.round(offsets[index].left - caseStatusbarContainerOffset.left) + Math.round(pointWidth / 2);
    const rightEnd = leftStart + svgWidth;
    const strokeColor = barColors[index];
    const svgConnector = `
      <svg class='absolute case-bar' style='left: ${leftStart}px; top: ${svgTopOffset}px;' width='${svgWidth}' height='${pointHeight}'>
        <line x1='0' y1='${topOffset}' x2='${rightEnd}' y2='${topOffset}' stroke='${strokeColor}' stroke-width='2' />
      </svg>`
    caseStatusBar.insertAdjacentHTML('beforeend', svgConnector);
  });
}

const redrawStatusBars = () => {
  const statusBar = document.querySelector('#case-status-bar');
  if (!statusBar) return;

  statusBar.querySelectorAll('.case-bar').forEach((bar) => bar.remove());
  drawStatusBars();
}

const requestDiagnosisMethodField = async (caseTypeId) => {
  const caseTypeIndexURL = `/case_types/${caseTypeId}/fields.js?category=diagnosis`;
  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const options = {
    headers: {
      'X-CSRF-Token': csrfToken,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
  const response = await fetch(caseTypeIndexURL, options);
  const parsedResponse = await response.json();
  document.getElementById('diagnosisMethodFieldContainer').innerHTML = parsedResponse.html;
};

const offset = (element) => {
  const box = element.getBoundingClientRect();
  const docElem = document.documentElement;

  return {
    top: box.top + window.pageYOffset - docElem.clientTop,
    left: box.left + window.pageXOffset - docElem.clientLeft
  };
};
