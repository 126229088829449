import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hash-highlight"
export default class extends Controller {
  static values = { classes: String }

  connect() {
    this.checkHash()
  }

  checkHash() {
    const hash = window.location.hash
    const selectedElement = this.element.querySelector(hash)

    if (hash && selectedElement) {
      selectedElement.classList.add(this.classesValue)
    }
  }
}
