import datepicker from 'js-datepicker'
import { v4 as uuidv4 } from 'uuid';

// Usage:
// Having a text field tag, add the `date-select` class.
// Optionally you can include data attributes like:
// min-date, max-date, date, date-format and typing-allowed

const buildDateFromNodeAttr = (element, dataAttribute) => {
  const attributeValue = element.data(dataAttribute);
  if(!attributeValue) return null;

  return new Date(attributeValue);
};

export default function setupAllDatePickers() {
  $('.date-select').map((i, node) => {
    if(node.getAttribute('calendar-setted-up')) return;

    const typingAllowed = node.dataset.typingAllowed == 'true';
    if (!typingAllowed) {
      node.setAttribute("onkeydown", "return false");
    };

    const dateSelector = $(node);
    const minDate = buildDateFromNodeAttr(dateSelector, 'min-date');
    const maxDate = buildDateFromNodeAttr(dateSelector, 'max-date');
    const dateSelected = buildDateFromNodeAttr(dateSelector, 'date');
    const isFormatLocale = node.getAttribute('data-date-format') === 'locale';
    const customConfig = {};

    if(maxDate) validateInputMaxDate(node, maxDate);
    if (isFormatLocale) {
      customConfig['formatter'] =  (input, date, instance) => {
        input.value = date.toLocaleDateString("en-US");
      }
    }

    datepicker(node, {
      id: uuidv4(),
      position: 'bl', // Use so this doesn't overflow on mobile
      dateSelected,
      minDate,
      maxDate,
      onSelect: (_instance, _date) => {
        node.dispatchEvent(new Event('change'));
      },
      ...customConfig,
    });
    node.setAttribute('calendar-setted-up', 'true');
  });

  function validateInputMaxDate(input, maxDate) {
    input.addEventListener('change', () => {
      const inputValue = input.value;
      if(!inputValue) return input.setCustomValidity('');

      const newDate = new Date(inputValue);
      const isDateValid = newDate < maxDate;
      const errorMessage = isDateValid ? '' : 'Invalid date';
      input.setCustomValidity(errorMessage);
    });
  }
};
