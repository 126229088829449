import Highcharts from "./highcharts_config";
import { highChartsConfig } from "./work_climate_chart_helpers";

document.addEventListener("turbolinks:load", () => {
  const workClimateScoresContainer = document.querySelector('#work-climate-scores-container');
  if (!workClimateScoresContainer) return;

  generateCharts(workClimateScoresContainer);
});

const generateCharts = (chartContainer) => {
  chartContainer.querySelectorAll('.climate-chart').forEach((element) => {
    const chartName = element.dataset.chartName;
    const scoresData = JSON.parse(element.dataset.scores);
    Highcharts.chart(element.id, highChartsConfig(chartName, scoresData));
  });
}
