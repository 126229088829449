var screeningAnswers = [];
var screeningResult = null;
var screeningInstructionId = null;

const endStateLabels = {
  "pass": "Pass",
  "onsite_screening_required": "On-site Screening Required",
  "fail_symptomatic": "Fail - Symptomatic",
  "fail_exposure": "Fail - Exposure",
  "fail_travel": "Fail - Travel"
};

document.addEventListener("turbolinks:load", function(event) {
  const checkIn = $("#screening-check-in");
  if (checkIn) {
    $(checkIn).click(function() {
      // check in
      $("#screening-check-in").addClass('hidden');
      $("#screening-spinner").removeClass('hidden');

      const locationUid = checkIn.data("location-uid");
      const url = "/api/v1/locations/" + locationUid + "/entered.json";
      $.post(url, {
        type: checkIn.data("type"),
        uid:  checkIn.data("uid"),
        method: "manual",
        signed_by: checkIn.data("signed-in-by-uid")
      }, function() {
        $("#screening-spinner").addClass('hidden');
        $("#screening-check-in").replaceWith("<a href='javascript:window.close();' class='bg-green-600 text-center rounded py-2 px-4 font-bold text-white'>Done</a>");
      }).fail(function(jqXHR, textStatus, errorThrown) {
        const message = jqXHR.responseJSON["message"];
        alert( "Failed to check in user: " + message );
        $("#screening-spinner").addClass('hidden');
        $("#screening-check-in").replaceWith("<a href='javascript:window.close();' class='bg-red-600 text-center rounded py-2 px-4 font-bold text-white'>Close Window</a>");
      });
    });
  }
  const screeningSteps = $("#screening-steps");
  if (screeningSteps) {

    // video completion handler
    $('#screening-steps video').on('ended',function(){
      $(this).closest(".question").find(".video-play-button").addClass("bg-gray-200 text-gray-900").removeClass("bg-indigo-600").html("Play Again?").data('playing', false);
      $(this).closest(".question").find("button").removeClass("hidden");
    });

    // video play/pause
    $(".video-play-button").click(function() {
      const video = $(this).closest(".question").find("video");
      if(video.data('playing')) {
        video.trigger('pause');
        video.data('playing', false);
        $(this).html("Play Video");
      } else {
        video.trigger('play');
        video.data('playing', true);
        $(this).html("Pause Video");
      }
    });

    // multiple choice checkboxes
    $(".question.multiple .ctg-checkbox").click(function() {
      if($(this).attr("data-checked") === "true") {
        $(this).addClass("bg-white text-gray-900");
        $(this).removeClass("bg-blue-600 text-white border-blue-700 shadow font-bold");
        $(this).attr("data-checked", "false");
        $(this).find(".ctg-checkbox_check").removeClass("bg-blue-800 text-green-400 border-blue-900");
      } else {
        $(this).removeClass("bg-white text-gray-900");
        $(this).addClass("bg-blue-600 text-white border-blue-700 shadow font-bold");
        $(this).attr("data-checked", "true");
        $(this).find(".ctg-checkbox_check").addClass("bg-blue-800 text-green-400 border-blue-900 border-black");
      }
    });

    $("button[data-action='next_question']").click(function(event) {
      var questionId = $(this).data('question-id');
      if (!questionId) {
        alert("This next question is not properly configured. Please fix in the screening editor.");
        return;
      }

      if(progress(this)) {
        $(`.question[data-question-id='${questionId}']`).removeClass("hidden").find("textarea, input").focus();
      }
    });

    $("button[data-action='instruction']").click(function(event) {
      if(progress(this)) {
        instructionId = $(this).data('instruction-id');
        screeningInstructionId = instructionId;
        screeningResult = $(`.instruction[data-instruction-id='${instructionId}']`).data('result');
        showConfirmation();
      }
    });

    $("button[data-action='onsite_screening_required']").click(function(event) {
      if(progress(this)) {
        screeningResult = "onsite_screening_required";
        showConfirmation();
      }
    });

    $("button[data-action='pass']").click(function(event) {
      if(progress(this)) {
        screeningResult = "pass";
        showConfirmation();
      }
    });

    $("button[data-action='submit']").click(function(event) {
      $(".review-answers").addClass("hidden");
      if(screeningInstructionId) {
        instruction = $(`.instruction[data-instruction-id='${instructionId}']`);
        $(instruction).removeClass("hidden");
      }
      submitScreening(screeningSteps.data("type"), screeningSteps.data("uid"), screeningSteps.data("organization-uid"), screeningSteps.data("profile-uid"), screeningResult, screeningAnswers, screeningSteps.data("completed-by"));
    });

    $("button[data-action='revise']").click(function(event) {
      location.reload();
    });

    // Show first...
    $(".question").first().removeClass("hidden").find("textarea, input").focus();

  }
});

// In the case of custom answers, we want to check a few things about it
function validatedAnswer(question, response) {
  let answer

  if(question.hasClass("multiple")) {

    answer = question.find("[data-checked='true']").map((i,checked) => {
      return $(checked).data("answer");
    })
      .get()
      .join(", ");

    return !answer
      ? alert("You must choose at least one option.")
      : answer

  } else if(question.hasClass("text")) {
    answer = question.find("textarea").val();
    return !answer
      ? alert("Please provide a response.")
      : answer

  } else if(question.hasClass("numeric")) {
    answer = question.find("input[type='text']").val();
    return !answer
      ? alert("You must enter a value.")
      : answer

  } else {
    return $(response).text().trim();
  }
}

function progress(response) {
  const question = $(response).closest(".question")
  const answer = validatedAnswer(question, response)

  if (answer) {
    screeningAnswers.push({
      question: question.find(".prompt").text(),
      answer: answer
    });

    question.addClass("hidden");
  }

  return answer ? true : false
}

function showConfirmation() {
  $(".review-answers .result-title").html("Result: " + endStateLabels[screeningResult]);
  $(".review-answers .submission").append("<div class='font-bold text-xl my-2 text-center'>Please Confirm Your Answers:</div>");
  screeningAnswers.forEach(function (item) {
    $(".review-answers .submission").append("<div class='font-bold my-2'>" + item['question'] + "</div><div class='my-2 mb-8'>" + item['answer'] + "</div>");
  });

  $(".review-answers.submission").html();
  $(".review-answers").removeClass("hidden");
  $(".review-answers").css("margin-bottom", $(".review-answers .action-items").outerHeight());
}

function submitScreening(type, uid, organization_uid, profile_uid, result, answers, completed_by) {
  var redirect_to = {
    "Employee": `/e/${uid}`,
    "Visitor": `/vi/${uid}`,
    "Student": `/s/${uid}`,
    "Contractor": `/c/${uid}`,
  }[type]

  $.post("/api/v1/screenings.json", {
    "organization_uid": organization_uid,
    "type": type,
    "uid": uid,
    "profile_uid": profile_uid,
    "result": result,
    "completed_by": completed_by,
    "answers": JSON.stringify(answers)
  }, function() {
    if(result === "pass" || result === "onsite_screening_required") {
      window.location = redirect_to
    }
  }).fail(function(jqXHR, textStatus, errorThrown) {
    message = jqXHR.responseJSON["message"];
    alert( "Failed to submit screening: " + message );
  });
}
