import Highcharts from "../highcharts_config";
import { highChartsConfig } from "../work_climate_chart_helpers";

export default function renderChart(data) {
  const { name, series } = data;
  const chartContainer = document.getElementById("work-climate-chart");

  if (!chartContainer) return;

  chartContainer.setAttribute("style", null);

  Highcharts.chart(chartContainer.id, highChartsConfig(name, Object.values(series)[0]));
};