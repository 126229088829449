import setupFacetTooltips from './work_climate/facet_tooltip.js';

document.addEventListener('turbolinks:load', () => {
  setupFacetTooltips();
  handleRowLinks();
  setupScoreScrollers();
});

// This behavior appears in 
// - app/javascript/scripts/case_management_table.js
// - app/javascript/scripts/audit_logs_table.js
// TODO: Add this somewhere where it can be reused
const handleRowLinks = () => {
  const teamClimateTable = document.getElementById('team-climate-table');
  if (!teamClimateTable) return;

  document.querySelectorAll('tr[data-href]').forEach((row) => {
    row.addEventListener('click', () => {
      window.document.location = row.dataset.href;
    });
  });
}

const setupScoreScrollers = () => {
  const scoreItems = document.querySelectorAll("[data-score-id]");
  if (!scoreItems) return;

  const scrollableContainers = document.querySelectorAll('[data-component="scores-scroller"]');
  if (!scrollableContainers) return;

  scrollableContainers.forEach((container) => {
    preScrollContainer(container);
    disableTippyOnScroll(container, scoreItems);
    addBordersOnScroll(container);
  });
}

const debounce = (callback, timeout) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { callback(...args) }, timeout);
  };
}

const disableTippyOnScroll = (container, scoreItems) => {
  container.addEventListener('scroll', () => {
    scoreItems.forEach(item => {
      item._tippy.disable();
      debounce(item._tippy.enable(), 500);
    });
  });
}

const preScrollContainer = (container) => {
  const maxScrollLeft = container.scrollWidth - container.clientWidth;
  container.scroll(maxScrollLeft, 0);
}

const addBordersOnScroll = (container) => {
  const horizontalScrollThreshold = 10;
  const maxScrollLeft = container.scrollWidth - container.clientWidth - horizontalScrollThreshold;
  container.addEventListener('scroll', () => {
    if (container.scrollLeft < horizontalScrollThreshold) {
      container.classList.remove("border-l", "border-r-0");
      container.classList.add("border-r", "border-l-0");
    }
    else if (container.scrollLeft > maxScrollLeft) {
      container.classList.remove("border-r", "border-l-0");
      container.classList.add("border-l", "border-r-0");
    }
    else {
      container.classList.remove("border-l-0", "border-r-0");
      container.classList.add("border-l", "border-r");
    }
  });
}
