// TODO: The value CURRENT_FACET_COUNT must be updated every time a new facet is added.
// You can see the current active facets in app/models/work_climate_facet.rb.
// Find a way to not depend on a minimum of inactive facets and use the maximum active facets instead.
// This will require to chage the way we validateMinimumFacets for the itemAdd target.
const CURRENT_FACET_COUNT = 8;
const MINIMUM_ACTIVE_FACETS = 3;
const MAXIMUM_ACTIVE_FACETS = 5;
const MINIMUM_INACTIVE_FACETS = CURRENT_FACET_COUNT - MAXIMUM_ACTIVE_FACETS;

document.addEventListener('turbolinks:load', () => {
  const workClimateFacetsContainer = document.getElementById('work_climate_facets_container');
  if(!workClimateFacetsContainer) return;

  setupFacetsActionButtons(workClimateFacetsContainer);
  validateFacetsCount();
});

const setupFacetsActionButtons = (container) => {
  const removeButtons = Array.from(container.querySelectorAll('[data-sortable-target="itemRemove"]'));
  const addButtons = Array.from(container.querySelectorAll('[data-sortable-target="itemAdd"]'));

  removeButtons.forEach((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      const facetItem = e.target.closest('[data-sortable-target="item"]');
      deactivateFacet(facetItem);
    });
  });

  addButtons.forEach((item) => {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      const facetItem = e.target.closest('[data-sortable-target="item"]');
      activateFacet(facetItem);
    });
  });
};

const deactivateFacet = (item) => {
  // Hide handle and change background color
  item.querySelector('.handle').classList.add('hidden');
  item.querySelector('.block').classList.remove('bg-white');
  item.querySelector('.block').classList.add('bg-gray-50');
  // Update button from remove to add
  item.querySelector('[data-sortable-target="itemRemove"]').classList.add('hidden');
  item.querySelector('[data-sortable-target="itemAdd"]').classList.remove('hidden');

  adjustSortOrderWhenDeactivating(item);
  // Add item to inactive facets (last sort order)
  inactiveFacetsContainer().appendChild(item);
  validateFacetsCount();
};

const adjustSortOrderWhenDeactivating = (item) => {
  const enabledHiddenInput = item.querySelector('#facet_enabled_field');
  const sortOrderHiddenInput = item.querySelector('#facet_sort_order_field');

  // Set facet enabled hidden field to false
  enabledHiddenInput.value = false;
  // Subtract one from active sortOrders greater than item sortOrder
  const activeFacets = activeFacetItems();
  activeFacets.forEach((facet) => {
    const sortOrder = facet.querySelector('#facet_sort_order_field');
    if (sortOrder.value > sortOrderHiddenInput.value) {
      sortOrder.value = parseInt(sortOrder.value, 10) - 1;
    }
  });
  // Subtract one from inactive sortOrders
  const inactiveFacets = inactiveFacetItems();
  inactiveFacets.forEach((facet) => {
    facet.querySelector('#facet_sort_order_field').value -= 1;
  });
  // Set item sortOrder to last inactive sortOrder plus one
  const lastInactiveSortOrder = inactiveFacets[inactiveFacets.length - 1].querySelector('#facet_sort_order_field');
  sortOrderHiddenInput.value = parseInt(lastInactiveSortOrder.value, 10) + 1;
};

const activateFacet = (item) => {
  // Display handle and change background color
  item.querySelector('.handle').classList.remove('hidden');
  item.querySelector('.block').classList.remove('bg-gray-50');
  item.querySelector('.block').classList.add('bg-white');

  // Update button from remove to add
  item.querySelector('[data-sortable-target="itemAdd"]').classList.add('hidden');
  item.querySelector('[data-sortable-target="itemRemove"]').classList.remove('hidden');
  
  adjustSortOrderWhenActivating(item);
  // Add item to active facets (last sort order)
  activeFacetsContainer().appendChild(item);
  validateFacetsCount();
};

const adjustSortOrderWhenActivating = (item) => {
  const enabledHiddenInput = item.querySelector('#facet_enabled_field');
  const sortOrderHiddenInput = item.querySelector('#facet_sort_order_field');

  // Set enabled hidden field to true
  enabledHiddenInput.value = true;
  // Add one to inactive sortOrders less than item sortOrder to adjust for new sortOrder
  const inactiveFacets = inactiveFacetItems();
  inactiveFacets.forEach((facet) => {
    const sortOrder = facet.querySelector('#facet_sort_order_field');
    if (sortOrder.value < sortOrderHiddenInput.value) {
      sortOrder.value = parseInt(sortOrder.value, 10) + 1;
    }
  });
  // Set sortOrder to last active sortOrder plus one
  const activeFacets = activeFacetItems();
  const lastActiveSortOrder = activeFacets[activeFacets.length - 1].querySelector('#facet_sort_order_field');
  sortOrderHiddenInput.value = parseInt(lastActiveSortOrder.value, 10) + 1;
};

const validateFacetsCount = () => {
  validateMinimumFacets(activeFacetsContainer(), MINIMUM_ACTIVE_FACETS, 'itemRemove');
  validateMinimumFacets(inactiveFacetsContainer(), MINIMUM_INACTIVE_FACETS, 'itemAdd');
};

const validateMinimumFacets = (container, value, targetClass) => {
  const items = Array.from(container.querySelectorAll('[data-sortable-target="item"]'));

  if (items.length <= value) {
    items.forEach((item) => {
      item.querySelector(`[data-sortable-target="${targetClass}"]`).classList.add('hidden');
    });
  } else {
    items.forEach((item) => {
      item.querySelector(`[data-sortable-target="${targetClass}"]`).classList.remove('hidden');
    });
  }
};

const activeFacetsContainer = () => document.getElementById('active_facets');
const inactiveFacetsContainer = () => document.getElementById('inactive_facets');
const activeFacetItems = () => Array.from(activeFacetsContainer().querySelectorAll('[data-sortable-target="item"]'));
const inactiveFacetItems = () => Array.from(inactiveFacetsContainer().querySelectorAll('[data-sortable-target="item"]'));
