document.addEventListener('turbolinks:load', function (event) {
  // Restrict entering to only numbers, plus (+), empty spaces and dashes (-) in phone number inputs.
  const phone_number_inputs = document.querySelectorAll("input[id*='_phone']")
  phone_number_inputs.forEach( (input) => {
    input.maxLength = '17' // Safe length for long phone numbers. E.g +111 123 123 1234

    input.addEventListener('input', () => {
      input.value = input.value.replace(/[^0-9^+^\-^\s]/g, '')
    })
  })
})
