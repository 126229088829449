import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="multi-step-form"
export default class extends Controller {
  static targets = [ 'form', 'step' ]
  static values = { displayClass: { type: String, default: 'block' } }

  connect() {
    this.showStep(0)
  }

  showStep(index) {
    this.stepTargets.forEach((step, stepIndex) => {
      if (stepIndex === index) {
        step.classList.add(this.displayClassValue)
        step.classList.remove('hidden')
      } else {
        step.classList.add('hidden')
        step.classList.remove(this.displayClassValue)
      }
    })
  }

  next() {
    let currentStep = this.stepTargets.findIndex(step => step.classList.contains(this.displayClassValue))
    if (currentStep < this.stepTargets.length - 1) {
      this.showStep(currentStep + 1)
    }
  }

  submit(event) {
    event.preventDefault();

    let formData = new FormData(this.formTarget);
    let action = this.formTarget.action;

    fetch(action, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Network response was not ok ${response.statusText}`);
      }
      return response.json();
    })
    .then(data => {
      this.next();
    })
    .catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }
}
