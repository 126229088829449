// How to use it:
// 1. The item who's gonna trigger the copy action needs
//    to have 'data-component': 'copy-to-clipboard'
// 2. Inside the container you need an input
//    with 'data-component-target': 'input' and the css class .copy-input
// Optionally you could wrap component into a tooltip
// and add 'data-component-target': 'tooltip' on message

const setupCopyToClipboard = (component) => {
  component.addEventListener('click', () => {
    const input = component.querySelector('[data-component-target="input"]');
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 99999);
    const tooltip = component.querySelector('[data-component-target="tooltip"]');
    // This unnested-tooltip looks for a tooltip component that is not under the same parent as the input.
    // It doesn't play well with 'normal' tooltips as the latter has preference.
    const unnestedTooltip = document.querySelector('[data-component-target="unnested-tooltip"]');
    if (!tooltip && !unnestedTooltip) return;

    if (tooltip) {
      tooltip.classList.remove('hidden');
      setTimeout(() => tooltip.classList.add('hidden'), 1000);
    } else if (unnestedTooltip) {
      unnestedTooltip.classList.remove('hidden');
      setTimeout(() => unnestedTooltip.classList.add('hidden'), 1000);
    }
  });
}

const setupCopyToClipboardComponents = () => {
  const components = Array.from(document.querySelectorAll('[data-component="copy-to-clipboard"]'));
  components.forEach((component) => setupCopyToClipboard(component))
}

export { setupCopyToClipboard, setupCopyToClipboardComponents };
