import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/border.css';

const setupFacetTooltips = () => {
  const statsContainers = document.querySelectorAll('[data-component="facet-tooltip"]');

  statsContainers.forEach((container) => createTooltip(container));
};

const createTooltip = (container) => {
  // We skip those scores that do not exist
  if (!container.dataset.hasOwnProperty('scoreId')) return;

  const workClimateScoreId = container.dataset.scoreId;
  tippy(`[data-score-id="${workClimateScoreId}"]`, {
    theme: 'white',
    allowHTML: true,
    content: 'Loading...',
    touch: true,
    placement: 'bottom',
    delay: 500,
    onCreate(instance) {
      instance._isFetching = false;
      instance._src = null;
      instance._error = null;
    },
    onShow(instance) {
      setContentForTooltip(instance, workClimateScoreId);
    }
  });
  return false;
}

const setContentForTooltip = (instance, workClimateScoreId) => {
  if (instance._isFetching || instance._src || instance._error) return;

  const csrfToken = document.getElementsByName('csrf-token')[0].content;
  const options =  { headers: { 'X-CSRF-Token': csrfToken, 'Content-Type': 'application/text' } };
  const url = `/work_climate_scores_details/${workClimateScoreId}`;
  instance._isFetching = true;

  fetch(url, options)
  .then(response => response.text())
  .then(data => {
    instance._src = data;
    instance.setContent(data)
  })
  .catch((error) => {
    instance._error = error;
    instance.setContent('Failed to load.');
  })
  .finally(() => {
    instance._isFetching = false;
  });
}

export default setupFacetTooltips;
