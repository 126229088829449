// Working on both test_result and vaccination submissions.
document.addEventListener('turbolinks:load', () => {
  const submitButton = document.getElementById('web-submission-submit-btn');
  if(!submitButton) return;

  const setupSubmitButton = (input) => {
    if (input.value) {
      submitButton.classList.remove('is-disabled');
    } else {
      submitButton.classList.add('is-disabled');
    }
  };

  const additionalInfoField = document.getElementById('additional-info-input');
  additionalInfoField.addEventListener('keyup', (event) => setupSubmitButton(event.target))
  const evidencesInput = document.getElementById('evidence-input');
  evidencesInput.addEventListener('change', (event) => setupSubmitButton(event.target))
});
