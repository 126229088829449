document.addEventListener("turbolinks:load", () => {
  const responsibleInfo = document.querySelector("#responsible_info");
  const searchInput = document.querySelector(".search-filtered-person");
  if(!responsibleInfo) return;

  searchInput.addEventListener("change", () => {
    const li = document.querySelectorAll("#searchResults li");

    li.forEach((element) => element.addEventListener('click', function(e) {
      let responsibleFullName = `${this.dataset.firstName} ${this.dataset.lastName}`;
      responsibleInfo.textContent = `Adding ${responsibleFullName} to response team`;
      responsibleInfo.setAttribute("data-selected-responsible-id", this.dataset.personId);
      responsibleInfo.setAttribute("data-selected-responsible-full-name", responsibleFullName);
      document.querySelector("#responsible_id").value = this.dataset.personId;
    }));
  });
});
