import { Controller } from "@hotwired/stimulus"

const DEBOUNCE_MS = 300;
const MINIMUM_SEARCH_LENGTH = 3;

// Connects to data-controller="person-search"
// It shows results in a scrollable container. Meant to be used in a form to dynamically select subjects
// Clicking on a person(list item) will populate form inputs. You'll need a structure like this:
/*
  <div data-controller="person-search">
    <input data-person-search-target="searchField" data-action="input->person-search#onSearchFieldInput click->person-search#cleanSearch">
    <div data-person-search-target="searchResults"></div>
    <div data-person-search-target="searchResultsErrorMessage"></div>
    <input data-person-search-target="personType">
    <input data-person-search-target="personId">
  </div>
*/
// Find an example in: app/views/team_absences/index.html.haml
export default class extends Controller {
  static targets = [
    'searchField',
    'searchResults',
    'searchResultsErrorMessage',
    'personType',
    'personId',
    'excludedEmployeeId'
  ]
  static values = {
    searchFunction: '', // used as a reference to prevent multiple searches
    omittedPersonTypes: { type: Array, default: [] },
  }


  connect() {
    // Hide search results when clicking outside the search results:
    document.body.addEventListener('click', (e) => {
      if(!this.element.contains(e.target)) this.cleanResults();
    });
  }

  defineSelectedPerson(event) {
    const selectedItem = event.currentTarget;
    const { firstName, lastName, email, personType, personId } = selectedItem.dataset;
    this.cleanResults();
    this.searchFieldTarget.value = `${firstName} ${lastName} (${email})`;
    this.copyDataset(selectedItem, this.personIdTarget)
    if (this.hasPersonTypeTarget) {
      this.copyDataset(selectedItem, this.personTypeTarget)
      this.personTypeTarget.value = personType;
      this.personTypeTarget.dispatchEvent(new Event('change'));
    }
    this.personIdTarget.value = personId;
    this.personIdTarget.dispatchEvent(new Event('change'));
  }

  // We're copy-pasting not-controlller realted dataset attributes from selected item
  // to the id input so other controllers have access to them
  copyDataset(orriginItem, targetItem) {
    Object.entries(orriginItem.dataset).forEach((entry) => {
      const [key, value] = entry;
      if(key.includes('Target') || key.includes('action')) return;

      targetItem.dataset[key] = value;
    });
  }

  onSearchFieldInput() {
    const searchCriteria = this.searchFieldTarget.value
    const shouldSearch = searchCriteria.length >= MINIMUM_SEARCH_LENGTH;
    if (shouldSearch) {
      clearTimeout(this.searchFunctionValue);
      this.searchFunctionValue = setTimeout(() => { this.performSearch() }, DEBOUNCE_MS);
    } else {
      this.cleanResults();
      this.showMinimumLengthMessage(searchCriteria.length);
    }
  }

  performSearch() {
    this.searchResultsErrorMessageTarget.innerHTML = '';
    const csrkToken = document.getElementsByName('csrf-token')[0].content;
    const options =  { headers: { 'X-CSRF-Token': csrkToken } };
    const searchCriteria = this.searchFieldTarget.value;
    const ommitedPersonTypesQuery = this.omittedPersonTypesValue.reduce((queryMemo, personType) => {
      return queryMemo += `omitted_person_types[]=${personType}&`;
    }, '');
    const excludedEmployeeIdQuery = `excluded_employee_id=${this.excludedEmployeeIdTarget.value}&`;
    const url = `/person_search.html?q=${searchCriteria}&${ommitedPersonTypesQuery}&${excludedEmployeeIdQuery}`;
    fetch(url, options)
      .then(response => response.text())
      .then(html => { this.insertResults(html) });
  }

  showMinimumLengthMessage(inputLength) {
    const errorMessage = `Minimum characters needed: ${MINIMUM_SEARCH_LENGTH - inputLength}`;
    this.searchResultsErrorMessageTarget.innerHTML = errorMessage;
  }

  cleanResults() {
    this.searchResultsTarget.innerHTML = '';
  }

  cleanSearch() {
    this.searchFieldTarget.value = '';
    if (this.hasPersonTypeTarget) {
      this.personTypeTarget.value = null;
      this.personTypeTarget.dispatchEvent(new Event('change'));
    }
    this.personIdTarget.value = null;
    this.personIdTarget.dispatchEvent(new Event('change'));
  }

  insertResults(html) {
    this.searchResultsTarget.innerHTML = html
  }
}
