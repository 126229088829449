import { closeMenus } from '../components/menu';

const enteringAnimationClasses = ['ease-out', 'duration-300', 'opacity-100'];
const leavingAnimationClasses = ['ease-in', 'duration-200', 'opacity-0'];

// TODO: add tests
const hideActiveModal = () => {
  $(".modal.active").addClass('opacity-0 pointer-events-none');
  $(".modal.active").removeClass('opacity-100 active');
  $("body").removeClass("modal-active");
};

/**
 * This is only used by scanner.js at the moment. Ideally, we move this over there. (It's also confusing that we have
 * multiple (3) setupModal functions scattered throughout.
 *
 * @param modalId
 * @param title
 * @param content
 */
const setupModal = (modalId, title, content) => {
  const modal = $(modalId);
  $(`${modalId} .modal-title`).html(title);
  $(`${modalId} .modal-content`).html(content);
  modal.addClass('opacity-100 active');
  modal.removeClass("pointer-events-none opacity-0");
  $("body").addClass("modal-active");
}

const setupModalListeners = () => {
  $('.modal-overlay').on('click', () => closeActiveModal());
  $('.modal-close').on('click', () => closeActiveModal());
}

const closeActiveModal = () => {
  const modalActive = document.querySelector('.modal.active');
  if (!modalActive) return;

  const modalContainer = modalActive.querySelector('.modal-container');
  const modalOverlay = modalActive.querySelector('.modal-overlay');

  modalActive.classList.remove(...enteringAnimationClasses, 'active');
  modalContainer.classList.remove(...enteringAnimationClasses, 'scale-100');
  modalOverlay.classList.remove(...enteringAnimationClasses);

  modalActive.classList.add(...leavingAnimationClasses, 'pointer-events-none');
  modalContainer.classList.add(...leavingAnimationClasses, 'scale-95');
  modalOverlay.classList.add(...leavingAnimationClasses);

  document.querySelector('body').classList.remove('modal-active')
}

const activateModal = (modalId) => {
  closeMenus();
  const modal = document.querySelector(modalId);
  const modalContainer = modal.querySelector('.modal-container');
  const modalOverlay = modal.querySelector('.modal-overlay');

  modal.classList.remove(...leavingAnimationClasses, 'pointer-events-none');
  modalContainer.classList.remove(...leavingAnimationClasses, 'scale-95');
  modalOverlay.classList.remove(...leavingAnimationClasses);

  modal.classList.add(...enteringAnimationClasses, 'active');
  modalContainer.classList.add(...enteringAnimationClasses, 'scale-100');
  modalOverlay.classList.add(...enteringAnimationClasses);

  document.querySelector('body').classList.add('modal-active')
}

export { hideActiveModal, setupModal, setupModalListeners, activateModal, closeActiveModal };
