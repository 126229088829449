// Instructions
// How to consume this as a component
// 1. Container must have `data-component="sortable"`(each cghild will work as a sortable item)
// Optionally. You can dinamically include `data-handle="myDataID"` if you don't want
//             the whole item to be dragable, but only a specific element/
//             Each element would nee to have an element wiht `data-item="myDataID"`
//             A `data-sort-input="mySortInput"` can also be provided. If so, inputs values will
//             automatiocally be updated from 1 to n. Input has to be wrapped under an
//             element with `data-item="sortable-item` so hidden items can be ignored
//             Adding `data-input-sorting-in-setup`, will update input values during setup


import Sortable from 'sortablejs/modular/sortable.core.esm.js';

const setupSortableComponent = (component) => {
  const sortableOptions = { sort: true };
  const itemsHandlers = component.getAttribute('data-handle');
  const sortInputsId = component.getAttribute('data-sort-input');
  const shouldSortInSettup = component.getAttribute('data-input-sorting-in-setup');

  if (itemsHandlers) {
    sortableOptions['handle'] = `[data-item="${itemsHandlers}"]`;
  }
  if (sortInputsId) {
    sortableOptions['onUpdate'] = () => updateSortInputs(component, sortInputsId);
  }
  Sortable.create(component, sortableOptions);
  if(shouldSortInSettup) updateSortInputs(component, sortInputsId);
};

const updateSortInputs = (component, sortInputsId) => {
  const activesortableItems = getActiveSortableItems(component)
  activesortableItems.forEach((sortableItem, index) => {
    const sortInput = sortableItem.querySelector(`[data-item="${sortInputsId}"]`);
    sortInput.value = index +1;
  });
};

const getActiveSortableItems = (component) => {
  const sortableItems = Array.from(component.querySelectorAll('[data-item="sortable-item"]'));
  return sortableItems.filter((item) => !item.classList.contains('hidden'));
};

const setupSortableComponents = () => {
  const components = document.querySelectorAll('[data-component="sortable"]')
  components.forEach((component) => setupSortableComponent(component));
};

export default setupSortableComponents;
