
$(document).on("turbolinks:load", () => {
  const DYNAMIC_RULES_FIELDS = ['has reservation', 'on site at', 'screening', 'badge'];
  const areRulesDisabled = $("#matchable-rules").data("disabled");
  if(!$('#matchable-rules').length) return;

  const klass = $("#matchable-rules").data("klass")
  $('#field-option').on("change", () => {
    // Change rule form when this happens.
    updateMatchOptions();
  });

  toggleDeliveryOptions($('#matchable-delivery-schedule select').val())

  $('#matchable-delivery-schedule select').on("change", (e) => {
    toggleDeliveryOptions(e.target.options[e.target.selectedIndex].value)
  });

  $("#add-rule").on("click", (event) => {
    event.preventDefault();
    if (areRulesDisabled) return;

    $("#rule-selection").removeClass("hidden");
  });

  // TODO: we could refactor this
  $('#commit-rule').on("click", (event) => {
    event.preventDefault();
    if(areRulesDisabled) return;

    const field = $("#field-option").val();
    const values = [];
    const labels = [];
    $.each($("#value-option input"), (_i, field) => {
      const inputType = $(field).prop("type");
      const inputValue = $(field).val();
      const isInpuctChecked = $(field).prop("checked")
      if (inputType == "checkbox" && isInpuctChecked) {
        if (!isNaN(parseInt(inputValue))) { // encode the label if we have a potential ID.
          values.push($(field).val() + "^" + $(field).data("label"));
        } else {
          values.push($(field).val());
        }
        labels.push($(field).data("label"));
      } else if (inputType == "radio" && isInpuctChecked ) {
        values.push($(field).val());
        labels.push($(field).data("label"));
      } else if (inputType == "text") {
        if(inputValue === "") return;

        values.push($(field).val());
        labels.push($(field).val());
      }
    });
    if (values.length === 0) return alert("Please add a value.");

    const value = `${field.toLowerCase().replaceAll(" ", "_")}<RULE-LABEL-SEPARATOR>${values.join("|")}`
    const newRule = buildRuleLabel(klass, field, value, labels);
    newRule.appendTo($("#rules-container"));
    $("#rule-selection").addClass("hidden");
    $(".rule a").on("click", removeRule);
  });

  $(".rule a").on("click", removeRule);

  function removeRule() {
    if (areRulesDisabled) return;

    $(this).parent().parent(".rule").remove();
  }

  function toggleDeliveryOptions(selection) {
    // TODO: This is a hotfix to support both views (announcements and screening schedules)
    let viewing_screening_schedules = window.location.href.includes("screening")

    if (selection == "scheduled" || viewing_screening_schedules) {
      $(".matchable-delivery-fields").show()
    } else {
      $(".matchable-delivery-fields").hide()
    }
  }

  function updateMatchOptions() {
    const selectedField = $( "#rule-selection select option:checked" ).val().toLowerCase();
    const title = $("#possible-values [data-attr-name='" + selectedField + "']").data("title");
    const optionsList = $("#possible-values [data-attr-name='" + selectedField + "'] .value");

    $("#value-option").html("");
    $("#dinamyc-rule-indicator").addClass("hidden");
    if(selectedField == "app installed") {
      $("#value-option").html("Matches:<br>");
      $("#value-option").append("<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='app_installed' value='" + "true" + "' data-label='" + "Yes" + "'></div><div class='pl-7 text-sm leading-5'>" + "Yes" + "</div></label></div></div>");
      $("#value-option").append("<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='app_installed' value='" + "false" + "' data-label='" + "No" + "'></div><div class='pl-7 text-sm leading-5'>" + "No" + "</div></label></div></div>");
    } else if(selectedField == "has reservation") {
      $("#value-option").html("Matches:<br>");
      $("#value-option").append("<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "true" + "' data-label='" + "Yes" + "'></div><div class='pl-7 text-sm leading-5'>" + "Yes" + "</div></label></div></div>");
      $("#value-option").append("<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "false" + "' data-label='" + "No" + "'></div><div class='pl-7 text-sm leading-5'>" + "No" + "</div></label></div></div>");
    } else if(selectedField == "badge") {
      document.getElementById('value-option').innerHTML = "Matches:<br>";
      let option = document.getElementById('value-option');
      option.insertAdjacentHTML('beforeend', "<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "passing" + "' data-label='" + "Passing" + "'></div><div class='pl-7 text-sm leading-5'>" + "Passing" + "</div></label></div></div>");
      option.insertAdjacentHTML('beforeend', "<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "failing" + "' data-label='" + "Failing" + "'></div><div class='pl-7 text-sm leading-5'>" + "Failing" + "</div></label></div></div>");
    } else if(selectedField == "screening") {
      document.getElementById('value-option').innerHTML = "Matches:<br>";
      let option = document.getElementById('value-option');
      option.insertAdjacentHTML('beforeend', "<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "passed" + "' data-label='" + "Passed" + "'></div><div class='pl-7 text-sm leading-5'>" + "Passed" + "</div></label></div></div>");
      option.insertAdjacentHTML('beforeend', "<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "failed" + "' data-label='" + "Failed" + "'></div><div class='pl-7 text-sm leading-5'>" + "Failed" + "</div></label></div></div>");
      option.insertAdjacentHTML('beforeend', "<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='radio' name='has_reservation' value='" + "expired" + "' data-label='" + "Expired" + "'></div><div class='pl-7 text-sm leading-5'>" + "Expired" + "</div></label></div></div>");
    } else if(optionsList.length > 0) {
      $("#value-option").html("Matches:<br>");
      $.each(optionsList, (_i, option) => {
        $("#value-option").append("<div class='mt-2'><div class='flex items-start'><label class='font-medium text-gray-700'><div class='absolute flex items-center h-5'><input type='checkbox' value='" + $(option).data('value') + "' data-label='" + $(option).data('label') + "'></div><div class='pl-7 text-sm leading-5'>" + $(option).data('label') + "</div></label></div></div>");
      });
    } else if (title && optionsList.length === 0) {
      $("#value-option").html("No " + title + " found!");
    } else {
      $("#value-option").html("Matches:<br>");
      $("#value-option").append("<div class='mt-2'><input type='text' value='' class='mt-1 form-input block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5'></div>");
    }
    if(isSelectedRuleDynamic(selectedField)) $("#dinamyc-rule-indicator").removeClass("hidden");
  }

  function isSelectedRuleDynamic (selectedField) {
    return DYNAMIC_RULES_FIELDS.includes(selectedField);
  }

  function buildRuleLabel(klass, field, value, labels) {
    const isFirstRule = !document.querySelectorAll('#rules-container input').length;
    const urlParams = new URLSearchParams(window.location.search);
    const ruleType = urlParams.get('matching_at_least_one_rule') ? 'OR' : 'AND'

    return $(`
      <div class='rule inline-block'>
        ${isFirstRule ? '' :
          `
            <span class="text-base leading-6 font-medium text-gray-700 ml-1 mr-1">
              ${ruleType}
            </span>
          `
        }

        <div class='inline-block mb-2 py-1 px-3 bg-red-600 rounded-md text-sm text-white'>
          <a class="cursor-pointer inline float-right ml-1" title="Remove rule">
            <svg class="block&nbsp;h-5 w-5 text-white" fill="none" stroke="currentColor" stroke_linecap="round" stroke_linejoin="round" stroke_width="2" viewBox="0 0 24 24">
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </a>
          <input type='hidden' name='${klass}[rules][]' value='${value}'>
            ${field}:
            <strong>${labels.join("</strong> or <strong>")}
            </strong>
        </div>
      </div>
      `);
  }
});
