import { activateModal, hideActiveModal, setupModalListeners } from '../utils/modals'

/*
This component/modals.js and utils/modals.js expects DOM like the following:

#identifier.modal
  .modal-overlay - This is animated and also has a click handler to dismiss
  .modal-container - This is used to scope queries that change things from user interaction
  .modal-content - This is only currently used (found in utils/modals.js) by scanner.js to dynamically fill the contents
                 SAFE TO DELETE from all modals but #scanner-modal
  .modal-title - This used (found in utils/modals.js) by scanner.js to dynamically fill the contents. It is ALSO used
                 by js.erb files to fill in at run time from an async request.
  .modal-close - This has an event handler attached to it to dismiss
  .modal-form-area - This is what one would think the modal-content does. But it is used
                 by js.erb files to fill in the modal content at run time from an async request.

 There are no dependencies on parent/child hierarchy of these classes.
 */
// Declare functions into window object in order to use them in js.erb files
window.activateModal = activateModal
window.hideActiveModal = hideActiveModal
window.setupModalListeners = setupModalListeners

const ESCAPE = 'Escape';
const ESC = 'Esc';
const ESC_CODE = 27;

const enteringAnimationClasses = ['ease-out', 'duration-300', 'opacity-100'];
const leavingAnimationClasses = ['ease-in', 'duration-200', 'opacity-0'];

const setupModal = (modalBtn) => {
  const modalName = modalBtn.dataset.modal || 'general-modal';
  const targetedModal = document.getElementById(modalName) || document.querySelector('.modal');
  modalBtn.addEventListener('click', (e) => {
    e.preventDefault();
    openModal(targetedModal);
  });

  targetedModal.getElementsByClassName('modal-close')
    .forEach((closeBtn) => {
      closeBtn.addEventListener('click', (e) => {
        e.preventDefault();
        closeModal();
      })
    });

  targetedModal.querySelector('.modal-overlay')
    .addEventListener('click', () => {
      closeModal();
    });

  document.onkeydown = (event) => {
    event = event || window.event;
    var isEscape = false;
    if ('key' in event) {
      isEscape = event.key === ESCAPE || event.key === ESC;
    } else {
      isEscape = event.keyCode === ESC_CODE;
    }
    if (isEscape && document.body.classList.contains('modal-active')) {
      closeModal();
    }
  }
};

/**
  * Takes a specific modal ID, or just opens the first.
  * @param id
*/
const openModal = (modal) => {
  const modalContainer = modal.querySelector('.modal-container');
  const modalOverlay = modal.querySelector('.modal-overlay');

  modal.classList.remove(...leavingAnimationClasses, 'pointer-events-none');
  modalContainer.classList.remove(...leavingAnimationClasses, 'scale-95');
  modalOverlay.classList.remove(...leavingAnimationClasses);

  modal.classList.add(...enteringAnimationClasses, 'active');
  modalContainer.classList.add(...enteringAnimationClasses, 'scale-100');
  modalOverlay.classList.add(...enteringAnimationClasses);
  
  document.querySelector('body').classList.add('modal-active') 
}

/**
 * Closes whatever is active
 */
const closeModal = () => {
  const modalActive = document.querySelector('.modal.active');
  if (!modalActive) return;

  const modalContainer = modalActive.querySelector('.modal-container');
  const modalOverlay = modalActive.querySelector('.modal-overlay');

  modalActive.classList.remove(...enteringAnimationClasses, 'active');
  modalContainer.classList.remove(...enteringAnimationClasses, 'scale-100');
  modalOverlay.classList.remove(...enteringAnimationClasses);

  modalActive.classList.add(...leavingAnimationClasses, 'pointer-events-none');
  modalContainer.classList.add(...leavingAnimationClasses, 'scale-95');
  modalOverlay.classList.add(...leavingAnimationClasses);

  document.querySelector('body').classList.remove('modal-active')
}

const setupModals = () => {
  Array.from(document.querySelectorAll('.modal-btn'))
    .forEach((modalBtn) => setupModal(modalBtn));
}

export { setupModal, setupModals }
