// Having a set of hideable elements, it hides all of them except one
// How to use it:
// 1. You need a container wrapping all your elements.
//    This container needs to have data-component="hiddeable"
//    and data-component-items="my-component-id".
// 2. Inside the container you need an element with data-action="hide-element",
//    data-component-item="my-component-id" and the data-hiddeable-target="target-id-to-be-hidden"
//    Optionally:
//    1 - Include data-component-item="my-component-id" and
//    data-showable-target="target-id-to-be-shown" to give component a togglable efect.
//    Clicking the element would hide A and show B
//    2 - Inlcude data-style-class="my-class" can be given, this class is used for changing style.
//    This class is removed in all the clickable elements except in the one that was clicked

const toggleItemsVisibility = (actionableItem, hiddeableItems) => {
  const showableTargetId = actionableItem.getAttribute('data-showable-target');
  const showTarget = document.getElementById(showableTargetId);
  hiddeableItems.forEach((item) => item.classList.add('hidden'));
  showTarget.classList.remove('hidden');
}

const toggleClickableItemsStyle = (clickedItem, actionableItems, styleClass) => {
  if(!styleClass) return;

  actionableItems.forEach((actionableItem) => actionableItem.classList.remove(styleClass));
  clickedItem.classList.add(styleClass);
};

const setupComponent = (component) => {
  const componentIdentifier = component.getAttribute('data-component-items');
  const actionableItems = Array.from(component.querySelectorAll(`[data-component-item="${componentIdentifier}"][data-action="hide-element"]`));
  const styleClass = component.getAttribute('data-style-class');
  const hiddeableItems = Array.from(component.querySelectorAll(`[data-component-item="${componentIdentifier}"][data-target="hideable-element"]`));
  actionableItems.forEach((actionableItem) => {
    actionableItem.addEventListener('click', () => {
      const clickedItem = actionableItem;
      toggleItemsVisibility(clickedItem, hiddeableItems);
      toggleClickableItemsStyle(clickedItem, actionableItems, styleClass);
    });
  });
}

const setupHideableComponents = () => {
  const components = Array.from(document.querySelectorAll('[data-component="hiddeable"]'));
  components.forEach(component => setupComponent(component));
}

export default setupHideableComponents;
