import Highcharts from "./highcharts_config";

document.addEventListener("turbolinks:load", () => {
  // Charts
  const colors = [
    "#5EC269",
    "#A0ECB1",
    "#E15062",
  ]

  const chartsContainer = document.getElementById("charts-container");
  if(!chartsContainer) return;

  const availabilityMaxValue = chartsContainer.dataset.availabilityMaxValue;
  $(".availability-chart").each((i, node) => {
    const data = $(node).data("chart")

    Highcharts.chart(node.id, {
      credits: {
        enabled: false,
      },
      chart: {
        type: "column"
        },
      title: {
        text: data.name,
        align: "left",
        margin: 30,
        style: {
          fontWeight: "bold"
        }
      },
      xAxis: {
        categories: data.categories,
        title: {
          text: "Day",
        },
        style: {
          marginTop: 20
        }
      },
      yAxis: {
        min: 0,
        max: Number(availabilityMaxValue),
        title: {
          text: "Personnel",
        },
        // plotLines: [{
        //   dashStyle: "Dot",
        //   value: data.one_response_per_day_line,
        //   color: "#666",
        //   label: {
        //     text: "1 response/person/day",
        //   },
        // }],
        stackLabels: {
          enabled: false,
          style: {
            fontWeight: "bold",
            color: ( // theme
                Highcharts.defaultOptions.title.style &&
                Highcharts.defaultOptions.title.style.color
            ) || "gray"
          }
        }
      },
      legend: {
        // enabled: false,
        align: "right",
        x: 0,
        verticalAlign: "top",
        y: 40,
        floating: true,
        backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#CCC",
        borderWidth: 1,
        shadow: false
      },
      tooltip: {
        backgroundColor: "#000",
        borderColor: "#000",
        borderRadius: 15,
        style: {
          color: "#fff",
        },
        headerFormat: "<b>{point.x}</b><br/><br/>",
        pointFormat: "{series.name}: {point.y:,.0f}<br/>Total: {point.stackTotal}",
      },
      plotOptions: {
        column: {
          borderWidth: 1,
          stacking: "normal",
          dataLabels: {
            enabled: false
          }
        }
      },
      series: data.series.map((series,i) => {
        series.color = colors[i]
        series.borderColor = colors[i]
        return series
      })
    })
  })
})
